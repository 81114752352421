import { Drawer, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { content } from './content'
import SidebarButton from './components/SidebarButton'
import logo from '../../../assets/logo.png'


const Sidebar = ({drawer, onToggleDrawer, history}) => {

    const classes= useStyles()

    const onSelectedCategory = (url) => {
        window.scrollTo(0,0)
        history.push(url)
        onToggleDrawer()
    }

    return (
        <Drawer
        className={classes.drawer}
        classes={{paper: classes.drawerPaper,}}
        variant='temporary'
        anchor="left"
        open={drawer}
        onClose={onToggleDrawer}
        >
        <div>
            <Grid container justify='center'>
                <Grid item>
                    <div className={classes.logoContainer}>
                    <img alt='' src={logo} className={classes.logo} />
                    </div>
                </Grid>
            </Grid> 
            <Grid container>
                {content.menu.map((item, index)=>{
                    return(
                        <Grid item xs={12} key={index}>
                            <SidebarButton  onSelectedCategory={onSelectedCategory} {...item}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
        </Drawer>
    )

}

const drawer_width = 200

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawer_width,
        flexShrink: 0,
        border:0,
        boxSizing:'border-box',
        overflowX:'hidden'
    },
    drawerPaper: {
        width: drawer_width,
        background:theme.palette.primary.main,
        borderRadius:'0px 16px 16px 0px',
        border:0,
        boxSizing:'border-box',
        overflowX:'hidden'
    },
    logo:{
        //height:'100%',
        width:'100%'
    },
    logoContainer:{
        marginTop:20,
        marginBottom:40,
        width:70,
        height:70
    }
    
}))

export default Sidebar