import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_confirm_link = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  await mainServer.post(`/registration/sendemail`, data)
}

/* export const MUTATION_RECOVER = gql`
  mutation CustomerForgottenPassword($email:String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        message
      }
    }
  }
`  */ 