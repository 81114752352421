import React, {useState} from 'react'
import { Grid, Icon, IconButton, TextField, withStyles } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import ProductImage from '../../../components/Structure/Images/ProductImage'
import DisplayText from '../../../components/Texts/DisplayText'
import { onGetFormatNumber } from '../../../shared/utility'
import { EYE_CONFIG } from '../../../variables/config'


const CartItem = props => {

    const {product, onDeleteItem} = props
    const {quantity, title, _id, details, customAttributes, variant, image, price, index, onUpdateQuantity} = props

    const [quant, setQuant] = useState(quantity)
    
    let configAttributes = null
    if(details){
        configAttributes = JSON.parse(details)
    }
    let productDetailsContent = null
    let unitPrice = ''
    let subtotal = ''
    if(price){
        unitPrice = `$${onGetFormatNumber(price)}`
        subtotal = `$${onGetFormatNumber(parseFloat(price)*quantity)}`
    } 

    if(configAttributes){
        const {axis, cylinder, sphere, addition, dominance, color, quantity, type } = configAttributes
        productDetailsContent = (
            <LenseDetailContainer 
                title={EYE_CONFIG[type]} quantity={quantity} 
                axis={axis} cylinder={cylinder} sphere={sphere}
                addition={addition} dominance={dominance} color={color}
            />
        )
    }


    const onIncrement = () => {
        let result = quant 
        if(quant === ''){
            setQuant(1)
            return
        }

        result++
        setQuant(result)

        onUpdateQuantity(_id, {quantity:quant+1} )
    }

    const onDecrement = () => {
        let result = quant 
        if(quant === ''){
            setQuant(1)
            return
        }
        result--
        if(result <= 1) {
            setQuant(1)
            onUpdateQuantity(_id, {quantity:1})
            return
        }
        setQuant(result)
        onUpdateQuantity(_id,{quantity:quant-1})
    }
    

    return(
        <div>
            <Grid container>
                {/* <Grid item xs={12} md={7}>
                    <Grid container spacing={1}> */}
                        <Grid item xs={12}>
                            <ProductImage type='CART' image={image}/>
                        </Grid>
                        <Grid item xs>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Heading color='primary' size='large'>{title}</Heading>
                                </Grid>
                                <Grid item xs={12}>
                                    {productDetailsContent}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item><DisplayText>PRECIO UNITARIO</DisplayText></Grid>
                                        <Grid item><DisplayText color='primary'>{unitPrice}</DisplayText></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        {/* </Grid>
                    </Grid> */}
                </Grid>
                
                <Grid item xs={12} md={2}>
                    <div style={{marginTop:40}}>
                    <Grid container spacing={1} wrap='nowrap' alignItems='center' justify='center'>
                        <Grid item>
                            {/* <DisplayText>{quantity}</DisplayText> */}
                            <Grid container justify='center' spacing={1} wrap='nowrap'>
                                <Grid item><IconButton onClick={onDecrement}size='small' ><Icon fontSize='small'>remove</Icon></IconButton></Grid>
                                <Grid item><CssTextField value={quant} /></Grid>
                                <Grid item><IconButton onClick={onIncrement}size='small' ><Icon fontSize='small'>add</Icon></IconButton></Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IconButton size='small' onClick={() => onDeleteItem(_id)}>
                                <Icon fontSize='small'>delete</Icon>
                            </IconButton>
                        </Grid>
                    </Grid>
                    </div>
                </Grid>
                {/* <Grid item xs={12} md={2}>
                    <DisplayText align='right'>{subtotal}</DisplayText>
                </Grid> */}
            </Grid>
        </div>
    )

}
 
export default CartItem


const LenseDetailContainer = props => {
    const {title, sphere, cylinder, axis, addition, dominance, color, quantity} = props

    return(
        <Grid container direction='column'>
            <Grid item>
                <Heading color='bluegrey'>{title}</Heading>
            </Grid>
            <Grid item>
                <Grid container direction='column'>
                    {sphere ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Poder(SPH):</Heading></Grid>
                                <Grid item xs><DisplayText>{sphere}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {cylinder ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Cilindro(CYL):</Heading></Grid>
                                <Grid item xs><DisplayText>{cylinder}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {axis ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Eje(AXIS):</Heading></Grid>
                                <Grid item xs><DisplayText>{axis}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {addition ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Adición(ADD):</Heading></Grid>
                                <Grid item xs><DisplayText>{addition}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {dominance ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Dominance(DOM):</Heading></Grid>
                                <Grid item xs><DisplayText>{dominance}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {color ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Color:</Heading></Grid>
                                <Grid item xs><DisplayText>{color}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {quantity ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Cantidad:</Heading></Grid>
                                <Grid item xs><DisplayText>{quantity}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    )
}

const CssTextField = withStyles(theme => ({
    root: {
      '& input':{
        textAlign:'center',
      },
      width:30
      //:'100%'
    },
  }))(TextField);