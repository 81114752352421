import React, { useState } from 'react'
import Page from '../../components/Structure/Page/Page'
import { makeStyles, Grid, Typography, Hidden } from '@material-ui/core'
import Heading from '../../components/Texts/Heading'
import CartList from './components/CartList'
import { request_order, request_update_lineitem } from './requests'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import {connect} from 'react-redux'
import * as actionTypes from '../../store/actions'
import emptyCart from '../../assets/empty-cart.png'
import { blueGrey } from '@material-ui/core/colors'
import { request_remove_lineitem } from '../Products/ProductView/requests'
import { isTokenValid } from '../../shared/utility'
import CartListMobile from './components/CartListMobile'

const useStyles = makeStyles(theme => ({
    
    link:{
        fontFamily:theme.typography.fontFamily,
        color:theme.palette.secondary.main,
        fontSize:18
    },
    emptyCart:{
        marginTop:100,
        width:200,
        marginBottom:40
    }
}))

const CartView = props => {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)

    const [updating, setUpdating] = useState(false);
    const [statusModal, setStatusModal] = useState(false)
    const [order, setOrder] = useState(null)
    const {onUpdateCartItems, login_modal, signup_modal, recover_password_modal} = props
    const {onUpdateSignInModal, onUpdateSingUpModal, onUpdateRecoverPasswordModal} = props


    useEffect(() => {
        //refetch()
        const initModule = async() => {
            window.scrollTo(0,0);
            setLoading(true)
            try {
                const orderID = localStorage.getItem('orderID')
                if(orderID){
                    const _order = await request_order(orderID)
                    console.log(_order)
                    setOrder(_order)
                    console.log(_order)
                }else{

                }
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        initModule()

    }, [])

    

    const onSelectedURL = (url) => {
        window.scrollTo(0,0);
        props.history.push(url)
    }

    const onDeleteItem = async(id) => {
        setUpdating(true)
        try {
            const _order = await request_remove_lineitem(order._id,id)
            setOrder(_order)
            onUpdateCartItems(_order.line_items.length)
        } catch (error) {
            console.log(error)
        }
        setUpdating(false)
    }

    const onUpdateItem = async (_id, data2send) => {
        console.log(data2send)
        setSending(true)
        try {
            const _order = await request_update_lineitem(order._id, _id, data2send)
            setOrder(_order)
            onUpdateCartItems(_order.line_items.length)
        } catch (error) {
            console.log(error)
        }
        setSending(false)
    }

    const onCheckout = () => {
        const isLogged = isTokenValid()
        console.log(isLogged)
        if(!isLogged){
            onUpdateSignInModal(true)
            console.log('Se requiere hacer login ')
        }else{
            props.history.push(`/checkout/${order._id}`)

        }
    }

    

    let products = []
    let subtotal = 0.0
    if(order){
        products = order.line_items
        subtotal = order.subtotal
    }


    let contentView = (
        <div>
        <Hidden smDown>
            <CartList products={products} subtotalPrice={subtotal} loading={updating} order={order}
            onDeleteItem={onDeleteItem} onUpdateItem={onUpdateItem} onCheckout={onCheckout}/>
        </Hidden>
        <Hidden mdUp>
            <CartListMobile products={products} subtotalPrice={subtotal} loading={updating} order={order}
            onDeleteItem={onDeleteItem} onUpdateItem={onUpdateItem} onCheckout={onCheckout}/>
        </Hidden>
        </div>
    )

    if(products && !products.length){
        contentView = (
            <Grid container justify='center'>
                <Grid item>
                    <img src={emptyCart} alt='No image' className={classes.emptyCart}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' align='center' style={{fontWeight:400, color:blueGrey[700], width:300, margin:'auto'}}>No existen productos en tu carrito</Typography>
                </Grid>
            </Grid>
        )
    }

    const onGoingBack = () => {
        window.history.go(-3);
    }

    return(
        <Page breadcrumbs={[{id:1, label:'Carrito de compras', selected:true}]} sending={sending} 
        loading={loading}>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item xs={12}>
                                <Heading size='extraLarge' align='center'>Resumen de lista de compras</Heading>
                            </Grid>
                            <Grid item>
                                {/* <Link className={classes.link} onClick={() => onSelectedURL('/')}>Seguir comprando</Link> */}
                                <Link className={classes.link} onClick={onGoingBack}>Seguir comprando</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {contentView}
                    </Grid>
                </Grid>
            </div> 
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        apollo:state.apolloClient,
        login_modal:state.login_modal,
        signup_modal:state.signup_modal,
        recover_password_modal:state.recover_password_modal,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateCartItems: (cartItems) => dispatch({
            type: actionTypes.UPDATE_CART_ITEMS, 
            cartItems:cartItems 
        }),
        onUpdateSignInModal: (login_modal) => dispatch({type: actionTypes.AUTH_LOGIN_MODAL, login_modal }),
        onUpdateSingUpModal: (signup_modal) => dispatch({type: actionTypes.AUTH_SINGUP_MODAL, signup_modal }),
        onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartView) 