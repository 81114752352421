import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Home from '../views/Home/Home'
import ProductView from '../views/Products/ProductView/ProductView'
import ForgottePasswordView from '../views/ForgottePasswordView/ForgottePasswordView'
import ResetPasswordView from '../views/ResetPasswordView/ResetPasswordView'
import ContactLensesView from '../views/Collections/ContactLensesView/ContactLensesView'
import CartView from '../views/CartView/CartView'
import SolutionsView from '../views/Collections/SolutionsView/SolutionsView'
import DropsView from '../views/Collections/DropsView/DropsView'
import SolutionView from '../views/Products/SolutionView/SolutionView'
import DropView from '../views/Products/DropView/DropView'
import EmailConfirmationView from '../views/Auth/EmailConfirmationView'
import ProfileView from '../views/ProfileView/ProfileView'
import OrdersView from '../views/OrdersView/OrdersView'


const PageRouter = props => {
    return(
        <Switch>
            <Route component={Home} path='/' exact/>
            <Route component={EmailConfirmationView} path='/confirmation'/>
            <Route component={ProductView} path='/contactlense-product/:id'/>
            <Route component={SolutionView} path='/solution-product/:id'/>
            <Route component={DropView} path='/drop-product/:id'/>
            <Route component={ForgottePasswordView} path='/forgottenPassword'/>
            <Route component={ResetPasswordView} path='/reset'/>
            <Route component={ContactLensesView} path='/contactlenses/:id'/>
            <Route component={SolutionsView} path='/solutions/:id'/>
            <Route component={DropsView} path='/drops/:id'/>
            <Route component={CartView} path='/cart'/>
            <Route component={ProfileView} path='/profile'/>
            <Route component={OrdersView} path='/orders'/>
            
            <Redirect from='/' to='/'/>
        </Switch>
    )
}

export default PageRouter