import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, makeStyles, Icon, Typography } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../Texts/DisplayText'

const SidebarButton = ({sections, label, onSelectedCategory}) =>{

    const classes = useStyles()

    

    return(
        <div>
            <Accordion 
                classes={{root: classes.acordion}}
            >
                <AccordionSummary
                expandIcon={<Icon style={{color:'white'}}>expand_more</Icon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    root: classes.acordionSummary, 
                    expanded : {}                
                    }}
                >
                <DisplayText className={classes.heading}>{label}</DisplayText>
                </AccordionSummary>
                <AccordionDetails
                    classes={{root: classes.details}}
                >
                    <Grid container>
                        {sections.map((item, index)=>{
                            return(
                                <Grid item xs={12}>
                                    <Accordion key={index}
                                        classes={{root: classes.acordionChild}}
                                    >
                                        <AccordionSummary
                                        expandIcon={<Icon style={{color:'white'}}>expand_more</Icon>}s
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        classes={{
                                            root: classes.acordionSummaryChild, 
                                            expanded : classes.expandedChild  ,
                                            content: classes.contentChild            
                                            }}
                                        >
                                        <DisplayText className={classes.heading}>{item.label}</DisplayText>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            classes={{root: classes.detailsChildren}}
                                        >
                                            <Grid container>
                                                {item.options.map((item, index)=>{
                                                    return(
                                                        <Grid item xs={12} key={index}>
                                                            <div className={classes.link} onClick={()=>onSelectedCategory(item.url)}>
                                                                <Typography >{item.label}</Typography>
                                                            </div>
                                                        </Grid>          
                                                    )
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )

}

const useStyles = makeStyles(theme => ({
    link:{
        margin:'8px 0px',
        fontSize:'0.8rem',
        cursor:'pointer'
    },
    acordion:{
        border: 'none',
        boxShadow: 'none',
        background:'transparent',
        color:'white'
    },
    acordionChild:{
        border: 'none',
        boxShadow: 'none',
        background:'transparent',
        color:'white'
    },
    acordionSummary:{
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
        borderBottom: 0,
        },
        '&:before': {
        display: 'none',
        },
        '&$expanded': {
        margin: 'auto',
        },
    },
    acordionSummaryChild:{
        border: 'none',
        boxShadow: 'none',
        padding:0,
        margin:0,
        '&:not(:last-child)': {
        borderBottom: 0,
        },
        '&:before': {
        display: 'none',
        },
        '&content':{
        padding:0,
        margin:0
        },
        '&$expanded': {
        //margin: 'auto',
        margin:0,
        padding:0
        },
    },
    contentChild:{
        margin:0,
        padding:0,
        '&$expanded': {
            //margin: 'auto',
            margin:0,
            padding:0
            },
    },
    expandedChild:{
        margin:0,
        padding:0,
       //background:'red'
    },
    detailsChildren:{
        padding:'0px 16px'
    },
    details:{
        padding:'0px 24px'
    }
 
}))

export default SidebarButton

/* <Accordion key={index}>
                                <AccordionSummary
                                expandIcon={<Icon>expand_more</Icon>}s
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                classes={{
                                    root: classes.acordionSummary, 
                                    expanded : {}                
                                    }}
                                >
                                <DisplayText className={classes.heading}>{item.label}</DisplayText>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {item.options.map((item, index)=>{
                                            return(
                                                <Grid item xs={12} key={index}>
                                                    <Button>{item.label}</Button>
                                                </Grid>          
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion> */