export const contact_lenses_categories = {
    "miopia-o-hipermetropia":{
        title:"Miopía o Hipermetropía", 
        tags:"lentes,miopia",
        image:""
    },
    "astigmatismo":{
        title:"Astigmatismo", 
        tags:"lentes,astigmatismo",
        image:""
    },
    "presbicia":{
        title:"Presbicia", 
        tags:"lentes,presbicia",
        image:""
    },
    "lentes-cosmeticos":{
        title:"Lentes cosméticos", 
        tags:"lentes,cosmeticos",
        image:""
    },
    "uso-diario":{
        title:"Diarios", 
        tags:"lentes,diario",
        image:""
    },
    "uso-quincenal":{
        title:"Quincenales", 
        tags:"lentes,quincenal",
        image:""
    },
    "uso-mensual":{
        title:"Mensuales", 
        tags:"lentes,mensual",
        image:""
    },
    "uso-anual":{
        title:"Anuales", 
        tags:"lentes,anual",
        image:""
    },
    "air-optix":{
        title:"Air Optix", 
        tags:"lentes,air optix",
        image:""
    },
    "dailies-aqua-comfort-plus":{
        title:"Dailies AquaComfort", 
        tags:"lentes,dailies aqua",
        image:""
    },
    "freshlook":{
        title:"Freshlook", 
        tags:"lentes,freshlook",
        image:""
    },
    "soflens":{
        title:"Soflens", 
        tags:"lentes,soflens",
        image:""
    },
    "pure-vision-2":{
        title:"Pure Vision 2", 
        tags:"lentes,pure vision",
        image:""
    },
    "ultra":{
        title:"Ultra", 
        tags:"lentes,ultra",
        image:""
    },
    "biomedics":{
        title:"Biomedics", 
        tags:"lentes,biomedics",
        image:""
    },
    "avaira-vitality":{
        title:"Avaira Vitality", 
        tags:"lentes,avaira",
        image:""
    },
    "biofinity":{
        title:"Biofinity", 
        tags:"lentes,biofinity",
        image:""
    },
    "clariti":{
        title:"Clariti", 
        tags:"lentes,clariti",
        image:""
    },
    "acuvue":{
        title:"Acuvue", 
        tags:"lentes,acuvue",
        image:""
    },
    "1-day":{
        title:"1 Day", 
        tags:"lentes,1-day",
        image:""
    },
    "hydrosoft":{
        title:"Hydrosofty", 
        tags:"lentes,hydrosoft",
        image:""
    },
    "alcon":{
        title:"Alcon", 
        tags:"lentes,alcon",
        image:""
    },
    "bausch-lomb":{
        title:"Bausch & Lomb", 
        tags:"lentes,bausch",
        image:""
    },
    "coopervision":{
        title:"CooperVision", 
        tags:"lentes,coopervision",
        image:""
    },
    "johnson-johnson":{
        title:"Johnson & Johnson", 
        tags:"lentes,johnson",
        image:""
    },
    "ital-lent":{
        title:"ITAL-LENT", 
        tags:"lentes,ital-lent",
        image:""
    },
    "all":{
        title:"Todos los lentes",
        tags:"lentes",
        image:""
    }
    
}

export const solutions_categories = {
    "opti-free":{
        title:"Opti Free", 
        tags:"soluciones,opti",
        image:""
    },
    "ao-sept":{
        title:"Ao Sept", 
        tags:"soluciones,ao",
        image:""
    },
    "renu":{
        title:"Renu", 
        tags:"soluciones,renu",
        image:""
    },
    "bio-true":{
        title:"Bio True", 
        tags:"soluciones,bio",
        image:""
    },
    //ck
    "avizor":{
        title:"Avizor",
        tags:"soluciones,avizor",
        image:""
    },
    "todas":{
        title:"Todas las soluciones",
        tags:"soluciones",
        image:""
    }
}


export const drops_categories = {
    "opti-free":{
        title:"Opti Free", 
        tags:"gotas,opti",
        image:""
    },
    "renu":{
        title:"Ao Sept", 
        tags:"gotas,renu",
        image:""
    },
    "systane":{
        title:"Renu", 
        tags:"gotas,systane",
        image:""
    },
    //ck
    "todas":{
        title:"Todas las gotas",
        tags:"gotas",
        image:""
    }

}