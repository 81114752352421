export const content = {
    menu:[
        {
            id:1,
            label:'LENTES DE CONTACTO',
            sections:[
                {
                    id:1, 
                    label:'Por condición visual', 
                    options:[
                        {id:1, label:'Miopía o hipermetropía (esféricos)', url:'/contactlenses/miopia-o-hipermetropia'},
                        {id:3, label:'Astigmatismo (tóricos)', url:'/contactlenses/astigmatismo'},
                        {id:4, label:'Presbicia (multifocales)', url:'/contactlenses/presbicia'},
                        {id:5, label:'Lentes cosméticos y cosmoprotésicos', url:'/contactlenses/lentes-cosmeticos'},
                    ]
                },
                {
                    id:2, 
                    label:'Por reemplazo', 
                    options:[
                        {id:1, label:'Diarios', url:'/contactlenses/uso-diario'},
                        {id:3, label:'Quincenales', url:'/contactlenses/uso-quincenal'},
                        {id:4, label:'Mensuales', url:'/contactlenses/uso-mensual'},
                        {id:5, label:'Anuales', url:'/contactlenses/uso-anual'},
                    ]
                },
                {
                    id:3, 
                    label:'Por marca', 
                    //multipleColumns:true,
                    options:[
                        
                            {id:1, label:'Air Optix', url:'/contactlenses/air-optix'},
                            {id:2, label:'Dailies AquaComfort', url:'/contactlenses/dailies-aqua-comfort-plus'},
                            {id:3, label:'Freshlook', url:'/contactlenses/freshlook'},
                            {id:4, label:'Soflens', url:'/contactlenses/soflens'},
                            {id:5, label:'Pure Vision 2', url:'/contactlenses/pure-vision-2'},
                            {id:6, label:'Ultra', url:'/contactlenses/ultra'},
                        
                        
                            {id:7, label:'Biomedics', url:'/contactlenses/biomedics'},
                            {id:8, label:'Avaira Vitality', url:'/contactlenses/avaira-vitality'},
                            {id:9, label:'Biofinity', url:'/contactlenses/biofinity'},
                            {id:10, label:'Clariti', url:'/contactlenses/clariti'},
                            {id:11, label:'Acuvue', url:'/contactlenses/acuvue'},
                            //{id:12, label:'1-Day', url:'/contactlenses/1-day'},
                            {id:18, label:'Hydrosoft', url:'/contactlenses/hydrosoft'},
                        
                    ]
                },
                {
                    id:4, 
                    label:'Por fabricante', 
                    options:[
                        {id:1, label:'Alcon', url:'/contactlenses/alcon'},
                        {id:2, label:'Bausch & Lomb', url:'/contactlenses/bausch-lomb'},
                        {id:3, label:'CooperVision', url:'/contactlenses/coopervision'},
                        {id:4, label:'Johnson & Johnson', url:'/contactlenses/johnson-johnson'},
                        {id:5, label:'ITAL-LENT', url:'/contactlenses/ital-lent'},
                    ]
                },
                {
                    id:5,
                    label:'Ver todos',
                    options:[{id:1, label:'Ver todos', url:'/contactlenses/all'}]
                }
            ]
        },
        {
            id:2,
            label:'SOLUCIONES',
            sections:[
                {
                    id:1, 
                    label:'Por marca', 
                    options:[
                        {id:1, label:'Opti Free', url:'/solutions/opti-free'},
                        {id:2, label:'Ao Sept', url:'/solutions/ao-sept'},
                        {id:3, label:'Renu', url:'/solutions/renu'},
                        {id:4, label:'Bio True', url:'/solutions/bio-true'},
                        //ck
                        {id:5, label:'Avizor', url:'/solutions/avizor'},
                        {id:6, label:'Ver todas', url:'/solutions/todas'},
                    ]
                },
            ]
        },
        {
            id:3,
            label:'GOTAS',
            sections:[
                {
                    id:1, 
                    label:'Por marca', 
                    options:[
                        {id:1, label:'Opti Free', url:'/drops/opti-free'},
                        {id:2, label:'Renu', url:'/drops/renu'},
                        {id:3, label:'Systane', url:'/drops/systane'},
                        //ck
                        {id:4, label:'Ver todas', url:'/drops/todas'},
                    ]
                },
            ]
        }
    ]
}