import { Grid, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading'

const steps = [
    {id:2, label:'Confirmado'},
    {id:3, label:'En preparación'},
    {id:4, label:'Enviado'},
    {id:5, label:'Entregado'},
]

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:16,
        marginBorrom:16,
        
    },
    summary:{
        marginTop:16,
        border:`1px solid ${blueGrey[100]}`,
        padding:16,
        borderRadius:12
    }
}))

const StepperStatus = props => {

    const classes = useStyles()
    const {status} = props

    return(
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={status-2} style={{padding:0}}>
                {steps.map(item => (
                    <Step key={item.label}>
                        <StepLabel>{item.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className={classes.summary}>
                <Grid container>
                    <Grid item xs={12}>
                        <Heading>Tu orden ha sido confirmada</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText>En esta página podrás estar monitoreando el estatus de tu orden</DisplayText>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default StepperStatus