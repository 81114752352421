import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_login = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  const response = await mainServer.post(`/login`, data)
  return response.data.data
}

export const request_profile = async(data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.get(`/user/me`)
  return response.data.data
}

export const request_update_order = async(id, data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  const response = await mainServer.patch(`/order/${id}`, data)  
  return response.data.data
}

export const MUTATION_LOGIN = gql`
mutation CustomerAccess($email:String!, $password:String!) {
    customerAccessTokenCreate(input: {email: $email, password: $password}) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`  

export const MUTATION_CUSTOMER_ASSOCIATE = gql`
mutation AttachCustomer2Cart($id: ID!, $token: String!){
  checkoutCustomerAssociateV2(checkoutId: $id, customerAccessToken: $token) {
    checkout {
      id
    }
  }
}
`