import React, {useEffect, useState} from 'react'
import Page from '../../components/Structure/Page/Page'
import { Grid, makeStyles } from '@material-ui/core'
import CarouselContainer from '../../components/Structure/CarouselContainer/CarouselContainer'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CustomerSlider from '../../components/Structure/CustomerSlider/CustomerSlider';
import {content} from './content'
import ProductContainer from './components/ProductContainer';
import { withRouter } from 'react-router-dom';
import UbelensBenefits from './components/UbelensBenefits';
import AuthentificationContainer from './components/AuthentificationContainer';
import { connect } from 'react-redux';
import { request_products } from './requests';


const useStyles = makeStyles(theme => ({
    root:{
        //marginTop:98
    },
    
}))

const Home = props => {

    const classes = useStyles()
    const {isauth, history} = props
    const [contactLenses, setContactLenses] = useState([])
    const [solutions, setSolutions] = useState([])
    const [drops, setDrops] = useState([])

    useEffect(() => {
        const initModule = async() => {
            console.log("Init home")
            const _lenses = await request_products('lentes')
            const _solutions = await request_products('soluciones')
            const _drops = await request_products('gotas')
            setContactLenses(_lenses)
            setSolutions(_solutions)
            setDrops(_drops)
        }
        window.scrollTo(0,0)
        initModule()
    },[])

    const onGo2Page = (url) => {
        console.log(url)
    }

    const onSelectedProduct = (url, id) => {
        window.scrollTo(0,0)
        props.history.push(`${url}/${id}`)
    }

    



    return(
        <div className={classes.root}>
            <CarouselContainer />
            <Page nopadding>
                <Grid container>
                    <Grid item xs={12}>
                        <UbelensBenefits />
                    </Grid>
                    {!isauth ? (
                        <Grid item xs={12}>
                            <AuthentificationContainer onGo2Page={onGo2Page} />
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <ProductContainer 
                            title='LENTES DE CONTACTO' button='VER TODO LENTES' url='/contactlenses/all'
                            description='Conoce los lentes de mayor preferencia de nuestros clientes'
                            data={contactLenses} onSelectedProduct={(id) => onSelectedProduct('/contactlense-product',id)}
                            onSelectedCollection={(url) => history.push(url)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ProductContainer 
                            title='NUESTRAS SOLUCIONES' button='VER TODO SOLUCIONES' url='/solutions/todas'
                            description='Conoce las soluciones para un mantenimiento adecuado de tus lentes'
                            data={solutions} onSelectedProduct={(id) => onSelectedProduct('/solution-product',id)}
                            onSelectedCollection={(url) => history.push(url)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ProductContainer 
                            title='NUESTRAS GOTAS' button='VER TODO GOTAS' url='/drops/todas'
                            description='Conoce las gotas para un mantenimiento adecuado de tus lentes'
                            data={drops} onSelectedProduct={(id) => onSelectedProduct('/drop-product',id)}
                            onSelectedCollection={(url) => history.push(url)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerSlider brands={content.brands} />
                    </Grid>
                    
                </Grid>
            </Page>
        </div>
        
    )
}

const mapStateToProps = state => {
    return {
        isauth: state.isauth,
    }
}

export default withRouter( connect(mapStateToProps)(Home))