import React, {useState} from  'react'
import Heading from '../../../components/Texts/Heading'
import { Grid, makeStyles, IconButton, Icon,  TextField, withStyles, Button, CircularProgress } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:theme.spacing(6),
        paddingBottom:theme.spacing(9),
        background:blueGrey[50],
        borderRadius:16
    },  
    buttonContainer:{
        paddingTop:theme.spacing(3),
    },
    label:{marginTop:20},
    iconbutton:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }
}))
//dailies-aqua-comfort-plus
const SimpleProductForm = props => {


    const {onSubmit, loading} = props
    const [quantity, setQuantity] = useState(0)
    const [isValid, setIsValid] = useState(false)
    const classes = useStyles()



    const onInnerSubmit = () => {
        let data2send = {}
        data2send = {...data2send, quantity: quantity}
        onSubmit(data2send)
    }

    const onChangeQuantity = (event) => {
        const number = parseInt(event.target.value)
        if(!isNaN(number)) setQuantity(number)  
        if(event.target.value === ''){
            setQuantity('')
            setIsValid(true)
        }  
    }

    const onIncrement = () => {
        let result = quantity 
        if(quantity === ''){
            setQuantity(1)
            setIsValid(true)
            return
        }

        result++
        setQuantity(result)
        setIsValid(true)

    }

    const onDecrement = () => {
        let result = quantity 
        if(quantity === ''){
            setQuantity(0)
            setIsValid(false)
            return
        }
        result--
        if(result >= 0){
            setQuantity(result)
            setIsValid(true)
        }
        
    }

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>           
                <Grid item xs={12}>
                    <div style={{marginTop:16}}>
                        <Grid container spacing={4} justify='center' alignItems='center'>
                            <Grid item xs={12}>
                                <div><Heading align='center' variant='h6'>Selecciona una cantidad</Heading></div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify='center' spacing={1} wrap='nowrap'>
                                    <Grid item><IconButton onClick={onDecrement}size='small' className={classes.iconbutton}><Icon fontSize='small'>remove</Icon></IconButton></Grid>
                                    <Grid item><CssTextField value={quantity} onChange={onChangeQuantity}/></Grid>
                                    <Grid item><IconButton onClick={onIncrement}size='small' className={classes.iconbutton}><Icon fontSize='small'>add</Icon></IconButton></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                        <Grid container justify='center'>
                            <Grid item >
                                <Button disabled={!isValid} onClick={onInnerSubmit} variant='contained' color='primary' size='large'>
                                    <Icon style={{paddingRight:8}}>shopping_cart</Icon>
                                    Añadir al carrito
                                    {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SimpleProductForm

export const formData = {
    sphere:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'sphere',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    cylinder:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'cylinder',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    axis:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'axis',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    addition:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'addition',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    dominance:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'dominance',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    }, 
    color:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'color',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    }
}

const CssTextField = withStyles(theme => ({
    root: {
      '& input':{
        textAlign:'center'
      },
    },
  }))(TextField);