import gql from 'graphql-tag'
import React, {useEffect} from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import Ecommerce from '../layouts/Ecommerce/Ecommerce'
import CheckoutView from '../views/CheckoutView/CheckoutView'
import {connect} from 'react-redux'
import * as actionTypes from '../store/actions'
import OrderStatusView from '../views/OrderStatusView/OrderStatusView'
import Legal from '../views/Legal/Legal'
import Privacy from '../views/Privacy/Privacy'

const PublicRouters = props => {

    const {history, client} = props

    return(
        <Router history={history}>
            <Switch>
                <Route component={CheckoutView} path='/checkout/:id'/>
                <Route component={OrderStatusView} path='/orders/:id/status'/>
                <Route component={Legal} path='/legal'/>
                <Route component={Privacy} path='/privacy'/>
                <Route path={'/'} component={Ecommerce}/>
                <Redirect to='/'/>
            </Switch>
        </Router>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
        onUpdateApolloClient: (apolloClient) => dispatch({type: actionTypes.UPDATE_APOLLO_CLIENT, apolloClient})
    }
}

export default connect(null, mapDispatchToProps)(PublicRouters)