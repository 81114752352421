import React from 'react'
import DisplayText from '../../../Texts/DisplayText'
import { Grid, makeStyles } from '@material-ui/core'
import Heading from '../../../Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    items:{
        fontFamily:theme.typography.fontFamily,
        color:blueGrey[700],
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.main}
    },
    link:{
        color: blueGrey[700],
        textDecoration:'none',
        '&:hover':{
            cursor:'pointer',
            color:theme.palette.secondary.main
        }
    }
}))

const SingleMenu = props => {

    const classes = useStyles()
    const {title, menu, onSelectedItem } = props

  
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Heading size='large'>{title}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {menu.map((item,key)=>{
                            return(
                                <Grid item xs={12} key={key.toString()}>
                                    <div className={classes.items} >
                                        <a className={classes.link} href={item.url} target='_blanck'>{item.label}</a>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default SingleMenu