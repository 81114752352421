import React, {useState, useEffect} from  'react'
import Heading from '../../../components/Texts/Heading'
import { Grid, makeStyles, IconButton, Icon, InputBase, Input, TextField, withStyles, Button, CircularProgress } from '@material-ui/core'
import SelectForm from '../../../components/Forms/SelectForm'
import leftEye from '../../../assets/left_eye.png'
import rightEye from '../../../assets/right_eye.png'


const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:theme.spacing(3),
        paddingBottom:theme.spacing(3),
    },  
    buttonContainer:{
        paddingTop:theme.spacing(3),
    },
    label:{marginTop:20},
    iconbutton:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    },
    imageContainer:{
        width:72,
        margin:'auto'
    },
    image:{
        width:'100%'
    }
}))

const SeparatedLenseForm = props => {

    const {onSubmit, catalogs, loading, product_id} = props

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [quantityLeft, setQuantityLeft] = useState(1)
    const [quantityRight, setQuantityRight] = useState(1)
    const [isValid, setIsValid] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        const initModule = () => {
            let temp = JSON.parse(JSON.stringify(formData));
            if(catalogs){
                const {spheres, cylinders, axes, adds, colors, dominances} = catalogs
                if(spheres && spheres.length){
                    temp.sphere_left.options = [...spheres]
                    temp.sphere_left.isRequired = true
                    temp.sphere_right.options = [...spheres]
                    temp.sphere_right.isRequired = true
                }
                if(cylinders && cylinders.length){
                    temp.cylinder_left.options = [...cylinders]
                    temp.cylinder_left.isRequired = true
                    temp.cylinder_right.options = [...cylinders]
                    temp.cylinder_right.isRequired = true
                }
                if(axes && axes.length){
                    temp.axis_left.options = [...axes]
                    temp.axis_left.isRequired = true
                    temp.axis_right.options = [...axes]
                    temp.axis_right.isRequired = true
                }
                if(adds && adds.length){
                    temp.addition_left.options = [...adds]
                    temp.addition_left.isRequired = true
                    temp.addition_right.options = [...adds]
                    temp.addition_right.isRequired = true
                }
                if(dominances && dominances.length){
                    temp.dominance_left.options = [...dominances]
                    temp.dominance_left.isRequired = true
                    temp.dominance_right.options = [...dominances]
                    temp.dominance_right.isRequired = true
                }

                if(colors && colors.length){
                    temp.color_left.options = [...colors]
                    temp.color_left.isRequired = true
                    temp.color_right.options = [...colors]
                    temp.color_right.isRequired = true
                }
            }
            setForm(temp)
        }
        initModule()
    },[])

    const onInnerSubmit = () => {
        let data2send = {}
        Object.keys(form).forEach(item => {
            if(form[item].isRequired){
                let value = form[item].options.find(el => el.value === form[item].value);
                data2send = {...data2send, [item]:value.label}
            }
        })
        const final_data = [
            {quantity: quantityLeft, details: JSON.stringify(onGetSingleFormula(data2send,'left')), product_id:product_id},
            {quantity: quantityRight, details: JSON.stringify(onGetSingleFormula(data2send,'right')), product_id:product_id}
        ]
        //console.log(final_data)
        //data2send = {...data2send, quantity: quantityLeft + quantityRight, type:'DIFF',
        //    quantity_left: quantityLeft, quantity_right: quantityRight, }
        onSubmit(final_data)
    }

    const onGetSingleFormula = (data, type) => {
        let data2send = {
            sphere: type === 'right' ? data.sphere_right : data.sphere_left, 
            cylinder: type === 'right' ? data.cylinder_right : data.cylinder_left, 
            axis: type === 'right' ? data.axis_right : data.axis_left, 
            addition: type === 'right' ? data.addition_right : data.addition_left, 
            dominance: type === 'right' ? data.dominance_right : data.dominance_left, 
            color: type === 'right' ? data.color_right : data.color_left, 
            type: type === 'right' ? 'RIGHT' : 'LEFT'
        }
        let result = {...data2send}
        Object.keys(result).forEach(item => {if(data2send[item] === null || data2send[item] === undefined) delete result[item]})
        return result
    }

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setIsValid(isFormValid(temp))
        setForm(temp)
    }

    const isFormValid = (data) => {
        let response = true
        Object.keys(data).forEach(item => {
            if(data[item].isRequired && !data[item].isValid) response = false
        })
        return response
    }

    const onChangeQuantity = (event, key) => {
        const number = parseInt(event.target.value)
        if(key === 'quantityLeft'){
            if(!isNaN(number)) setQuantityLeft(number)  
            if(event.target.value === '') setQuantityLeft('')  
        }else{
            if(!isNaN(number)) setQuantityRight(number)  
            if(event.target.value === '') setQuantityRight('')  
        }
        
    }

    const onIncrement = (key) => {
        if(key === 'quantityLeft'){
            let result = quantityLeft 
            if(quantityLeft === ''){
                setQuantityLeft(1)
                return
            }
            result++
            setQuantityLeft(result)
        }else{
            let result = quantityRight 
            if(quantityRight === ''){
                setQuantityRight(1)
                return
            }
            result++
            setQuantityRight(result)
        }
        
    }

    const onDecrement = (key) => {
        if(key === 'quantityLeft'){
            let result = quantityLeft 
            if(quantityLeft === ''){
                setQuantityLeft(1)
                return
            }
            result--
            if(result >= 1) setQuantityLeft(result)
        }else{
            let result = quantityRight 
            if(quantityRight === ''){
                setQuantityRight(1)
                return
            }
            result--
            if(result >= 1) setQuantityRight(result)
        }
        
    }

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} spacing={2}>
                    <Grid container>
                        <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <div className={classes.imageContainer}><img src={leftEye} alt='' className={classes.image}/></div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.imageContainer}><img src={rightEye} alt='' className={classes.image}/></div>
                        </Grid>
                    </Grid>
                    
                </Grid>
                {form.sphere_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Poder (SPH)</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.sphere_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.sphere_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {form.cylinder_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Ciindro (CYL)</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.cylinder_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.cylinder_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {form.axis_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Eje (AXIS)</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.axis_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.axis_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {form.addition_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Adición (ADD)</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.addition_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.addition_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {form.dominance_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Dominance (DOM)</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.dominance_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.dominance_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                
                {form.color_left.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={4}>
                                <div className={classes.label}><Heading align='left'>Color</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.color_left} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectForm data={form.color_right} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <div style={{marginTop:16}}>
                        <Grid container spacing={2} justify='center' alignItems='center'>
                            <Grid item xs={4}>
                                <div><Heading align='left'>Cantidad</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify='center' spacing={1} wrap='nowrap'>
                                    <Grid item><IconButton onClick={()=>onDecrement('quantityLeft')}size='small' className={classes.iconbutton}><Icon fontSize='small'>remove</Icon></IconButton></Grid>
                                    <Grid item><CssTextField value={quantityLeft} onChange={(e) => onChangeQuantity(e,'quantityLeft')}/></Grid>
                                    <Grid item><IconButton onClick={()=>onIncrement('quantityLeft')}size='small' className={classes.iconbutton}><Icon fontSize='small'>add</Icon></IconButton></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify='center' spacing={1} wrap='nowrap'>
                                    <Grid item><IconButton onClick={()=>onDecrement('quantityRight')}size='small' className={classes.iconbutton}><Icon fontSize='small'>remove</Icon></IconButton></Grid>
                                    <Grid item><CssTextField value={quantityRight} onChange={(e) => onChangeQuantity(e,'quantityRight')}/></Grid>
                                    <Grid item><IconButton onClick={()=>onIncrement('quantityRight')}size='small' className={classes.iconbutton}><Icon fontSize='small'>add</Icon></IconButton></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                        <Grid container justify='center'>
                            <Grid item >
                                <Button disabled={!isValid} onClick={onInnerSubmit} variant='contained' color='primary' size='large'>
                                    <Icon style={{paddingRight:8}}>shopping_cart</Icon>
                                    Añadir al carrito
                                    {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SeparatedLenseForm

export const formData = {
    sphere_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'sphere_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    cylinder_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'cylinder_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    axis_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'axis_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    sphere_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'sphere_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    cylinder_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'cylinder_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    axis_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'axis_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    addition_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'addition_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    addition_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'addition_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    dominance_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'dominance_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    dominance_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'dominance_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    color_left:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'color_left',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    color_right:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'color_right',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
}

const CssTextField = withStyles(theme => ({
    root: {
      '& input':{
        textAlign:'center'
      },
    },
  }))(TextField);