import React, {useState} from 'react'
import { Grid, makeStyles, Button, CircularProgress } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import Heading from '../../../components/Texts/Heading' 
import { onGetFullname, onGetCompleteAddress } from '../../../shared/utility'
import { withRouter } from 'react-router-dom'
import CheckoutDisplayData from './CheckoutDisplayData'
import COShippingRates from './COShippingRate'
import OpticalContianer from './OpticalContianer'

const useStyles = makeStyles(theme => ({
    display:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'4px 16px',
    },
    form:{
        marginTop:12
    }
}))

const availableShippingRates = [
    {title:'Estándar', price:"0.0"}
]

const CheckoutShipping = props => {

    const classes = useStyles()

    const {order, seller, onChangeStep} = props

    const [loading, setLoading] = useState(false)


    const onSubmit = async() => {try {
        onChangeStep(3)
        setLoading(false)
    } catch (error) {
        console.log(error)       
    }}



    return(
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <OpticalContianer notitle optometrist={order ? order.optometrist : null} optical={order ? order.optical : null}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutDisplayData label='Dirección de envío' value={onGetCompleteAddress(order ? order.shipping_address : null)} onEdit={() => onChangeStep(1)}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Heading variant='h6'>Método de envío</Heading>
                        <div className={classes.form}>
                            <Grid container spacing={2}>
                                {availableShippingRates.map((item,key)=>{
                                    return(
                                        <Grid item xs={12} key={key.toString()}>
                                            <COShippingRates {...item}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end' spacing={3}>
                        <Grid item>
                            <Button variant='text' size='large' onClick={() => onChangeStep(1)} >Regresar</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' onClick={onSubmit}
                            color='primary' size='large'>Continuar
                            {loading ? (<CircularProgress style={{color:'#FFF', marginLeft:8}} size={24}/>) : null}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(CheckoutShipping)