import React from  'react'
import { makeStyles, Breadcrumbs } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import clsx from 'clsx'

const menu = [
    {id:1, label:'Información'},
    {id:2, label:'Envío'},
    {id:3, label:'Pago'},
]

const useStyles = makeStyles(theme=>({
    link:{
        color:grey[600],  fontWeight:500,
        '&:hover':{cursor:'pointer'}
    },
    selected:{
        color:theme.palette.secondary.main,
       
    }
}))

const CheckoutStepper = props => {

    const {onChange, selected} = props
    const classes = useStyles()

    return(
        <Breadcrumbs separator='>'>
            {menu.map((item,key)=>{
                return(
                    <div className={clsx({
                        [classes.link]:true,
                        [classes.selected]:selected === item.id
                    })} onClick={() => onChange(item.id)} key={key.toString()}>
                        {item.label}
                    </div>
                )
            })}
        </Breadcrumbs>
    )
}

export default CheckoutStepper