import React, {useState} from 'react'
import { makeStyles, Avatar, Fade } from '@material-ui/core'
import clsx from 'clsx'
import { blueGrey } from '@material-ui/core/colors'
import noproduct from '../../../assets/noproduct.png'
import { config_data } from '../../../variables/config'

const useStyles = makeStyles(theme => ({
    imageContainer:{
        width:500,
        height:500,
        position:'relative',
        margin:'auto',
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(4),
        [theme.breakpoints.only('md')]: {
            width:400,
            height:400,
        },
    },
    shopContainer:{
        width:120,
        height:120,
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
        [theme.breakpoints.down('sm')]: {
            width:'100%',
            height:200,
        },
    },
    checkoutContainer:{
        width:80,
        height:80,
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:12,
        background:'white',
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    listContainer:{
        width:'100%',
        height:'auto',
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    quantity:{
        position:'absolute',
        top:-12,
        right:-12,
        background:theme.palette.primary.main,
        fontSize:14,
        width:24,
        height:24

    }
}))

const ProductImage = props => {

    const classes = useStyles()
    const {type, image, quantity} = props
    const [error, setError] = useState(true)

    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)

    let imgSrc = image ? `${config_data.S3_SERVER_URL}${image}` : null
    //console.log(imgSrc)

    return(
        <div className={clsx({
            [classes.imageContainer]:true,
            [classes.shopContainer]:type === 'CART',
            [classes.checkoutContainer]:type === 'CHECKOUT',
            [classes.listContainer]:type === 'LIST'

        })}>
            {quantity ? <Avatar className={classes.quantity} color='primary'>{quantity}</Avatar> : null}
            {error ? <img alt='' src={noproduct} className={classes.image}/> : null}
            <Fade in={!error}><img alt='' src={imgSrc} className={classes.image} onLoad={onImageLoad} onError={onError} /></Fade>
        </div>
    )
}

export default ProductImage