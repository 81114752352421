import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import ProductImage from '../../../components/Structure/Images/ProductImage'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'
import { onGetFormatNumber } from '../../../shared/utility'


const useStyles = makeStyles(theme => ({
    root:{
        top:56,
        position:'fixed',  
        width:506,
        [theme.breakpoints.down('md')]: {
            width: 420,
            boxSizing:'border-box'
        }, 
        [theme.breakpoints.down('sm')]: {
            position:'relative',
            width:'100%',
            top:0
        }, 
    },
    image:{
        width:64
    },
    listContainer:{
        paddingBottom:24,
        borderBottom:`1px solid ${blueGrey[100]}`
    },
    details:{
        paddingTop:24,
        paddingBottom:24,
        borderBottom:`1px solid ${blueGrey[100]}`
    },
    total:{
        paddingTop:24,
    },
    buttonContainer:{
        paddingTop:32
    }
}))

const CheckoutRight = props => {

    const {order} = props
    const classes = useStyles()

    let lineItems = [] 
    let totalPrice = 0;
    if(order){
        lineItems = order.line_items
        totalPrice = onGetFormatNumber(order.subtotal)
    }

    return(
        <div className={classes.root}>
            <div className={classes.listContainer}>
                <Grid container spacing={2}>
                    {lineItems.map((item,key)=>{

                        const {quantity, title, total_price, image} = item
                        const singlePrice = onGetFormatNumber(total_price)

                        return(
                            <Grid item xs={12} key={key.toString()} >
                                <Grid container spacing={3} wrap='nowrap' alignItems='center'>
                                    <Grid item>
                                        <ProductImage type='CHECKOUT' image={image} quantity={quantity}/>
                                    </Grid>
                                    <Grid item xs>
                                        <DisplayText>{title}</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <Heading>{`$ ${singlePrice}`}</Heading> 
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            <div className={classes.details}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs><DisplayText>Subtotal</DisplayText></Grid>
                            <Grid item><Heading>{`$ ${totalPrice}`}</Heading></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs><DisplayText>Envío</DisplayText></Grid>
                            <Grid item><Heading>Gratis</Heading></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.total}>
                <Grid container spacing={1}>
                    <Grid item xs><DisplayText variant='h6'>Total</DisplayText></Grid>
                    <Grid item><Heading variant='h5'>{`$ ${totalPrice}`}</Heading></Grid>
                </Grid>
            </div>
        </div>
    )
}

export default CheckoutRight