import React from 'react'
import { Card, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        padding:0, margin:0, borderRadius:16, 
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        boxShadow:'none',
        //border:`1px solid ${grey[200]}`
    },
}))

const CardContainer = props => {

    const classes = useStyles()

    return(
        <Card className={classes.root}>
            {props.children}
        </Card>
    )
}

export default CardContainer