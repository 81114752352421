import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Heading from '../../../Texts/Heading'
import { Link } from 'react-router-dom'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    link:{
        textDecoration:'none',
        fontFamily:'Work Sans',
        fontSize:14,
        color:blueGrey[700],
        fontWeight:400,
    }
}))

const SectionItem = props => {

    const {label, options, multipleColumns, onItemSelected} = props
    const classes = useStyles()

    let optionsView = null

    console.log(options)

    
    if(options && options.length){
        if(!multipleColumns){
            optionsView = (
                <Grid item>
                    <Grid container direction='column' spacing={1}>
                        {options.map((item,key)=>{
                            return(
                                <Grid item key={key.toString()}>
                                        <Link className={classes.link} onClick={() => onItemSelected(item.url)}>{item.label}</Link>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            )
        }else{
            optionsView = (
                <Grid container spacing={4} wrap="nowrap">
                    {options.map((item,key)=>{
                        return(
                            <Grid item key={key.toString()}>
                                <Grid container direction='column' spacing={1}>
                                    {item.map((el,id)=>{
                                        return(
                                            <Grid item key={id.toString()}>
                                                <Link className={classes.link} onClick={() => onItemSelected(el.url)}>{el.label}</Link>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            )

        }
        
    }

    return(
        <div>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <Heading size='large' noWrap>{label}</Heading>
                </Grid>
                {optionsView}
            </Grid>
        </div>
    )
}

export default SectionItem