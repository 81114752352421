import React, {forwardRef, useState, useImperativeHandle} from 'react'
import { Grid, makeStyles, Button, CircularProgress } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading' 
import { isFormValid, onGetExpirationFormat, onGetCompleteAddress, onGetCreditCardFormat, onGetFullname, isExpDateIsValid, isCCVIsValid, onInitForm, onGetFormData } from '../../../shared/utility'
import { request_complete_order, request_payment_cash, request_update_billing_address, request_payment_credit_card, 
    request_payment_credit_card_verification, request_mercadopago_payment_link, request_order, request_mercadopago_preference,
    request_mercadopago_payment } from '../requests'
import { withRouter } from 'react-router-dom'
import CheckoutDisplayData from './CheckoutDisplayData'
import CheckoutCreditCard from './CheckoutCreditCard'
import PaymentOption from './PaymentOption'
import BillingAddressForm from './BillingAddressFom'
import paymentTypesAccepted from '../../../assets/payment-cards.png'
import LoadingModal from '../modals/LoadingModal'
import ErrorModal from '../modals/ErrorModal'
import ContainedButton from '../../../components/Actions/Buttons/ContainedButton'
import mplogo from '../../../assets/mercadopago-logo.png'
import pselogo from '../../../assets/logo-pse.png'
import MercadoPagoModal from '../modals/MercadoPagoModal'
import OpticalContianer from './OpticalContianer'
import { catalogs } from '../../../texts/esp/catalogs'

const useStyles = makeStyles(theme => ({
    display:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'4px 16px',
        marginTop:8
    },
    form:{
        marginTop:12
    }
}))

const CheckoutPayment = forwardRef((props) => {

    const classes = useStyles()

    const {seller, order, onChangeStep, passedInRef, history, user, onUpdateOrder} = props

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [billingAddressForm, setBillingAddressForm] = useState(JSON.parse(JSON.stringify(billingAddressFormData)))

    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mpModal, setMpModal] = useState(false)
    const [mpLink, setMpLink] = useState('')
    const [paymentOption, setPaymentOption] = useState(1)
    const [paymentType, setPaymentType] = useState(null)
    const [billingAddressOption, setBillingAddressOptiion] = useState(1) // Default, same as shipping address
    const [error, setError] = useState(null)

    useImperativeHandle(passedInRef, () => ({
        submit: () => {
            console.log("Dispatched")
            onSubmit()
        }
    }))

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'cardNumber' && data.value ) {
            const cardValue = onGetCreditCardFormat(data.value)
            onChangeCardNumber(cardValue.split(' ').join(''))
            temp[id].value = cardValue
        }
        if(id === 'expiration' && data.value){
            const expValue = onGetExpirationFormat(data.value)
            temp[id].value = expValue
            temp[id].isValid = isExpDateIsValid(expValue)
        }
        if(id === 'securityCode' && data.value) temp[id].isValid = isCCVIsValid(data.value)
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onChangeBilling = (data) => {
        let temp = {...billingAddressForm}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setBillingAddressForm(temp)
        //setIsValid(isFormValid(temp))
    }

    const onChangeCardNumber = (value) => {
        const cardValue = value
        if(cardValue.length >= 6){
            let bin = cardValue.substring(0,6);
            window.Mercadopago.getPaymentMethod({'bin':bin}, setPaymentMethod)
        }
    }

    const setPaymentMethod = (status, response) => {
        if(status === 200){
            //console.log(response)
            const paymentMethod = response[0]
            console.log(paymentMethod)
            setPaymentType(paymentMethod)
        }else{
            setPaymentType(null)
            console.log(`error: ${JSON.stringify(response)}`)
        }
    }

    const onSubmit = async() => {
        const orderID = localStorage.getItem('orderID')
        //setLoading(true)
        try {

            // Check billing address path
            let data2send
            if(billingAddressOption === 1){ // Same as shipping_address
                data2send = { address1: order.shipping_address.address1, address2: order.shipping_address.address2, province: order.shipping_address.province, city: order.shipping_address.city, country: order.shipping_address.country}
            }else{
                const is_valid_form = isFormValid(billingAddressForm)
                if(is_valid_form){
                    data2send = onGetFormData(billingAddressForm)
                }else{
                    setError('Datos imcompletos de dirección de facturación')
                    return
                }
            }

            //console.log(data2send)
            await request_update_billing_address(orderID, data2send)

            if(paymentOption === 1){
                const {payment} = order
                if(payment){
                    const {token_id, payment_url, payment_id, gateway} = payment
                    console.log('Existe un posible pago')
                    if(gateway === 'mercadopago'){
                        const _payment = await request_mercadopago_payment(order._id) //Check if there is a valid payment, watch out! Pending or rejected payments has not beeen updated
                        if(_payment.results.length === 0){
                            const _preference = await request_mercadopago_preference(token_id) // If not payment, review token_id
                            if(_preference.items.length){
                                
                                if(parseInt(_preference.items[0].unit_price) === parseInt(order.total)){ // If there is no change, one can reuse the token
                                    console.log('Usar mismo codigo')
                                    setMpLink(payment_url)
                                    setMpModal(true)
                                }else{ // There is a change, a new token muts be generated
                                    console.log('Generar uno nuevo')
                                    const mercardoPagoLink = await request_mercadopago_payment_link(order._id) 
                                    const _order = await request_order(order._id)
                                    onUpdateOrder(_order)
                                    setMpLink(mercardoPagoLink)
                                    setMpModal(true)
                                }
                            
                        }else{
                            return
                        }}
                    }
                    
                }else{
                    console.log('Proceder a generar link de compra')
                    const mercardoPagoLink = await request_mercadopago_payment_link(order._id)
                    const _order = await request_order(order._id)
                    onUpdateOrder(_order)
                    setMpLink(mercardoPagoLink)
                    setMpModal(true)
                }
            }

            // Proceed to pay
            /*if(paymentOption === 1){ // Mercado pago
                // Request link for payment
                const data2send = {total_price:order.total}
                console.log(data2send)
                const mercardoPagoLink = await request_mercadopago_payment_link(orderID)
                setMpLink(mercardoPagoLink)
                setMpModal(true)
            }else{ // Cash paymeny
                console.log('Pago en efectivo')
                setLoading(true)
                await request_payment_cash(orderID, {transaction_amount:order.subtotal})
                await request_complete_order(orderID)
                console.log('Compra exitoso')
                setLoading(false)
                localStorage.removeItem('orderID')
                history.push(`/orders/${orderID}/status`)

            }*/
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError('Hubo un error con la respuesta del servidor')

        }

        
        
    }

    const onTokenProcessed = (status, response) => {
        //console.log(status)
        //console.log(response)
        if(status === 200) onProceedCheckout(response.id)
        if(status === 400){
            setLoading(false)
            setError('Hubo un error con la información de su tarjeta, revíse sus datos y vuelva a intentarlo')
        }

    }

    const onUseCurrentAddress = () => {
        if(user && user.billing_address){
            const data2send = {
                address1: user.shipping_address.address1,
                address2: user.shipping_address.address2,
                province: user.shipping_address.province,
                city: user.shipping_address.city,
                country: user.shipping_address.country,
            }
            let _form = onInitForm(billingAddressForm, data2send)
            if(data2send.province){
                const provice_catalog = catalogs.cities[data2send.province]
                if(provice_catalog) _form.city.options = [...provice_catalog]
            }
            setBillingAddressForm(_form)
        }else{
            console.log('No tengo información registrada')
        }
    }

    const onProceedCheckout = async(token) => {

        try {
            const orderID = localStorage.getItem('orderID')
            //Update must be done to order state

            //Create credit_card payment
            console.log('Pago con tarjeta')
            const data2send = {token:token,transaction_amount:order.subtotal,payment_method_id:paymentType.id,email:order.email}
            const transaction_id = await request_payment_credit_card(orderID, data2send)
            await request_payment_credit_card_verification(orderID, {transaction_id:transaction_id})
            await request_complete_order(orderID)
            console.log('Compra exitoso')
            setLoading(false)
            localStorage.removeItem('orderID')
            history.push(`/orders/${orderID}/status`)
            
            
        } catch (error) {
            console.log(error)
            setLoading(false)
            setError('El pago fue rechazado, verifique su información o directamente con su proveedor')

        }
    }

    let paymentContent = null
    let billingAddressFormContent = null

    if(paymentOption === 1) paymentContent = (
        <Grid container justify='flex-end' spacing={1}>
            <Grid item><Grid item><img src={mplogo} alt='' width={80}/></Grid></Grid>
            <Grid item ><img src={pselogo} alt='' width={26}/></Grid>
        </Grid>
    )
    if(billingAddressOption === 2){
        billingAddressFormContent = (
            <div style={{paddingTop:16}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <DisplayText variant='subtitle1'>Ingresa tus datos</DisplayText>
                            </Grid>
                            <Grid item>
                                <Button variant='text' color='secondary' onClick={onUseCurrentAddress} >Usar mi dirección</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <BillingAddressForm form={billingAddressForm} onChange={onChangeBilling}/>
                    </Grid>
                </Grid>
            </div>
        )
    }

    //console.log(mpLink)

    return(
        <div>
            <MercadoPagoModal url={mpLink} open={mpModal} onClose={() => setMpModal(false)}/>
            <LoadingModal open={loading} />
            <ErrorModal open={Boolean(error)} message={error} onClose={() => setError(null)}/>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <OpticalContianer notitle optometrist={order ? order.optometrist : null} optical={order ? order.optical : null}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutDisplayData label='Dirección de envío' value={onGetCompleteAddress(order ? order.shipping_address : null)} onEdit={() => onChangeStep(1)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckoutDisplayData label='Método' value={'Estándar - Gratis'} onEdit={() => onChangeStep(2)}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Heading variant='h6'>Pago</Heading>
                        <DisplayText>Todas las transacciones son seguras y encriptadas</DisplayText>
                        <div className={classes.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <PaymentOption label='Pago con Tarjeta de Crédito/Débito' image={{src:paymentTypesAccepted, width:200}}
                                            selected={paymentOption === 1} onChange={() => setPaymentOption(1)} 
                                            content={paymentContent}/>
                                        </Grid>
                                        {/*<Grid item xs={12}>
                                            <PaymentOption label='Pago en efectivo' 
                                            selected={paymentOption === 2} onChange={() => setPaymentOption(2)}/>
                                        </Grid>*/}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Heading variant='h6'>Dirección de facturación</Heading>
                        <div className={classes.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <PaymentOption label='Usar mi dirección de envío' 
                                            selected={billingAddressOption === 1} onChange={() => setBillingAddressOptiion(1)}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PaymentOption label='Usar una dirección diferente' 
                                            selected={billingAddressOption === 2} onChange={() => setBillingAddressOptiion(2)}/>
                                        </Grid>
                                    </Grid>
                                    {billingAddressFormContent}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end' spacing={3}>
                        <Grid item>
                            <Button variant='text' size='large' onClick={() => onChangeStep(2)} >Regresar</Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' onClick={onSubmit}
                            color='primary' size='large'>Pagar
                            {loading ? (<CircularProgress style={{color:'#FFF', marginLeft:8}} size={24}/>) : null}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
})

export default withRouter(CheckoutPayment)

const formData = {
    cardholderName:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'cardholderName',
          type:'text',
          fullWidth: true,
          label:'Nombre del tarjetahabiente',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    cardNumber:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'cardNumber',
          type:'text',
          fullWidth: true,
          label:'No. de tarjeta',
          helperText:'Número inválido'
        },
        rules:{
          type:'none',
        }
    },
    expiration:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'expiration',
          type:'text',
          fullWidth: true,
          label:'Expiración',
          placeholder:'MM / YY',
          helperText:'MM / YY'
        },
        rules:{
          type:'expiration_card',
        }
    },
    securityCode:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'securityCode',
          type:'number',
          fullWidth: true,
          label:'CVV',
          placeholder:'123',
          helperText:'Número no válido'
        },
        rules:{
          type:'distance',
        }
    },
}

const billingAddressFormData = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 2)',
          helperText:''
        },
        rules:{
          type:'none',
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:''
        },
        rules:{
          type:'select',
        }
    },
    
}