import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_order = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/order/${id}`)
    return response.data.data
}

export const request_update_lineitem = async(id, lineitemid, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}/lineitem/${lineitemid}`, data)
    return response.data.data
}
