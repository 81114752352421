import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Button, CircularProgress } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import Heading from '../../../components/Texts/Heading' 
import InputForm from '../../../components/Forms/InputForm'
import { isFormValid, isFormValidV2, onGetFormData, onGetFullname, onInitForm, onSetErrorsToForm } from '../../../shared/utility'
import SelectForm from '../../../components/Forms/SelectForm'
import { request_update_shipping_address, request_update_order, UPDATE_MAILING_ADDRESS, request_order } from '../requests'
import { withRouter } from 'react-router-dom'
import OpticalContianer from './OpticalContianer'
import { catalogs } from '../../../texts/esp/catalogs'

const useStyles = makeStyles(theme => ({
    display:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'4px 16px',
        marginTop:8
    },
    form:{
        marginTop:12
    }
}))

const CheckoutInformation = props => {

    const classes = useStyles()

    const {seller, match, onUpdateOrder, order, onChangeStep, user} = props

    

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(order)
        if(order){
            const {shipping_address, email} = order
            const _form = onInitForm(form, shipping_address)
            if(shipping_address && shipping_address.province){
                const provice_catalog = catalogs.cities[shipping_address.province]
                if(provice_catalog) _form.city.options = [...provice_catalog]
            }
            setIsValid(isFormValid(_form))
            setForm(_form)
        }
    }, [order])


    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = () => {
        console.log('Submit')

        const errors = isFormValidV2(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        let data2send = onGetFormData(form)
        onUpdateCheckout(data2send)
    }

    const onUpdateCheckout = async(data) => {
        const {id} = match.params

        try {
            setLoading(true)
            await request_update_shipping_address(id,data)
            const _order = await request_order(id)
            onUpdateOrder(_order)
            setLoading(false)
            onChangeStep(2)
        } catch (error) {
            console.log(error)
        }
    }

    const onUseCurrentAddress = () => {
        if(user && user.shipping_address){
            const data2send = {
                address1: user.shipping_address.address1,
                address2: user.shipping_address.address2,
                province: user.shipping_address.province,
                city: user.shipping_address.city,
                country: user.shipping_address.country,
            }
            let _form = onInitForm(form, data2send)
            if(data2send.province){
                const provice_catalog = catalogs.cities[data2send.province]
                if(provice_catalog) _form.city.options = [...provice_catalog]
            }
            setForm(_form)
        }else{
            console.log('No tengo información registrada')
        }
    }

    return(
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <OpticalContianer optometrist={order ? order.optometrist : null} optical={order ? order.optical : null}/>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Grid container>
                            <Grid item xs>
                                <Heading variant='h6'>Información de envío</Heading>
                            </Grid>
                            <Grid item>
                                <Button variant='text' color='secondary' onClick={onUseCurrentAddress} >Usar mi dirección</Button>
                            </Grid>
                        </Grid>
                        <div className={classes.form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputForm data={form.address1} onChange={onChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.address2} onChange={onChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectForm data={form.province} onChange={onChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectForm data={form.city} onChange={onChange}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectForm data={form.country} onChange={onChange}/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <Button variant='contained' onClick={onSubmit}
                            color='primary' size='large'>Continuar
                            {loading ? (<CircularProgress style={{color:'#FFF', marginLeft:8}} size={24}/>) : null}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withRouter(CheckoutInformation)

const formData = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El número de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 2)',
          helperText:'El número de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:''
        },
        rules:{
          type:'select',
        }
    },
    
}