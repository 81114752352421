import React, {useEffect} from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import SimpleButton from '../../components/Actions/Buttons/SimpleButton'
import Page from '../../components/Structure/Page/Page'
import DisplayText from '../../components/Texts/DisplayText'
import Heading from '../../components/Texts/Heading'
import queryString from 'query-string'
import { request_email_confirmation } from './requests'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'

const useStyles = makeStyles(theme => ({
    textContainer:{
        margin:'auto',
        width:600
    },
    icon:{
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        fontSize:100,
        padding:12
    }
}))

const EmailConfirmationView = props => {

    const classes = useStyles()
    const {onChangeAuthStatus} = props

    useEffect(() => {
        const initModule = async() => {
            const data = queryString.parse(props.history.location.search)
            try {
                const access_data = await request_email_confirmation({registration_token:data.token})
                localStorage.setItem('token_data',JSON.stringify(access_data))
                onChangeAuthStatus(true)
            } catch (error) {
                //console.log('hola error')
                console.log(error)
            }
        }
        initModule()
    })

    return(
        <div>
            <Page>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Heading variant='h3' align='center'>¡Felicidades!</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.textContainer}>
                            <DisplayText variant='h6' align='center'>Tu correo electrónico ha sido confirmado, te invitamos a hacer uso de los privilegios dentro de Ubelens</DisplayText>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='center'>
                            <Grid item><Icon className={classes.icon}>done</Icon></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='center'>
                            <Grid item><div style={{paddingTop:32}}>
                            <SimpleButton color='primary' onClick={() => props.history.push('/')}>Empezar a comprar</SimpleButton>
                            </div></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Page>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth }),
    }
}

export default connect(null,mapDispatchToProps)(EmailConfirmationView)