import React, {useState, useEffect} from  'react'
import Heading from '../../../components/Texts/Heading'
import { Grid, makeStyles, IconButton, Icon, InputBase, Input, TextField, withStyles, Button, CircularProgress } from '@material-ui/core'
import SelectForm from '../../../components/Forms/SelectForm'
import { catalogs } from '../../../variables/catalogs'

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:theme.spacing(3),
        paddingBottom:theme.spacing(3),
    },  
    buttonContainer:{
        paddingTop:theme.spacing(3),
    },
    label:{marginTop:20},
    iconbutton:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }
}))
//dailies-aqua-comfort-plus
const CombinedLenseForm = props => {


    const {onSubmit, catalogs, loading, product_id} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [quantity, setQuantity] = useState(1)
    const [isValid, setIsValid] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        const initModule = () => {
            let temp = JSON.parse(JSON.stringify(formData));
            if(catalogs){
                const {spheres, cylinders, axes, adds, colors, dominances} = catalogs
                if(spheres && spheres.length){
                    temp.sphere.options = [...spheres]
                    temp.sphere.isRequired = true
                }
                if(cylinders && cylinders.length){
                    temp.cylinder.options = [...cylinders]
                    temp.cylinder.isRequired = true
                }
                if(axes && axes.length){
                    temp.axis.options = [...axes]
                    temp.axis.isRequired = true
                }
                if(adds && adds.length){
                    temp.addition.options = [...adds]
                    temp.addition.isRequired = true
                }
                if(dominances && dominances.length){
                    temp.dominance.options = [...dominances]
                    temp.dominance.isRequired = true
                }

                if(colors && colors.length){
                    temp.color.options = [...colors]
                    temp.color.isRequired = true
                }
            }
            setForm(temp)
        }
        initModule()
    },[])

    const onInnerSubmit = () => {
        let data2send = {}
        Object.keys(form).forEach(item => {
            if(form[item].isRequired){
                let value = form[item].options.find(el => el.value === form[item].value);
                data2send = {...data2send, [item]:value.label}
            }
        })
        data2send = [{details:JSON.stringify({...data2send, type:'EQUAL'}), quantity: quantity, product_id:product_id}]
        //console.log(data2send)
        onSubmit(data2send)
    }

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setIsValid(isFormValid(temp))
        setForm(temp)
    }

    const isFormValid = (data) => {
        let response = true
        Object.keys(data).forEach(item => {
            if(data[item].isRequired && !data[item].isValid) response = false
        })
        return response
    }

    const onChangeQuantity = (event) => {
        const number = parseInt(event.target.value)
        if(!isNaN(number)) setQuantity(number)  
        if(event.target.value === '') setQuantity('')  
    }

    const onIncrement = () => {
        let result = quantity 
        if(quantity === ''){
            setQuantity(1)
            return
        }

        result++
        setQuantity(result)
    }

    const onDecrement = () => {
        let result = quantity 
        if(quantity === ''){
            setQuantity(1)
            return
        }
        result--
        if(result >= 1) setQuantity(result)
        
    }

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                {form.sphere.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Poder (SPH)</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.sphere} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                
                {form.cylinder.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Cilindro (CYL)</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.cylinder} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                
                {form.axis.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Eje (AXIS)</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.axis} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {form.addition.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Adición (ADD)</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.addition} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {form.dominance.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Dominance (DOM)</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.dominance} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {form.color.isRequired ? (
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify='center'>
                            <Grid item xs={6} md={4}>
                                <div className={classes.label}><Heading align='left'>Color</Heading></div>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <SelectForm data={form.color} onChange={onChange}/>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                
                <Grid item xs={12}>
                    <div style={{marginTop:16}}>
                        <Grid container spacing={2} justify='center' alignItems='center'>
                            <Grid item xs={4}>
                                <div><Heading align='left'>Cantidad</Heading></div>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify='center' spacing={1} wrap='nowrap'>
                                    <Grid item><IconButton onClick={onDecrement}size='small' className={classes.iconbutton}><Icon fontSize='small'>remove</Icon></IconButton></Grid>
                                    <Grid item><CssTextField value={quantity} onChange={onChangeQuantity}/></Grid>
                                    <Grid item><IconButton onClick={onIncrement}size='small' className={classes.iconbutton}><Icon fontSize='small'>add</Icon></IconButton></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.buttonContainer}>
                        <Grid container justify='center'>
                            <Grid item >
                                <Button disabled={!isValid} onClick={onInnerSubmit} variant='contained' color='primary' size='large'>
                                    <Icon style={{paddingRight:8}}>shopping_cart</Icon>
                                    Añadir al carrito
                                    {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CombinedLenseForm

export const formData = {
    sphere:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'sphere',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    cylinder:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'cylinder',
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    axis:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'axis',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    addition:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'addition',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    },
    dominance:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'dominance',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    }, 
    color:{
        value:null,
        isValid:false,
        isVisited:false,
        isRequired:false,
        options:[],
        config:{
            id:'color',  
            fullWidth:true,
            placeholder:'seld'
        },
        rules:{
            type:'select'
        }
    }
}

const CssTextField = withStyles(theme => ({
    root: {
      '& input':{
        textAlign:'center'
      },
    },
  }))(TextField);