import { updateObject } from '../shared/utility'
import * as actionTypes from './actions'

const initialState = {
    user:null,
    isauth:false,
    apolloClient:null,
    cartItems:0,
    login_modal:false,
    signup_modal:false,
    recover_password_modal:false,
    confirm_link:false
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.CHANGE_AUTH_STATUS:
            return updateObject(state, {isauth:action.isauth})
        case actionTypes.AUTH_LOGIN_MODAL:
            return updateObject(state, {login_modal:action.login_modal})
        case actionTypes.AUTH_SINGUP_MODAL:
            return updateObject(state, {signup_modal:action.signup_modal})
        case actionTypes.AUTH_RECOVER_PASSWORD_MODAL:
            console.log('Ejectura')
            return updateObject(state, {recover_password_modal:action.recover_password_modal})
        case actionTypes.UPDATE_CART_ITEMS:
            return{
                ...state,
                cartItems:action.cartItems,
            }
        case actionTypes.UPDATE_APOLLO_CLIENT:
            return{
                ...state,
                apolloClient:action.apolloClient,
            }
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_CONFIRM_LINK:
            return updateObject(state, {confirm_link: action.confirm_link})
        default:
            return state;

    }
}

export default reducer;