import React, {useState} from 'react'
import CardContainer from '../../../components/Structure/CardContainer/CardContainer'
import { CardContent, makeStyles, Grid, Checkbox, Radio } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import Heading from '../../../components/Texts/Heading'
import DisplayText from '../../../components/Texts/DisplayText'
import CombinedLenseForm from './CombinedLenseForm'
import SeparatedLenseForm from './SeparatedLenseForm'


const useSytles = makeStyles(theme => ({
    root:{},
    card:{
        background:blueGrey[50],
        borderRadius:16,
        padding:theme.spacing(2),
        paddingTop:theme.spacing(4)
    },
    radioContainer:{
        paddingTop:theme.spacing(2)
    }
}))

const FormulaContainer = props => {

    const classes = useSytles()
    const {onSubmit, catalogs, loading, warranty, product_id} = props

    const [option, setOption] = useState(1)

    let formContent = null

    if(option === 1){
        formContent = (
            <CombinedLenseForm  product_id={product_id} onSubmit={onSubmit} catalogs={catalogs} loading={loading}/>
        )
    }else{
        formContent = (
            <SeparatedLenseForm product_id={product_id} onSubmit={onSubmit} catalogs={catalogs} loading={loading}/>
        )
    }

    return(
        <div>
            <div className={classes.card}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Heading align='center' size='extraLarge' color='bluegrey_super_dark' >Ingresa tu fórmula</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.radioContainer}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Grid container wrap='nowrap' alignItems='center'>
                                                <Grid item><Radio checked={option===1} onChange={() => setOption(1)}/></Grid>
                                                <Grid item><DisplayText>Misma fórmula en ambos ojos</DisplayText></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container wrap='nowrap' alignItems='center'>
                                                <Grid item><Radio checked={option===2} onChange={() => setOption(2)}/></Grid>
                                                <Grid item><DisplayText>Diferente fórmula para cada ojo</DisplayText></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {formContent}
                        </Grid>
                        <Grid item xs={12}>
                            <Heading variant='body2' color='bluegrey_dark'>Garantía</Heading>
                            <DisplayText variant='body2' color='bluegrey'>{warranty ? warranty : 'N/A'}</DisplayText>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )
}

export default FormulaContainer