import React from 'react';
import PublicRouters from './routes/router';
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer'
import {colombiaDepartments} from './variables/departmentsCatalogs'
import { blueGrey } from '@material-ui/core/colors';

/*console.log(colombiaDepartments)

let newdata = colombiaDepartments.map(item => {
  return {
    id:item.id+1, value:item.departamento, label:item.departamento
  }
})

console.log(JSON.stringify(newdata))*/




const store = createStore(reducer);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#2A368B' }, // Purple and green play nicely together. '#38baac'
    secondary: { main: '#0291ff' }, 
    text:{
      primary:blueGrey[900],
      secondary:blueGrey[500]
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Work Sans',
    ].join(','),
  },
});

const App = (props) => {

  return(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <PublicRouters history={createBrowserHistory()} {...props}/>
        </MuiThemeProvider>  
    </Provider>  
  )
}

export default App;
