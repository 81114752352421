import React, {useState} from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import ProductMenu from './ProductMenu'
import DescriptionContainer from './DescriptionContainer'
import TechnicalDescriptionContainer from './TechnicalDescriptionContainer'
import DisplayText from '../../../components/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    container:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2),
    }
}))

const ProductDetails = props => {

    const [selected, setSelected] = useState(1)
    const classes = useStyles()
    const {description, details, specifications} = props


    let contentView = null

    switch (selected) {
        case 1: // DESCRIPTION VIEW
            contentView = (
                <div style={{minHeight:400}}>
                    <DisplayText align='justify' color='bluegrey_super_dark'>{description}</DisplayText>
                </div>
            )
            break;
        case 2: // DETAILS VIEW
            contentView = (
                <DescriptionContainer data={details}/>
            )
            break;
        case 3: // TECHNICAL VIEW
            contentView = (
                <TechnicalDescriptionContainer data={specifications}/>
            )
            break;
        default:
            break;
    }

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <ProductMenu  selected={selected} options={menuItems} onChange={(id) => setSelected(id)}/>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        {contentView}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default ProductDetails

const menuItems = [
    {id:1, label:'Descripción'},
    {id:2, label:'Detalles'},
    {id:3, label:'Ficha técnica'},
]