import { BASIC_AUTH_HEADER, mainServer } from "../../../variables/config";

export const request_product = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/product/${id}`)
    return response.data.data
}

export const request_create_order = async(data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.post(`/order`, data)  
  return response.data.data
}

export const request_add_lineitem = async(id, data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.post(`/order/${id}/lineitem`, data)  
  return response.data.data
}


export const request_remove_lineitem = async(id, lineitemid) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.delete(`/order/${id}/lineitem/${lineitemid}`)  
  return response.data.data
}

export const request_update_order = async(id, data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.patch(`/order/${id}` , data)  
  return response.data.data
}