import React, {useState} from 'react'
import DisplayText from '../../../components/Texts/DisplayText'
import logo from '../../../assets/ubelens-logo-color.png'
import { Grid, Hidden, Icon, makeStyles } from '@material-ui/core'
import CheckoutStepper from './CheckoutStepper'
import CheckoutInformation from './CheckoutInformation'
import CheckoutShipping from './CheckoutShipping'
import CheckoutPayment from './CheckoutPayment'
import Heading from '../../../components/Texts/Heading'
import { onGetFormatNumber } from '../../../shared/utility'
import CheckoutRightModalMobile from '../modals/CheckoutRightModalMobile'


const useStyles = makeStyles(theme => ({
    logo:{
        width:128
    },
    logoContainer:{
        '&:hover':{
            cursor:'pointer'
        },
        background:theme.palette.primary.main,
        padding:"4px 16px",
        borderRadius:32
    },
    seeDetails:{
        cursor:'pointer',
        color:theme.palette.primary.main,
        marginTop:4
    }
}))


const CheckoutLeft = props => {

    const classes = useStyles()

    const {step, order, seller, history, onUpdateOrder, onChangeStep, passedInRef, user, onCompletePayment} = props

    let totalPrice = 0;
    if(order){
        totalPrice = onGetFormatNumber(order.subtotal)
    }

    const [checkoutModal, setCheckoutModal] = useState(false)

    let contentView = null

    switch (step) {
        case 1: // Information View
            contentView = <CheckoutInformation user={user} order={order} seller={seller} 
            onChangeStep={onChangeStep} onUpdateOrder={onUpdateOrder}/>            
            break;
        case 2: // Shipping View
            contentView = <CheckoutShipping order={order} seller={seller}  
            onChangeStep={onChangeStep}/>            
            break;
    
        default:
            contentView = <CheckoutPayment user={user} order={order} seller={seller} passedInRef={passedInRef} history={history}
            onChangeStep={onChangeStep} onUpdateOrder={onUpdateOrder}/>            
            break;
    }

    return(
        <div>
            <CheckoutRightModalMobile open={checkoutModal} onClose={()=>setCheckoutModal(false)} step={step} order={order} onCompletePayment={onCompletePayment}/>
            <Grid container spacing={2}> 
                <Grid item >
                    <div className={classes.logoContainer} onClick={() => history.push('/')}>
                        <Grid container>
                            <Grid item><img src={logo} alt='' className={classes.logo}/></Grid>
                        </Grid>
                    </div>
                </Grid>
                <Hidden mdUp>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item ><DisplayText variant='h6'>Total</DisplayText></Grid>
                        <Grid item><Heading variant='h5'>{`$ ${totalPrice}`}</Heading></Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <div className={classes.seeDetails} onClick={()=>setCheckoutModal(true)}>
                            <Grid container >
                                <Grid item><DisplayText color='primary'  >Ver detalles</DisplayText></Grid>
                                <Grid item><Icon color='primary' >navigate_next</Icon></Grid>
                            </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <CheckoutStepper selected={step} onChange={onChangeStep}/>
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
            
        </div>
    )
}

export default CheckoutLeft