import React, {useState} from 'react'
import DisplayText from '../../../components/Texts/DisplayText'
import logo from '../../../assets/ubelens-logo-color.png'
import { Button, Grid, Hidden, Icon, makeStyles } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import StepperStatus from './StepperStatus'
import CustomerInformation from './CustomerInformation'
import PaymentStatusBar from '../../../components/DisplayData/PaymentStatusBar'
import { onGetFormatNumber } from '../../../shared/utility'
import CheckoutRightModalMobile from '../modals/CheckoutRightModalMobile'

const useStyles = makeStyles(theme => ({
    logo:{
        width:150
    },
    logoContainer:{
        '&:hover':{
            cursor:'pointer'
        },
        background:theme.palette.primary.main,
        padding:"4px 16px",
        borderRadius:32
    },
    seeDetails:{
        cursor:'pointer',
        color:theme.palette.primary.main,
        marginTop:4
    },
    total:{
        margin:'8px 0px 24px'
    }
}))


const CheckoutLeft = props => {

    const classes = useStyles()

    const {order, history, payment_status, step, onCompletePayment} = props

    const [checkoutModal, setCheckoutModal] = useState(false)

    let totalPrice = 0;

    if(order){
        totalPrice = onGetFormatNumber(order.subtotal)
    }

    return(
        <div>
            <CheckoutRightModalMobile open={checkoutModal} onClose={()=>setCheckoutModal(false)} step={step} order={order} onCompletePayment={onCompletePayment}/>
            <Grid container spacing={3}> 
                <Grid item >
                    <div className={classes.logoContainer} onClick={() => history.push('/')}>
                        <Grid container>
                            <Grid item><img src={logo} alt='' className={classes.logo}/></Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <DisplayText>{`No. de orden #${order ? order.order_id : ''}`}</DisplayText>
                    <Hidden mdUp>
                        <div className={classes.total}>
                        <Grid container spacing={1}>
                            <Grid item ><DisplayText variant='h6'>Total</DisplayText></Grid>
                            <Grid item><Heading variant='h5'>{`$ ${totalPrice}`}</Heading></Grid>
                            <Grid item xs></Grid>
                            <Grid item>
                                <div className={classes.seeDetails} onClick={()=>setCheckoutModal(true)}>
                                <Grid container >
                                    <Grid item><DisplayText color='primary'  >Ver detalles</DisplayText></Grid>
                                    <Grid item><Icon color='primary' >navigate_next</Icon></Grid>
                                </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        </div>
                    </Hidden>
                    <Heading variant='h5'>¡Gracias por tu compra!</Heading>
                    <PaymentStatusBar status={payment_status} />
                </Grid>
                <Grid item xs={12}>
                    <StepperStatus status={order ? order.status_id : null}/>
                </Grid>
                {/* <Hidden mdUp>
                    <Grid item xs={12}>
                        <CheckoutRightMobile step={step} order={order} onCompletePayment={onCompletePayment}/>
                    </Grid>
                </Hidden> */}
                <Grid item xs={12}>
                    <CustomerInformation order={order} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <Button color='primary' size='large' variant='contained'
                            onClick={() => history.push('/')}>Seguir comprando</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default CheckoutLeft