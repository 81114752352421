import { Button, Dialog, Grid, makeStyles, Typography} from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import SimpleModal from '../Structure/Modals/SimpleModal'

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        //width:'100%'
    },
    paper:{
        padding:theme.spacing(1),
        position:'absolute',
        bottom:-26,
        backgroundColor:blueGrey[900],
        //width:'100%'
    },
    container:{
        padding:theme.spacing(2),
        color:'white'
    },
    line:{
        width:1,
        height:26,
        //backgroundColor:theme.palette.primary.main
        backgroundColor:'white'
    },
    action:{
        //cursor:'pointer'
        //color:theme.palette.primary.main,
        color:blueGrey[100],
        textDecoration:'none'
    },
    acceptButton:{
        padding:'8px 16px',
        borderRadius:16,
        backgroundColor:'white',
        margin:12,
        '&:hover':{
            backgroundColor:'white'
        }
    }
  }));

const CookiesBar = (props) =>{

    const classes = useStyles();

    const {open, onAcceptedCookies}  = props


    return(
        <div>
        <Dialog 
        open={open} 
        maxWidth='xl' 
        fullWidth 
        classes={{paper:classes.paper}} 
        className={classes.root} >
            {/* <div className={classes.buttonContainer}><IconButton onClick={() => onClose('confirmlink')}><Icon >close</Icon></IconButton></div> */}
            <div className={classes.container}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{fontWeight:500}}>COOKIES Y PRIVACIDAD</Typography>
                        <Typography>
                            Esta web utiliza cookies propias o de terceros. 
                            Al continuar con la navegación usted acepta el uso que hacemos de ellas. Si lo desea 
                            puede modificar sus preferencias en su navegador
                        </Typography>
                    </Grid>
                    <Grid item >
                        <div  >
                            <a href='/legal' target='_blanck' className={classes.action}>Mostrar más información</a>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.line}/>
                    </Grid>
                    <Grid item >
                        <div  >
                            <a href='/privacy' target='_blanck' className={classes.action}>Ver política de privacidad</a>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <Button className={classes.acceptButton} onClick={onAcceptedCookies}>Acepto</Button>
                            </Grid>
                        </Grid>                      
                    </Grid>
                </Grid>  
            </div>
        </Dialog>
        </div>
    )
}

export default CookiesBar