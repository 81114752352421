import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import { request_mercadopago_payment, request_order, request_order_seller, request_profile, request_update_order, request_update_payment } from './requests'
import CheckoutLeft from './components/CheckoutLeft'
import CheckoutRight from './components/CheckoutRight'
import LoadingContainer from '../../components/Structure/LoadingContainer'
import {ORDER_STATUS_TYPES, PAYMENT_STATUSES} from '../../variables/config'
import CheckoutRightModalMobile from './modals/CheckoutRightModalMobile'

const useStyles = makeStyles(theme => ({
    home: {
        position: 'relative',
        width:'100%',
        minHeight:'100vh',
        boxSizing:'border-box',
        paddingBottom:64,
        [theme.breakpoints.down('sm')]: {
            height:'100%'
        },
    },
    root: {
        width: 1100,
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding:'0 5%',
            boxSizing:'border-box'
        },
    },
    subroot: {
        position: 'absolute',
        background: grey[100],
        width: '50%',
        height: '100%',
        borderLeft: `1px solid ${grey[300]}`,
        transform: 'translateX( calc(100% - 2px))',
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            display:'none'
        },
    },
    leftContainer: {
        boxSizing: 'border-box',
        paddingTop: 56,
        paddingRight: 66,
        [theme.breakpoints.down('sm')]: {
            paddingRight:0
        },
    },
    rightContainer: {
        boxSizing: 'border-box',
        paddingTop: 56,
        paddingLeft: 44,
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            display:'none'
        },
    }
}))

const CheckoutView = props => {

    const classes = useStyles()
    const { match, history } = props
    const [user, setUser] = useState()
    const [order, setOrder] = useState(null)
    const [seller, setSeller] = useState(null)
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const paymentViewRef = useRef()

    


    useEffect(() => {
        const initModule = async () => {try {
            setLoading(true)
            const orderID = match.params.id
            const _order = await request_order(orderID)
            const _user = await request_profile()
            const _seller = await request_order_seller(orderID)
            setOrder(_order)
            setSeller(_seller)
            setUser(_user)

            // Redirect if the order status id is not pending
            if(_order.status_id > 1) history.push(`/orders/${orderID}/status`)

            if(_order.status_id === ORDER_STATUS_TYPES.PENDING){
                if(_order.payment){
                    const {token_id, payment_url, payment_id, gateway} = _order.payment
                    if(gateway === 'mercadopago'){
                        const order_id = _order._id
                        const _payment = await request_mercadopago_payment(order_id)
                        if(_payment.results && _payment.results.length){
                            // There is a payment
                            const mp_payment = _payment.results[0]
                            if(mp_payment.status === 'approved' || mp_payment.status === 'authorized'){
                                console.log('Se puede finalizar la compra')
                                let data2send = {payment_id:mp_payment.id}
                                if(mp_payment.status === 'approved') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                if(mp_payment.status === 'authorized') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                console.log(data2send)
                                await request_update_payment(order_id, data2send)
                                await request_update_order(order_id, {status_id:ORDER_STATUS_TYPES.CONFIRMED})
                                const _updated_order = await request_order(order_id)
                                setOrder(_updated_order)
                                history.push(`/orders/${order_id}/status`)
                            }
                        }else{
                            console.log('No se ha finalizado la compra')
                        }
                    }
                }
            }
            setLoading(false)
        } catch (error) {
            console.log(error)   
            setLoading(false)
        }}
        initModule()
        //console.log(paymentViewRef)
    }, [])

    const onCompletePayment = () => {
        if(paymentViewRef){
            paymentViewRef.current.submit()
        }
    }

    const onUpdateOrder = (data) => {
        console.log(data)
        setOrder(data)
    }



    return (
        <div>
            <LoadingContainer loading={loading}>
                
                <div className={classes.home}>
                    <div className={classes.subroot} />
                    <div className={classes.root}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div className={classes.leftContainer}>
                                    <CheckoutLeft user={user} step={step} onChangeStep={(val) => setStep(val)} history={history} order={order} seller={seller} 
                                    onUpdateOrder={onUpdateOrder} passedInRef={paymentViewRef}
                                    onCompletePayment={onCompletePayment}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.rightContainer}>
                                    <CheckoutRight step={step} order={order} onCompletePayment={onCompletePayment}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </LoadingContainer>
            
        </div>
        

    )
}

const mapStateToProps = state => {
    return {
        //user: state.user,
        apolloClient: state.apolloClient,
    }
}

export default connect(mapStateToProps)(CheckoutView)