import React from 'react'
import Frame from '../../components/Structure/Frame/Frame'
import PageRouter from '../../routes/pageRouter'


const Ecommerce = props => {
    return(
        <Frame >
            <PageRouter />  
        </Frame>
    )
}

export default Ecommerce