import { Grid, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import DisplayText from '../../../components/Texts/DisplayText';
import { onGetFullname } from '../../../shared/utility';

const useStyles = makeStyles(theme => ({
    display:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'8px 16px',
        marginTop:8
    },
    form:{
        marginTop:12
    }
}))

const OpticalContianer = ({optometrist, optical, notitle}) => {

    const classes = useStyles()
    if(!optometrist && !optical) return null

    return ( 
        <Grid container>
            {!notitle ? <Grid item xs={12}>
                <DisplayText variant='h6' style={{fontWeight:500}}>Información médica</DisplayText>
            </Grid> : null}
            <Grid item xs={12}>
                <div className={classes.display}> 
                    <Grid container spacing={1}>
                        {optical ? <Grid item xs={12}>
                            <Grid container wrap='nowrap'>
                                <Grid item xs>
                                    <DisplayText variant='body1' color='bluegrey'>Óptica</DisplayText>
                                </Grid>
                                <Grid item>
                                    <DisplayText>{optical.business_name}</DisplayText>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                        {optometrist ? <Grid item xs={12}>
                            <Grid container wrap='nowrap'>
                                <Grid item xs>
                                    <DisplayText variant='body1' color='bluegrey'>Optómetra</DisplayText>
                                </Grid>
                                <Grid item>
                                    <DisplayText>{onGetFullname(optometrist)}</DisplayText>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                    </Grid>      
                    
                   
                </div>
            </Grid>
        </Grid>
        
     );
}
 
export default OpticalContianer;