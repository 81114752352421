import { CircularProgress, Dialog, Grid, Grow, makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../components/Texts/DisplayText';

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:54,
        paddingBottom:54
    }
}))

const LoadingModal = (props) => {

    const classes = useStyles()
    const {open} = props

    return(
        <Dialog open={open} maxWidth='xs' fullWidth classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition}>
            <div className={classes.root}>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                    <Grid item>
                        <CircularProgress size={72}/>
                    </Grid>
                    <Grid item>
                        <DisplayText variant='h6'>Procesando compra...</DisplayText>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default LoadingModal
