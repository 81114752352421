import gql from 'graphql-tag';

export const typeDefs = gql`
    type MailingAddressInput {
        address1: String!
        address2: String
        city: String!
        country: String!
        firstName: String!
        lastName: String!
        phone: String!
        province: String!
        zip: String!
    }
`