import { Grid } from '@material-ui/core'
import React from 'react'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'

const BillingAddressForm = props => {

    const {form, onChange} = props

    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputForm data={form.address1} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <InputForm data={form.address2} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <SelectForm data={form.province} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <SelectForm data={form.city} onChange={onChange}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectForm data={form.country} onChange={onChange}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default BillingAddressForm