import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import CardContainer from '../../../components/Structure/CardContainer/CardContainer'
import Heading from '../../../components/Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2),
    },
    header:{
        //background:blueGrey[100],
        padding:theme.spacing(1),
        borderRadius:2
    },
    link:{
        textDecoration:'none',
        color:blueGrey[600],
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(3),
        //borderBottom:`1px solid ${blueGrey[50]}`,
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
        }
        //paddingTop:theme.spacing(2),
        //paddingBottom:theme.spacing(2),
    }
}))

const MenuBar = props => {

    const {onSelectedCategory, sections} = props
    const classes = useStyles()

    return(
        <div>
            <CardContainer>
                <div className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Heading size='extraLarge' color='bluegrey'>Categorías</Heading>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid  container  spacing={3}>
                                {sections.map((item,key)=>{
                                    return(
                                        <Grid item xs={12}>
                                            <Grid container key={key.toString()}>
                                                <Grid item xs={12}>
                                                    <div className={classes.header}>
                                                    <Heading size='large'>{item.label}</Heading>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        {item.options.map((el,id)=>{
                                                            return(
                                                                <Grid item xs={12} key={id.toString()}>
                                                                    <div onClick={() => onSelectedCategory(el.url)}
                                                                    className={classes.link}>
                                                                        {el.label}
                                                                    </div>
                                                                    
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                    )
                                })}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </div>
            </CardContainer>
        </div>
    )
}

export default MenuBar

/*const sections = {
    contactlenses:[
        {
            id:1, 
            label:'Por condición visual', 
            options:[
                {id:1, label:'Miopía o Hipermetropía', url:'/contactlenses/miopia-o-hipermetropia'},
                {id:3, label:'Astigmatismo', url:'/contactlenses/astigmatismo'},
                {id:4, label:'Presbicia', url:'/contactlenses/presbicia'},
                {id:5, label:'Lentes cosméticos', url:'/contactlenses/lentes-cosmeticos'},
            ]
        },
        {
            id:2, 
            label:'Por remplazo', 
            options:[
                {id:1, label:'Diarios', url:'/contactlenses/uso-diario'},
                {id:3, label:'Quincenales', url:'/contactlenses/uso-quincenal'},
                {id:4, label:'Mensuales', url:'/contactlenses/uso-mensual'},
            ]
        },
        {
            id:3, 
            label:'Por marca', 
            multipleColumns:true,
            options:[
                {id:1, label:'Air Optix', url:'/contactlenses/air-optix'},
                {id:2, label:'Dailies AquaComfort', url:'/contactlenses/dailies-aqua-comfort-plus'},
                {id:3, label:'Freshlook', url:'/contactlenses/freshlook'},
                {id:7, label:'Soflens', url:'/contactlenses/soflens'},
                {id:8, label:'Pure Vision 2', url:'/contactlenses/pure-vision-2'},
                {id:9, label:'Ultra', url:'/contactlenses/ultra'},
                {id:12, label:'Biomedics', url:'/contactlenses/biomedics'},
                {id:13, label:'Avaira Vitality', url:'/contactlenses/avaira-vitality'},
                {id:14, label:'Biofinity', url:'/contactlenses/biofinity'},
                {id:15, label:'Clarity', url:'/contactlenses/clariti'},
                {id:16, label:'Acuvue', url:'/contactlenses/acuvue'},
                {id:17, label:'1-Day', url:'/contactlenses/1-day'},
            ]
        },
        {
            id:4, 
            label:'Por fabricante', 
            options:[
                {id:1, label:'Alcon', url:'/contactlenses/alcon'},
                {id:2, label:'Bausch & Lomb', url:'/contactlenses/bausch-lomb'},
                {id:3, label:'CooperVision', url:'/contactlenses/coopervision'},
                {id:4, label:'Johnson & Johnson', url:'/contactlenses/johnson-johnson'},
            ]
        },
    ]
}*/