import { Button, Grid, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import DisplayText from '../../../components/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    display:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'4px 16px',
        marginTop:8
    },
    container:{
        display:'flex', 
        alignItems:'center'
    }
}))

const CheckoutDisplayData = (props) => {

    const classes = useStyles()
    const {label, value, onEdit} = props 

    return(
        <div className={classes.display}>
            <DisplayText variant='body2' color='bluegrey'>{label}</DisplayText>
                <Grid container alignItems='center' wrap='nowrap' >
                    <Grid item xs>
                        <DisplayText>{value ? value : 'N/D' }</DisplayText>
                    </Grid>
                    {onEdit ? <Grid item>
                        <Button variant='text' size='small' color='secondary' onClick={onEdit} >Editar</Button>
                    </Grid> : null}
                </Grid>
        </div>
    )
}

export default CheckoutDisplayData