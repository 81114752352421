import moment from 'moment';

export const ruleValidation = (value, rules, value2) => {

  let response = false;

  if(value !== null){
    if(rules){
      switch (rules.type) {
        case 'distance':
          const min  = rules.min ? rules.min : 1 
          const max  = rules.max ? rules.max : 512
          if(value.length > min-1 && value.length < max + 1){
            response = true;
          }
          break;
        case 'numeric':
            if(value > rules.min-1 && value < rules.max + 1){
              response = true;
            }
            break;
        case 'email':
          const mailformat = /^[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,4}$/
          if (value.match(mailformat)){
            response = true;
          }
          break;
        case 'password':
          const passwordformat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
          if (value.match(passwordformat)){
            response = true;
          }
          break
        case 'expiration_card':
          const exp_pattern = /^([0-9]{2}[/]?){2}$/
          if (value.match(exp_pattern)) response = true
          break;
        case 'equals':
          response = value ===  value2;
          break;
        case 'phone_number':
          if(value.length > 9 && value.length < 13){
            response = true
          }
          break;
        case 'select':
          if(value !== '' && value !== null){
            response = true;
          }else{
            response = false;
          }
          break;
        case 'rfc':
          const rfcFormat = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
          if(value.match(rfcFormat)){
            response = true;
          };
          break;
        case 'none':
          response = true;
          break;
        case 'date':
          response = moment(value,"YYYY-MM-DD").isValid();
          break;
        case 'time':
          response = moment(value,"HH:mm").isValid();
          break;
          /**
           *   |  (?:5[1-5][0-9]{2} | (?:5[1-5][0-9]{2} | 222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12} |  3[47][0-9]{13}  | 3(?:0[0-5]|[68][0-9])[0-9]{11}  |  6(?:011|5[0-9]{2})[0-9]{12}  | (?:2131|1800|35\d{3})\d{11}
           * 
           */
        case 'creditcard':
          const creditcardtFormat = /^(?:4[0-9]{12}(?:[0-9]{3})?  |  (?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})  |  3[47][0-9]{13}  |  3(?:0[0-5]|[68][0-9])[0-9]{11}  |  6(?:011|5[0-9]{2})[0-9]{12}  | (?:2131|1800|35\d{3})\d{11}$/    
          console.log(value.split(' ').join(''))
          if(value.split(' ').join('').match(creditcardtFormat)) response = true
        default:
      }
    }else{
      response = true
    }
    
  }else{
    response = rules.type === 'none'
  }

  return response;
};

export const isFormValid = (data) => {
  let isValid = true;
  Object.keys(data).forEach((item,key) => {
    if(data[item].isRequired && !data[item].isValid){
      isValid = false;
    }
  })
  return isValid;
}