import React from 'react'
import { Grid, makeStyles, Radio } from '@material-ui/core'
import DisplayText from '../../../components/Texts/DisplayText'
import { nodata } from '../../../variables/texts'
import Heading from '../../../components/Texts/Heading'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:4,
        padding:'8px 24px',
        paddingLeft:2
    }
}))

const COShippingRates = props => {

    //console.log(props)
    const {handle, price, title} = props
    const classes = useStyles()

    let priceText = nodata
    if(price){
        if(price === "0.0"){
            priceText = 'Gratis'
        }else{
            priceText = `$${price}`
        }
    }

    return(
        <div className={classes.root}>
            <Grid container alignItems='center'>
                <Grid item>
                    <Radio checked />
                </Grid>
                <Grid item xs>
                    <DisplayText>{title ? title : nodata}</DisplayText>
                </Grid>
                <Grid item>
                    <Heading>{priceText}</Heading>
                </Grid>
            </Grid>
        </div>
    )
}

export default COShippingRates