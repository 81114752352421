import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';


export const request_create_user = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  const response = await mainServer.post(`/registration`, data)
  console.log(response.data)
}


export const MUTATION_REGISTER= gql`
mutation CustomerCreate($email:String!, $password:String!, $firstName:String!, $lastName:String!) {
    customerCreate(input: {email: $email, password: $password, firstName:$firstName, lastName:$lastName}) {
      customer {
        id
        email
        firstName
        displayName
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`  

export const MUTATION_CUSTOMER_ASSOCIATE = gql`
mutation AttachCustomer2Cart($id: ID!, $token: String!){
  checkoutCustomerAssociateV2(checkoutId: $id, customerAccessToken: $token) {
    checkout {
      id
    }
  }
}
`