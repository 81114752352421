import React, {useState} from 'react'
import CardContainer from '../../../components/Structure/CardContainer/CardContainer'
import { makeStyles, Grid, CircularProgress, Button } from '@material-ui/core'
import CartItem from './CartItemMobile'
import { blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../../components/Texts/DisplayText'
import { onGetFormatNumber } from '../../../shared/utility'
import SimpleButton from '../../../components/Actions/Buttons/SimpleButton'
import { request_update_order } from '../../Products/ProductView/requests'
import { request_update_lineitem } from '../requests'

const useStyles = makeStyles(theme => ({
    root:{padding:theme.spacing(3)},
    row:{
        borderBottom:`1px solid ${blueGrey[50]}`,
        paddingTop:theme.spacing(3),
        paddingBottom:theme.spacing(3),
    },
    header:{
        borderBottom:`1px solid ${blueGrey[50]}`,
        paddingTop:theme.spacing(0),
        paddingBottom:theme.spacing(1),
    },
    subtotal:{
        paddingTop:theme.spacing(3),
        paddingBottom:theme.spacing(3),
    }
}))

const CartList = props => {

    const classes = useStyles()
    const {products, subtotalPrice, onDeleteItem, onUpdateItem, loading, onCheckout, order} = props
    

    return(
        <CardContainer>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.subtotal}>
                            <Grid container  spacing={2}>
                                <Grid item>
                                    <DisplayText color='primary' size='large'>SUBTOTAL</DisplayText>
                                </Grid>
                                <Grid item xs={2}>
                                    <DisplayText align='right' size='large' color='bluegrey_super_dark' > {`$${onGetFormatNumber(subtotalPrice)}`} </DisplayText>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' spacing={2}>
                            {loading ? (
                                <Grid item><CircularProgress color='secondary'/></Grid>
                            ) : null}
                            <Grid item xs>
                                <SimpleButton fullWidth color='primary' onClick={onCheckout}>Finalizar compra</SimpleButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <div className={classes.header}>
                            <Grid container>
                                <Grid item xs={12} md={7}>
                                    <DisplayText color='primary'>PRODUCTO</DisplayText>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <DisplayText color='primary'>PRECIO UNITARIO</DisplayText>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <DisplayText color='primary' align='right'>CANTIDAD</DisplayText>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <DisplayText color='primary' align='right'>TOTAL</DisplayText>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid> */}
                    {products.map((item,key)=>{
                        
                        return(
                            <Grid item xs={12} key={key.toString()}>
                                <div className={classes.row}>
                                    <CartItem {...item} onDeleteItem={onDeleteItem} index={key} onUpdateQuantity={onUpdateItem}/>
                                </div>
                            </Grid>
                        )
                    })}
                    
                </Grid>
            </div>
        </CardContainer>
    )
}

export default CartList


