import gql from 'graphql-tag';

export const MUTATION_RECOVER = gql`
  mutation CustomerForgottenPassword($email:String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        message
      }
    }
  }
`  