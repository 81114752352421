import React from 'react'
import { makeStyles } from '@material-ui/core'
import DisplayText from '../../../Texts/DisplayText'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme=>({
    root:{
        padding:theme.spacing(2),
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    }
}))

const MenuItem = props => {

    const {label, url, onSelectedItem} = props
    const classes = useStyles()

    return(
        <div className={classes.root} onClick={() => onSelectedItem(url)}>
            <DisplayText>{label}</DisplayText>
        </div>
    )
}

export default MenuItem