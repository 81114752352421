import React, {useState, useEffect} from 'react'
import { Button, Checkbox, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import Page from '../../components/Structure/Page/Page'
import DisplayText from '../../components/Texts/DisplayText'
import Heading from '../../components/Texts/Heading'
import { catalogs } from '../../texts/esp/catalogs'
import profileImage from '../../assets/profile/userinputdata.png'
import { isFormValid, isTokenValid, onGetJSONData, onInitForm } from '../../shared/utility'
import InputForm from '../../components/Forms/InputForm'
import SelectForm from '../../components/Forms/SelectForm'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import { request_profile, request_update_profile } from './requests'
import ShippingAddressForm from './components/ShippingAddressForm'
import BillingAddressForm from './components/BillingAddressForm'
import UpdatePasswordModal from './modals/UpdatePasswordModal'


const breadcrumbs = [
    {id:1, label:'Mis datos', url:'/profile', selected:true}
]

const useStyles = makeStyles(theme => ({
    root:{

    },
    image:{
        width:300
    },
    form:{
        marginTop:32
    }
}))


const ProfileView = (props) => {
    
    const {history, onUpdateUser} = props
    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [billing_address_form, setBillingAddressForm] = useState(JSON.parse(JSON.stringify(address_form)))
    const [shipping_address_form, setShippingAddressForm] = useState(JSON.parse(JSON.stringify(address_form)))
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)

    //modals
    const [passwordModal, setPasswordModal] = useState(false)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const user = await request_profile()
            console.log(user)
            const _form = onInitForm(form, user)
            const _billing_form = onInitForm(billing_address_form, user.billing_address)
            const _shipping_form = onInitForm(shipping_address_form, user.shipping_address)
            setUser(user)
            setForm(_form)
            
            if(user.billing_address && user.billing_address.province){
                const provice_catalog = catalogs.cities[user.billing_address.province]
                if(provice_catalog) _billing_form.city.options = [...provice_catalog]
            }
            if(user.shipping_address && user.shipping_address.province){
                const provice_catalog = catalogs.cities[user.shipping_address.province]
                if(provice_catalog) _shipping_form.city.options = [...provice_catalog]
            }


            setShippingAddressForm(_shipping_form)
            setBillingAddressForm(_billing_form)
            onUpdateUser(user)
        } catch (error) {
            setError('Error de conexión con el servidor')
        } setLoading(false)}
        if(isTokenValid()){initModule()}else{history.push('/')}
    }, [])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        setValid(isFormValid(temp))
    }

    const onChangeBillingForm = (data) => {
        let temp = { ...billing_address_form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setBillingAddressForm(temp)
    }

    const onChangeShippingForm = (data) => {
        let temp = { ...shipping_address_form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'province'){
            //console.log('Actualizar ciudades/barrios')
            temp.city.value = null
            temp.city.options = catalogs.cities[data.value]
        }
        setShippingAddressForm(temp)
    }

    const onSubmit = async() => {
        //if(!valid){
        //    setError('Llene los campos requeridos')
        //    return
        //}
        setSending(true)
        try {
            const data2send = onGetJSONData(form)
            const updatedUser = await request_update_profile(data2send) 
            console.log(updatedUser)
            onUpdateUser(updatedUser)

        } catch (error) {
            setError(JSON.stringify(error))
            console.log(error)
        }
        setSending(false)        
    }



    return(
        <Page breadcrumbs={breadcrumbs} loading={loading} error={error} onCloseErrorModal={() => setError(null)} success={success} onCloseSuccess={() => setSuccess(null)}>
            <UpdatePasswordModal user={user} open={passwordModal} onClose={()=>setPasswordModal(false)} />
            <div className={classes.root}>
                <Heading variant='h5'>Mi información</Heading>
                <DisplayText color='bluegrey' style={{paddingTop:16}}>Por favor, asegúrese de actualizar su información personal si ha cambiado.</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputForm data={form.email} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.first_name} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.last_name} onChange={onChange} />
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <SelectForm data={form.gender_id} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={()=>setPasswordModal(true)}>Cambiar contraseña</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify='flex-end'>
                                        <Grid item>
                                            <Button variant='contained' size='large' color='primary' onClick={onSubmit}>
                                                <Grid container alignItems='center' spacing={1}>
                                                    <Grid item>Guardar</Grid>
                                                    {sending ? (
                                                        <Grid item><CircularProgress style={{color:'white', marginTop:4}} size={24}/></Grid>
                                                    ): null}
                                                </Grid>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <ShippingAddressForm user_id={user ? user._id : null} address={user ? user.shipping_address : null} form={shipping_address_form} onChange={onChangeShippingForm} onUpdateData={(data) => setUser(data)}
                                 onUpdateForm={(data) => setForm(data)} onDispatchSuccess={(message) => setSuccess(message)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <BillingAddressForm user_id={user ? user._id : null} address={user ? user.billing_address : null} form={billing_address_form} onChange={onChangeBillingForm} onUpdateData={(data) => setUser(data)}
                                 onUpdateForm={(data) => setForm(data)} onDispatchSuccess={(message) => setSuccess(message)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container justify='center'>
                                <Grid item>
                                <img src={profileImage} alt='' className={classes.image}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Checkbox checked color='primary'/>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color='textSecondary'>
                                                He leído y acepto los términos y condiciones de uso, la política de tratamiento de datos personales y autorizo el tratamiento de mis datos personales.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                
                
            </div>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })
    }
}

export default connect(null, mapDispatchToProps)(ProfileView)

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true

        },
        rules: {
            type: 'email',
        }
    },
    gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
}

const address_form = {
    address1:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'address1',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 1)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    address2:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
          id:'address2',
          type:'text',
          fullWidth: true,
          label:'Dirección (Línea 2)',
          helperText:'El numero de caracteres debe ser entre 1 y 512'
        },
        rules:{
          type:'distance',
          max:512
        }
    },
    city:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
          id:'city',
          type:'select',
          fullWidth: true,
          label:'Ciudad',
          helperText:''
        },
        rules:{
          type:'distance',
        }
    },
    province:{ // Departamento, barrio
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.departments],
        config:{
          id:'province',
          type:'select',
          fullWidth: true,
          label:'Departamento',
          helperText:'Este campo es obligatorio'
        },
        rules:{
          type:'select',
        }
    },
    country:{ // Departamento, barrio
        value: 'Colombia',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        options:[{id:1, value:'Colombia', label:'Colombia'}],
        config:{
          id:'country',
          type:'select',
          fullWidth: true,
          label:'País',
          helperText:''
        },
        rules:{
          type:'select',
        }
    },
}