import acuvue from '../../assets/supplier/acuvue.png'
import aosept from '../../assets/supplier/aosept.png'
import airoptix from '../../assets/supplier/airoptix.png'
import alcon from '../../assets/supplier/alcon.jpg'
import avaira from '../../assets/supplier/avaira.png'
import bausch from '../../assets/supplier/bausch.jpg'
import Biomedics from '../../assets/supplier/Biomedics.jpg'
import coopervision from '../../assets/supplier/coopervision.png'
import dailies from '../../assets/supplier/dailies.png'
import frequency from '../../assets/supplier/frequency.jpg'
import freshlook from '../../assets/supplier/freshlook.png'
import johnson from '../../assets/supplier/johnson&johnson.png'
import proclear from '../../assets/supplier/proclear.jpg'
import purevision from '../../assets/supplier/purevision.png'
import optifree from '../../assets/supplier/opti-free.jpg'
import systane from '../../assets/supplier/systane.png'
import biotrue from '../../assets/supplier/biotrue.png'
import bl from '../../assets/supplier/bl-logo.png'
import renu from '../../assets/supplier/renu.jpg'
import soflens from '../../assets/supplier/soflens.png'
import ultra from '../../assets/supplier/ultra.png'
import biofinity from '../../assets/supplier/biofinity.jpg'
import clariti1d from '../../assets/supplier/clariti1d.jpg'


export const content = {
    brands:[
        {id:1, image:acuvue},
        {id:2, image:airoptix},
        {id:3, image:alcon},
        {id:4, image:avaira},
        {id:5, image:bausch},
        {id:6, image:Biomedics},
        {id:7, image:coopervision},
        {id:8, image:dailies},
        {id:9, image:frequency},
        {id:10, image:freshlook},
        {id:11, image:johnson},
        {id:12, image:proclear},
        {id:13, image:purevision},
        {id:14, image: aosept},
        {id:15, image: optifree},
        {id:16, image: systane},
        {id:17, image: biotrue},
        {id:18, image: bl},
        {id:19, image: renu},
        {id:20, image: soflens},
        {id:21, image: ultra},
        {id:22, image: biofinity},
        {id:23, image: clariti1d},


    ]
}