import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import { request_products } from './requests'
import CollectionView from '../componentes/CollectionView'
import { drops_categories } from '../../../texts/esp/categories'


const DropsView = props => {

    const {id} = props.match.params
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [products, setProducts] = useState([])

     

    useEffect(()=>{
        const initModule = async() => {try {
            const category = drops_categories[id]
            setLoading(true)
            const _products = await request_products(category.tags)
            setProducts(_products)
            setTitle(category.title)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }}
        window.scrollTo(0,0)
        initModule()
    },[props.match])

    const onSelectedProduct = (handle) => {
        console.log(handle)
        window.scrollTo(0,0)
        props.history.push(`/drop-product/${handle}`)
    } 

    const onSelectedCategory = (url) => {
        window.scrollTo(0,0)
        props.history.push(url)
    }


    let breadcrumbs = [
        {id:1, label:'Gotas', url:'/drops'},
        {id:2, label:'Marca', url:`/drops/${id}`, selected:true},
    ]

    return(
        <Page breadcrumbs={breadcrumbs}>
            <CollectionView 
                title={title} loading={loading} products={products} sections={sections}
                onSelectedProduct={onSelectedProduct}
                onSelectedCategory={onSelectedCategory}
            />
        </Page>
    )
}

export default DropsView

const sections =[
    {
        id:1, 
        label:'Por marca', 
        options:[
            {id:1, label:'Opti Free', url:'/drops/opti-free'},
            {id:2, label:'Renu', url:'/drops/renu'},
            {id:3, label:'Systane', url:'/drops/systane'},
            {id:6, label:'Ver todas', url:'/drops/todas'},
        ]
    },
]