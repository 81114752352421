import React from 'react'
import { Grid, Hidden } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import ProductCard from '../../../components/Structure/ProductCard/ProductCard'
import MenuBar from './MenuBar'
import LoadingContainer from '../../../components/Structure/LoadingContainer'
import MenuBarMobile from './MenuBarMobile'

const CollectionView = props => {

    const {title, products, loading, onSelectedCategory, onSelectedProduct, sections} = props
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container  spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Heading size='extraLarge'>{title}</Heading>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            {/* Vendor's banner goes here */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container  spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Hidden smDown>
                                <MenuBar sections={sections} onSelectedCategory={onSelectedCategory}/>
                            </Hidden>
                            <Hidden mdUp>
                                <MenuBarMobile content={sections} onSelectedCategory={onSelectedCategory} />
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <LoadingContainer loading={loading}>
                                <Grid container spacing={3}>
                                    {products.map(item=>{
                                        return(
                                            <Grid item xs={12} md={4} key={item._id}>
                                                <ProductCard {...item} onClick={onSelectedProduct}/>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </LoadingContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{height:200}}></div>
        </div>
    )
}
export default CollectionView