import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { palette } from '../../variables/config'
import { blueGrey } from '@material-ui/core/colors'

const variant = {
    extraSmall:'caption',
    small:'body2',
    medium:'body1',
    large:'h6',
    extraLarge:'h5',
    superLarge:'h4'
}

const useStyles = makeStyles(theme => ({
    root:{
        fontWeight:400,
        color:blueGrey[900]
    }
}))
 

const DisplayText = props => {

    const {size, color} = props
    const classes = useStyles()


    return(
        <Typography className={classes.root} variant={size ? variant[size] : 'body1'} style={{color: color ? palette.texts[color] : undefined }} {...props}>
            {props.children}
        </Typography>
    )
}

export default DisplayText