import { Button, Grid, makeStyles, Snackbar, SnackbarContent } from '@material-ui/core'
import React, {useState} from 'react'
import DisplayText from '../Texts/DisplayText';

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 1000,
      width:1000,
      //width:'80%'
      borderRadius:16
    },
    snackbar: {
      margin: theme.spacing(2),
      borderRadius:16
    },
    action:{
        cursor:'pointer'
    },
    line:{
        width:1,
        height:20,
        backgroundColor:'white'
    },
    message:{
        //fontWeight:500,
        fontSize:20
    },
    acceptButton:{
        padding:'8px 16px',
        borderRadius:16,
        backgroundColor:'white',
        margin:16
    }
  }));

const Cookies = (props) => {

    const classes = useStyles();

    const {onShowMoreInfo} = props

    const [open, setOpen] = useState(true)

    const action = ( 
        <div>
            {/* <div className={classes.action} onClick={() => onShowMoreInfo('/legal')}>Mostrar más informacion</div>
            <div className={classes.action} onClick={onAcceptedCookies}>Acepto</div> */}
            <Button className={classes.acceptButton} onClick={onAcceptedCookies}>Acepto</Button>
        </div>
        
    )

    const message = (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className={classes.message}>
                <div>COOKIES Y PRIVACIDAD</div>
                Esta web utiliza cookies propias o de terceros. 
                Al continuar con la navegación usted acepta el uso que hacemos de ellas. Si lo desea 
                puede modificar sus preferencias en su navegador
                </div>
            </Grid>
            <Grid item >
                <div className={classes.action} onClick={() => onShowMoreInfo('/legal')}>Mostrar más información</div>
            </Grid>
            <Grid item>
                <div className={classes.line}/>
            </Grid>
            <Grid item >
                <div className={classes.action} onClick={() => onShowMoreInfo('/privacy')}>Ver política de privacidad</div>
            </Grid>
        </Grid>
    )

    function onAcceptedCookies() {
        console.log('Acepto')
        localStorage.setItem("cookieFlag", 1);
        setOpen(false);  
    }

    return(
        <div >
            <Snackbar
                //className={classes.root}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={open}
                //onClose={onAcceptedCookies}
            >  
            <SnackbarContent 
            className={classes.snackbar} 
            //message="Nuestro sitio web utiliza cookies para ser más cool" 
            message={message}
            action={action} 
            //onClose={onAcceptedCookies} 
            />
            </Snackbar>
        </div>
    )

}

export default Cookies