import React from 'react'
import { Grid, makeStyles, Icon } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import DisplayText from '../../../components/Texts/DisplayText'
import SimpleButton from '../../../components/Actions/Buttons/SimpleButton'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    icon:{
        color:green[600],
        fontSize:100,
        border:`3px solid ${green[600]}`,
        borderRadius:'50%'
    }
}))

const SuccessView = props => {

    const { onReturn} = props
    const classes = useStyles()

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Heading align='center' color='primary' size='superLarge'>¡Correo enviado!</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText align='center' color='bluegrey' size='normal'>Por favor revisa tu correo electrónico para continuar con el proceso de recuperación</DisplayText>
                    </Grid>
                    <Grid  item xs={12}>
                        <Grid container justify='center'>
                            <Grid item>
                                <Icon className={classes.icon}>done</Icon>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4}>
                        
                        <Grid item xs={12}>
                            <SimpleButton fullWidth color='primary' onClick={onReturn}>Regresar</SimpleButton>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{minHeight:44}}/>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            
        </Grid>
    )
}

export default SuccessView