import React from 'react'
import { Popper, makeStyles, ClickAwayListener } from '@material-ui/core'
import CardWrapper from '../Structure/CardContainer/CardWrapper'

const useStyles = makeStyles(theme => ({
    popper:{zIndex:2}
}))

const PopperContainer = props => {

    const {anchorEl, topOffset, rounded, onClose} = props
    const classes = useStyles()

    //const onClickAway = () => {
    //    console.log('Di click afuera')
    //}

    return(
        <Popper placement='bottom' open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.popper} style={{marginTop: topOffset ? topOffset : undefined}}>
            
                <CardWrapper>
                    <ClickAwayListener onClickAway={onClose}>
                        {props.children}
                    </ClickAwayListener>
                </CardWrapper>
            
        </Popper>
    )
}

export default PopperContainer