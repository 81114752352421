import React, {useState} from 'react'
import Page from '../../components/Structure/Page/Page'
import { content } from './content'
import CardContainer from '../../components/Structure/CardContainer/CardContainer'
import { makeStyles} from '@material-ui/core'
import { isFormValid } from '../../shared/utility'
import { useMutation } from 'react-apollo'
import { MUTATION_RECOVER, request_reset_password } from './requests'
import { blueGrey } from '@material-ui/core/colors'
import { ERROR_CODES } from '../../variables/errorCodes'
import FormView from './components/FormView'
import SuccessView from './components/SuccessView'
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    root:{
        width:600,
        margin:'auto',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
    card:{
        padding:theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding:theme.spacing(3),
        },
    },
    link:{
        color:blueGrey[500],
        fontWeight:500,
        fontFamily:'Roboto',
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.main,}
    }
}))


const ResetPasswordView = props => {

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isPasswordRecover, setIsPasswordRecover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isValid, setIsValid] = useState(false)


    ///////////////////////////////  INTERNAL FUNCITONS  //////////////////////////////////////

    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        if(id === 'password'){
            temp.passwordConfirmation.value2 = temp[id].value
        }
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async() => {

        const {token} = queryString.parse(props.history.location.search)

        let data2send = {
            password_change_token:token,
            password: form.password.value
        }
        console.log(data2send)

        setLoading(true)
        try {
            await request_reset_password(data2send)
            setIsPasswordRecover(true)
        } catch (error) {

            setError('Hubo un error')
        }
        setLoading(false)
        
    }

    let contentView = (
        <FormView form={form} onChange={onChange} isValid={isValid} loading={loading} error={error}
        onSubmit={onSubmit}/>
    )

    if(isPasswordRecover){
        contentView = <SuccessView onReturn={()=>props.history.push('/')}/>
    }

    return(
        <Page breadcrumbs={content.breadcrumbs}>
            <div className={classes.root}>
                <CardContainer>
                    <div className={classes.card}>
                        {contentView}
                    </div>
                </CardContainer>
            </div>
        </Page>
    )
}

export default ResetPasswordView


const formData = {
    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula'
        },
        rules:{
          type:'password',
        }
    },
    passwordConfirmation:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'passwordConfirmation',
          type:'password',
          fullWidth: true,
          label:'Confirmar contraseña',
          helperText:'La contraseña no coincide'
        },
        rules:{
          type:'equals',
        }
    },
}