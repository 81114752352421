import React, {useState} from 'react'
import Page from '../../components/Structure/Page/Page'
import { content } from './content'
import CardContainer from '../../components/Structure/CardContainer/CardContainer'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import Heading from '../../components/Texts/Heading'
import InputForm from '../../components/Forms/InputForm'
import SimpleButton from '../../components/Actions/Buttons/SimpleButton'
import { Link } from 'react-router-dom'
import { isFormValid } from '../../shared/utility'
import { useMutation } from 'react-apollo'
import { MUTATION_RECOVER } from './requests'
import DisplayText from '../../components/Texts/DisplayText'
import { blueGrey } from '@material-ui/core/colors'
import { ERROR_CODES } from '../../variables/errorCodes'
import FormView from './components/FormView'
import SuccessView from './components/SuccessView'

const useStyles = makeStyles(theme => ({
    root:{
        width:600,
        margin:'auto',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
    card:{
        padding:theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            padding:theme.spacing(3),
        },
    },
    link:{
        color:blueGrey[500],
        fontWeight:500,
        fontFamily:'Roboto',
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.main,}
    }
}))


const ForgottePasswordView = props => {

    const classes = useStyles()
    const {history} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isEmailSent, setIsEmailSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [onRecoverPassword] = useMutation(MUTATION_RECOVER)


    ///////////////////////////////  INTERNAL FUNCITONS  //////////////////////////////////////


    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async() => {
        let data2send = {}
        Object.keys(form).forEach(item => {
            data2send = {...data2send, [item]:form[item].value}
        })
        console.log(data2send)
        setLoading(true)
        try {
            const response = await onRecoverPassword({ variables: data2send })
            console.log(response.data.customerRecover)
            const {customerUserErrors} = response.data.customerRecover
            
            if(customerUserErrors && customerUserErrors.length){
                console.log('Hubo un error')
                console.log(customerUserErrors)
                if(customerUserErrors){
                    const errorMesage = ERROR_CODES[customerUserErrors[0].code]
                    console.log(errorMesage)
                    setError('Correo electrónico no identificado')
                }
            }else{
                console.log('Se puede acceder')
                setIsEmailSent(true)
                
            }
        } catch (error) {
            console.log(error)
            console.log(error.response)
            setError('Hubo un error')
        }
        setLoading(false)
        
    }

    let contentView = (
        <FormView form={form} onChange={onChange} isValid={isValid} loading={loading} error={error}
        onSubmit={onSubmit}/>
    )

    if(isEmailSent){
        contentView = <SuccessView onReturn={()=>props.history.push('/')}/>
    }

    return(
        <Page breadcrumbs={content.breadcrumbs}>
            <div className={classes.root}>
                <CardContainer>
                    <div className={classes.card}>
                        {contentView}
                    </div>
                </CardContainer>
            </div>
        </Page>
    )
}

export default ForgottePasswordView


const formData = {
    email:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'email',
          type:'email',
          fullWidth: true,
          label:'Dirección de correo',
          helperText:'Dirección de correo no válido'
        },
        rules:{
          type:'email',
        }
    },
}