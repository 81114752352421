import { Grid, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading'

const useStyles = makeStyles(theme => ({
    root:{
        marginTop:16,
        marginBorrom:16,
    },
    summary:{
        marginTop:16,
        border:`1px solid ${blueGrey[100]}`,
        padding:16,
        borderRadius:12
    }
}))

const CustomerInformation = props => {

    const classes = useStyles()
    const {order} = props

    let paymentMethod = ''
    if(order){
        switch (order.payment_type_id) {
            case 1:
                paymentMethod = 'Tarjeta de crédito/debito'
                break;
            case 2:
                paymentMethod = 'Efectivo'
                break;
            case 3:
                paymentMethod = 'QR'
                break;
            default:
                break;
        }
    }

    return(
        <div className={classes.root}>
            <div className={classes.summary}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Heading variant='h6'>Información del cliente</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SimpleDataWrapper title='Información de contacto' description={order ? order.email : ''}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AddressWrapper title='Dirección de envío' data={order ? order.shipping_address : null}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AddressWrapper title='Dirección de facturación' data={order ? order.billing_address : null}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SimpleDataWrapper title='Método de envío' description="Estándar"/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SimpleDataWrapper title='Método de pago' description={paymentMethod}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default CustomerInformation


const SimpleDataWrapper = (props) => {

    const {title, description} = props

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Heading>{title}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <DisplayText>{description}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}

const AddressWrapper = (props) => {

    const {title, data} = props

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <Heading>{title}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <DisplayText>{data ? data.address1 : ''}</DisplayText>
                    <DisplayText>{data ? data.address2 : ''}</DisplayText>
                    <DisplayText>{data ? data.province : ''}</DisplayText>
                    <DisplayText>{data ? data.city : ''}</DisplayText>
                    <DisplayText>{data ? data.country : ''}</DisplayText>
                    <DisplayText>{data ? data.zip : ''}</DisplayText>
                </Grid>
            </Grid>
        </div>
    )
}