import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import Heading from '../../../components/Texts/Heading'
import DisplayText from '../../../components/Texts/DisplayText'
import InputForm from '../../../components/Forms/InputForm'
import SimpleButton from '../../../components/Actions/Buttons/SimpleButton'

const FormView = props => {

    const {form, onChange, isValid, loading, error, onSubmit} = props

    return(
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Heading color='primary' size='superLarge'>Recupera tu contraseña!</Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayText color='bluegrey' size='normal'>Por favor ingresa la dirección de correo con la que te registaste. Nosotros te enviaremos tu nueva contraseña</DisplayText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <SimpleButton fullWidth color='primary' onClick={onSubmit}
                                disabled={!isValid}>Restablecer contraseña</SimpleButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12}>
                                    <div style={{minHeight:44}}>
                                        <Grid container justify='center'>
                                            {loading ? (
                                                <Grid item> <CircularProgress color='secondary'/> </Grid>
                                            ) : null}
                                            {error ? (
                                                <Grid item>
                                                    <DisplayText color='red'>{error}</DisplayText>
                                                </Grid>
                                            ):null}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            
        </Grid>
    )
}

export default FormView