import { makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import React from 'react';

const PaymentStatusBar = ({status}) => {

    const classes = useStyles()

    if(status !== 1) return null

    return ( 
        <div className={classes.root}>
            El pago se encuentra pendiente de aprobación
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:amber[200],
        border:`1px solid ${amber[700]}`,
        padding:16,
        borderRadius:12,
        fontFamily:theme.typography.fontFamily,
        marginTop:8
    }
}))


export default PaymentStatusBar;