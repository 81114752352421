import React, {useEffect} from 'react'
import Page from '../../../components/Structure/Page/Page'
import { useState } from 'react'  
import CollectionView from '../componentes/CollectionView'
import { request_products } from './requests'
import { solutions_categories } from '../../../texts/esp/categories'


const SolutionsView = props => {

    const {id} = props.match.params
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [products, setProducts] = useState([])

    useEffect(()=>{
        const initModule = async() => {try {
            const category = solutions_categories[id]
            setLoading(true)
            const _products = await request_products(category.tags)
            setProducts(_products)
            setTitle(category.title)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }}
        window.scrollTo(0,0)
        initModule()
    },[props.match])

    const onSelectedProduct = (handle) => {
        console.log(handle)
        window.scrollTo(0,0)
        props.history.push(`/solution-product/${handle}`)
    } 

    const onSelectedCategory = (url) => {
        window.scrollTo(0,0)
        props.history.push(url)
    }

    let breadcrumbs = [
        {id:1, label:'Soluciones', url:'/solutions'},
        {id:2, label:'Marca', url:`/solutions/${id}`, selected:true},
    ]

    return(
        <Page breadcrumbs={breadcrumbs}>
            <CollectionView
                title={title} loading={loading} products={products} sections={sections}
                onSelectedProduct={onSelectedProduct}
                onSelectedCategory={onSelectedCategory}/>
        </Page>
    )
}

export default SolutionsView

const sections = [
    {
        id:1, 
        label:'Por marca', 
        options:[
            {id:1, label:'Opti Free', url:'/solutions/opti-free'},
            {id:2, label:'Ao Sept', url:'/solutions/ao-sept'},
            {id:3, label:'Renu', url:'/solutions/renu'},
            {id:4, label:'Bio True', url:'/solutions/bio-true'},
            //ck
            {id:5, label:'Avizor', url:'/solutions/avizor'},
            {id:6, label:'Ver todas', url:'/solutions/todas'},
        ]
    },
]