import React from 'react'
import { makeStyles, Button, fade } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    button:{
        padding:14,
        paddingRight:34,
        paddingLeft:34,
        border:`2px solid ${theme.palette.secondary.main}`,
        color:blueGrey[900],
        '&:hover':{
            border:`2px solid ${theme.palette.secondary.main}`,
            background:theme.palette.secondary.main,
            color:'white'
        }
    }
}))

const SimpleButton = props => {

    const classes = useStyles()



    return(
        <Button variant='outlined' className={classes.button} color='primary' {...props} >
            {props.children}
        </Button>
    )
}

export default SimpleButton