import React, {useState} from 'react'
import { Grid, makeStyles, Icon, fade, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
//import { content } from '../content'
import clsx from 'clsx';
import { blueGrey } from '@material-ui/core/colors'
import PopperContainer from '../../../components/Overlays/PopperContainer';
import DisplayText from '../../../components/Texts/DisplayText';



const useStyles = makeStyles(theme => ({
    popper:{
        maxWidth:300,
        //width:'80%',
        padding:theme.spacing(1),
    },
    divider:{
        width:2,
        height:200,
        background:blueGrey[50]
    },
    acordion:{
        border: 'none',
        boxShadow: 'none',
    },
    acordionSummary:{
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
        borderBottom: 0,
        },
        '&:before': {
        display: 'none',
        },
        '&$expanded': {
        margin: 'auto',
        },
    },
}))

const MenuBarMobile = props => {

    const {onSelectedItem , content, onSelectedCategory} = props

    const classes = useStyles()

    const [selected, setSelected] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSubmenuActive, setIsSubmenuActive] = useState(false)
    const [submenu, setSubmenu] = useState([])

    let timer = null

    const onSelectedMenu = (e) => {
        setAnchorEl(e.currentTarget)
        //setSelected(id)
        //const findElement = content.find(el => el.id === id)
        //setSubmenu(findElement.sections)

    }

    const onInnerSelectedItem = (url) => {
        setSelected(null)
        setAnchorEl(null)
        setSubmenu([])
        onSelectedItem(url)
    }

    const onClose = (url) => {
        onSelectedCategory(url)
        setAnchorEl(null)
        //setSelected(null)
        //setSubmenu([])
    }

    let submenuContent = null

  
        submenuContent = (
            <PopperContainer anchorEl={anchorEl} topOffset={4} onClose={onClose}>
                <div className={classes.popper}>
                    <Grid container spacing={1}>
                        {content.map((item,key)=>{
                            return(
                                <Grid item xs={12} key={key.toString()}>
                                    {/* <DisplayText>{item.label}</DisplayText> */}
                                    <Accordion 
                                        classes={{root: classes.acordion}}
                                    >
                                        <AccordionSummary
                                        expandIcon={<Icon>expand_more</Icon>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        classes={{
                                            root: classes.acordionSummary, 
                                            expanded : {}                
                                          }}
                                        >
                                        <DisplayText className={classes.heading}>{item.label}</DisplayText>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                {item.options.map((item, index)=>{
                                                    return(
                                                        <Grid item xs={12} key={index}>
                                                            <Button onClick={() => onClose(item.url)}>{item.label}</Button>
                                                        </Grid>          
                                                    )
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </PopperContainer>
        )
    

    return(
        <div>
            {submenuContent}
            <MenuButton label='Categorías' onSelectedMenu={onSelectedMenu}/>
            {/* <Grid container spacing={4}>
                {content.map((item,key)=>{
                    return(
                        <Grid item key={key.toString()}>
                            <MenuButton {...item} selected={selected === item.id} anchorEl={anchorEl}
                            onSelectedMenu={onSelectedMenu}  onSelectedItem={onInnerSelectedItem}
                            />
                        </Grid>
                    )
                })}
            </Grid> */}
        </div>
    )

}

export default MenuBarMobile

const useButtonStyles = makeStyles(theme => ({
    root:{
        borderTopLeftRadius:12,
        borderTopRightRadius:12,
        '&:hover':{
            cursor:'pointer',
        },

        
    },
    activeRoot:{
        //borderBottom:`2px solid #FFF`,
        //background: fade('#FFF',0.25)
    },
    icon:{
        //color:'white'
    }
}))

const MenuButton = props => {

    const {id, label, url, selected, onSelectedMenu} = props
    const classes = useButtonStyles()
    return(
        <div className={clsx({
            [classes.root]:true,
            [classes.activeRoot]:selected
        })} onClick={(e) => onSelectedMenu(e)}>
            <Grid container alignItems='center'>
                <Grid item>
                    <DisplayText size='large' >{label}</DisplayText>
                </Grid>
                <Grid item>
                    <Icon className={classes.icon}>expand_more</Icon>
                </Grid>
            </Grid>
            
        </div>
    )
}