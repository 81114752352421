import React from 'react'
import DisplayText from '../../../Texts/DisplayText'
import { Grid, makeStyles, Icon } from '@material-ui/core'
import Heading from '../../../Texts/Heading'

const useStyles = makeStyles(theme => ({
    root:{
        //borderLeft:'1px solid #FFF',
        //paddingLeft:theme.spacing(2)
    },
    items:{
        '&:hover':{cursor:'pointer'}
    },
    icon:{
        color:theme.palette.primary.main
    }
}))

const ContactInfo = props => {

    const classes = useStyles()
    const {title, contacts} = props

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Heading size='large' >{title}</Heading>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {contacts.map((item,key)=>{
                            return(
                                <Grid item xs={12} key={key.toString()}>
                                    <Grid container wrap='nowrap' spacing={1}>
                                        <Grid item><Icon className={classes.icon}>{item.icon}</Icon></Grid>
                                        <Grid item><DisplayText >{item.text}</DisplayText></Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ContactInfo