import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_reset_password = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  await mainServer.post(`/login/resetpassword`, data)
}


export const MUTATION_RECOVER = gql`
  mutation CustomerResetPassword($id:ID!, $resetToken:String!, $password:String!) {
    customerReset(id: $id, input: {resetToken: $resetToken, password: $password}) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        message
      }
      userErrors {
        field
        message
      }
    }
  }
`  