import { mainServer, BASIC_AUTH_HEADER } from "../../../variables/config"

export const request_profile = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/user/me`)
    return response.data.data
}

export const request_order = async(id) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  const response = await mainServer.get(`/order/${id}`)
  return response.data.data
}

export const request_reauth = async(id) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.get(`/reauthenticate`)
  return response.data.data
}

export const request_mercadopago_payment_link = async(id,data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.get(`/order/${id}/payment/mercadopago/link`)
  return response.data.data.url
}

export const request_mercadopago_payment = async(id) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.get(`/order/payment/mercadopago/${id}`)
  return response.data.data
}

export const request_update_order = async(id, data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.patch(`/order/${id}`, data)
  return response.data.data
}

export const request_update_payment = async(id, data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  await mainServer.patch(`/order/${id}/admin/payment`, data)
}
  