import React, {useState, useEffect} from 'react'
import { makeStyles, Grid, IconButton, Icon, AppBar, Avatar, Hidden } from '@material-ui/core'
import logo from '../../../assets/ubelens-logo-color.png'
import logoWhite from '../../../assets/ubelens-logo-white.png'
import isotype from '../../../assets/logo.png'
import MenuTopBar from './components/MenuTopbar'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import UserButton from './components/UserButton/UserButton'
//asdfasfdgit


const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        background:theme.palette.primary.main,
        transition: theme.transitions.create(['background'],{
            easing: theme.transitions.easing.easeIn, 
            duration: theme.transitions.duration.short,
        })
    },
    root_transparent:{
        background:'rgba(255,255,255,0.01)',
        transition: theme.transitions.create(['background'],{
            easing: theme.transitions.easing.easeIn, 
            duration: theme.transitions.duration.complex,
        })
    },
    appbar:{
        boxShadow:'none',
        background:'transparent'
    },
    subroot:{
        margin:'auto',
        paddingLeft:40,
            paddingRight:40,
        marginLeft:72,
        marginRight:72,
        boxSizing:'border-box',
        [theme.breakpoints.down('lg')]: {
            width:'100%',
            paddingLeft:40,
            paddingRight:40,
            margin:0,
        },
      
    },
    menu:{
        background:theme.palette.secondary.main
    },
    submenu:{
        margin:'auto',
        //padding:theme.spacing(2),
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]: {
            width:'100%',
        },
    },
    imageContainer:{
        padding:theme.spacing(1),
        margin:'auto',
        '&:hover':{cursor:'pointer'},
        
    },
    logoBrand:{
        fontSize:36,
        letterSpacing:1,
        fontWeight:400,
        color:'white',
        //color:grey[700],
        marginTop:2
    },
    ube:{
        color:theme.palette.secondary.main
    },
    leftActionContainer:{
        position:'absolute',
        top:20,
        right:theme.spacing(4),
    },
    rightActionContainer:{
        position:'absolute',
        top:0,
        left:theme.spacing(4),
    },
    cartContainer:{
        position:'relative'
    },
    cartNumber:{
        position:'absolute',
        top:0,
        right:0,
        width:20,
        height:20,
        fontSize:14,
        background:theme.palette.secondary.main,
        zIndex:1
    }
}))

const Topbar = props => {

    const {onSelectedLink, history, onModalSelected, isAuth, onLogOut, cartItems, onToggleDrawer} = props
    const classes = useStyles()

    const [transparent, setTransparent] = useState(true)

    useEffect(() => {
        window.addEventListener('scroll', (e) => onHandleScroll(e,transparent))
        const width = window.innerWidth
        const scrollY = window.scrollY
        const bannerW = width*0.2-100
        if(scrollY > bannerW) setTransparent(false)
        return function cleanup(){
            window.removeEventListener('scroll', onHandleScroll)
        }
        
    }, [])

    const onHandleScroll = (event) => {  
        let scrollTop = event.srcElement.scrollingElement.scrollTop
        let scrollWidth = event.srcElement.scrollingElement.scrollWidth
        let bannerHeight = scrollWidth*0.2 - 100;
        if(scrollTop < bannerHeight){
            setTransparent(true)
        }else{
            setTransparent(false)
        }
    }
  
    const onSelectedItem = (url) =>{
        console.log(url)
        window.scrollTo(0,0)
        props.history.push(url)
    }

    return(
        <AppBar className={classes.appbar}>
             <div className={clsx({
                    [classes.root]:true,
                    [classes.root_transparent]:transparent && history.location.pathname === '/'
                })}>
                <div className={classes.rightActionContainer}>
                    <Hidden mdUp>
                        <IconButton style={{marginTop:20}} onClick={onToggleDrawer}>
                            <Icon style={{color:'white'}}>menu</Icon>
                        </IconButton>
                    </Hidden>
                </div>
                <div className={classes.leftActionContainer}>
                    <Grid container>
                        <Grid item>
                            <UserButton history={history} onItemSelected={onModalSelected} isAuth={isAuth} onLogOut={onLogOut}/>
                        </Grid>
                        <Grid item>
                            <div className={classes.cartContainer}>
                                {cartItems ? <Avatar className={classes.cartNumber}>{cartItems}</Avatar> : null}
                                <IconButton onClick={() => onSelectedLink('/cart')}><Icon style={{color:'white'}}>storefront</Icon></IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.subroot}>
                    <Grid container alignItems='center' justify='center'>
                        <Grid item xs={12}>
                            <Grid container justify='center'>
                                <Grid item>
                                    <div onClick={() => onSelectedLink('/')} className={classes.imageContainer}>
                                        <Grid container>
                                            <Grid item>
                                                <Hidden smDown>
                                                    <img src={transparent && history.location.pathname === '/' ? logoWhite : logo} alt='' width={150} style={{marginTop:4}}/>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <img src={isotype} alt='' width={70} style={{marginTop:4}}/>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden smDown>
                        <Grid item>
                            <MenuTopBar onSelectedItem={onSelectedItem}/>
                        </Grid>
                        </Hidden>
                    </Grid>
                </div>
                
            </div>
        </AppBar>
       
    )
}

export default withRouter(Topbar)


const buttonStyles = makeStyles(theme => ({
    root:{
        '&:hover':{cursor:'pointer'}
    }
}))