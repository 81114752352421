import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

const LoadingContainer = ({loading, children}) => {
    return(
        <div>
            {loading ? (
                <div style={{padding:32}}>
                    <Grid container justify='center'><Grid item><CircularProgress /></Grid></Grid>
                </div>
            ) : children}
        </div>
    )
}

export default LoadingContainer