import React from 'react'
import moment from 'moment'
import { Grid, makeStyles } from '@material-ui/core'
import cx from 'classnames'
import { blueGrey } from '@material-ui/core/colors'


const useStyles = makeStyles(theme => ({
    root:{margin:'16px 0px'},
    pill:{
        background:blueGrey[50],
        fontFamily:theme.typography.fontFamily,
        fontSize:16,
        color:blueGrey[700],
        padding:'12px 24px',
        borderRadius:32,
        transition:theme.transitions.create(['color','background'], {
            duration:theme.transitions.duration.complex,
            easing:theme.transitions.easing.easeOut,
        }),
        '&:hover':{
            background:theme.palette.secondary.main,
            color:'white',
            cursor:'pointer',
            transition:theme.transitions.create(['color','background'], {
                duration:theme.transitions.duration.enteringScreen,
                easing:theme.transitions.easing.easeIn,
            })
        }
    },
    selectedPill:{
        background:theme.palette.secondary.main,
        color:'white',
        cursor:'pointer',
        transition:theme.transitions.create(['color','background'], {
            duration:theme.transitions.duration.enteringScreen,
            easing:theme.transitions.easing.easeIn,
        })
    }
}))

const DateFilter = (props) => {

    const classes = useStyles()
    const {selected, onChange, options} = props

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                {options.map(item => {
                    return(
                        <Grid item>
                            <div className={cx({
                                [classes.pill]:true,
                                [classes.selectedPill]:selected===item.id
                            })} onClick={() => onChange(item.id)}>{item.label}</div>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default DateFilter
