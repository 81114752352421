import React, {useState, useEffect} from 'react'
import CardContainer from '../CardContainer/CardContainer'
import { CardContent, Grid, makeStyles, fade, Button, IconButton, Icon, Fade, Slide, Grow } from '@material-ui/core'
import { onGetFormatNumber } from '../../../assets/shared/utility'
import Heading from '../../Texts/Heading'
import { useRef } from 'react'
import block from  '../../../assets/block.png'
import ProductImage from '../Images/ProductImage'
import { grey, blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../Texts/DisplayText'
import SimpleButton from '../../Actions/Buttons/SimpleButton'
import clsx from 'clsx'
import { config_data } from '../../../variables/config'
import noproduct from '../../../assets/noproduct.png'

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        background:'white',
        marginRight:32,
        borderRadius:8,
        boxSizing:'border-box',
        '&:hover':{
            cursor:'pointer',
        },

    },    
    container:{
        position:'relative'
    },
    btnContainer:{
        position:'absolute',
        width:'100%',
        bottom:0,
    },
    image:{
        top:0,
        position:'absolute',
        padding:'5%',
        boxSizing:'border-box',
        width:'100%',
        height:'100%',
        objectFit:'contain',
        opacity:1,
        transition: theme.transitions.create(['opacity'],{
            easing: theme.transitions.easing.easeIn, 
            duration: theme.transitions.duration.complex,
        })
    },
    imageHover:{
        opacity:0.5,
        transition: theme.transitions.create(['opacity'],{
            easing: theme.transitions.easing.easeIn, 
            duration: theme.transitions.duration.complex,
        })
    },
    imageContainer:{
        width:'100%',
        paddingTop:'100%',
        position:'relative',
        boxSizing:'border-box',
    },
    imageContainerHover:{
    },
    priceContainer:{
        position:'relative',
        //background:grey[100],
        height:50
    },
    shopButton:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        textAlign:'center',
        fontFamily:'Work Sans',
        background:'white',
        textDecoration:'underline',
        fontWeight:600,
        color:blueGrey[900],
        paddingTop:8,
        paddingBottom:8
    },
    shopIconButton:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    iconButtonContainer:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:5,
    },
    caption:{
        fontFamily:'Work Sans',
        fontSize:14,
        textAlign:'center',
        color:blueGrey[500]
    }
}))

const ProductCard  = props => {

    const classes = useStyles()
    const {_id, image, title, price, onClick, manufacturer} = props
    const [hover, setHover] = useState(false)
    const [error, setError] = useState(true)

    const onImageLoad = (e) => {
        setError(false)
    }

    const onError = (e) => {
        setError(true)
    }

    let _price = ''
    if(price){
        _price = `$ ${onGetFormatNumber(price)}`
    }  
    

    let selectedImage = image ? `${config_data.S3_SERVER_URL}${image}` : null




    return(
        <div className={classes.root}  onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        onClick={() => onClick(_id)}>
            <div className={classes.subroot}>
                <Grid container spacing={1}>
                    
                    <Grid item xs={12}>
                        <div className={clsx({
                            [classes.imageContainer]:true,
                            [classes.imageContainerHover]:hover,
                        })}>
                            <div className={classes.iconButtonContainer}>
                                <Grow in={hover} timeout={750}>
                                    <IconButton className={classes.shopIconButton}><Icon>add_shopping_cart</Icon></IconButton>
                                </Grow>
                            </div>
                            {error ? <img alt='' src={noproduct} className={classes.image}/> : null}
                            <Fade in={!error}><img alt='' src={selectedImage} className={classes.image} onLoad={onImageLoad} onError={onError} className={clsx({[classes.image]:true,[classes.imageHover]:hover})}/></Fade>

                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <div className={classes.caption}>{manufacturer}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <Heading size='normal' align='center'>{title}</Heading>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <div className={classes.priceContainer}>
                                    <Heading size='large' align='center' color='bluegrey_dark'>{_price}</Heading>
                                    <Slide direction='up' in={hover} >
                                        <div className={classes.shopButton}>
                                            AGREGAR
                                        </div>
                                    </Slide>
                                   
                                </div>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>       
            
        </div>
    )
}

export default ProductCard