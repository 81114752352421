import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, Grid, Hidden } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import { request_order, request_order_seller, request_payment_mercadopago_verification } from './requests'
import CheckoutLeft from './components/CheckoutLeft'
import CheckoutRight from './components/CheckoutRight'
import LoadingContainer from '../../components/Structure/LoadingContainer'
import queryString from 'query-string'
import axios from 'axios'
import { config_data } from '../../variables/config'

const mpInstance = axios.create({
    baseURL:'https://api.mercadopago.com/v1',
    headers: {'Authorization': `Bearer ${config_data.MP_ACCESS_TOKEN}`}
})

const useStyles = makeStyles(theme => ({
    home: {
        position: 'relative',
        width:'100%',
        minHeight:'100vh',
        boxSizing:'border-box',
        paddingBottom:64
    },
    root: {
        width: 1100,
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding:'0 5%',
            boxSizing:'border-box'
        },
    },
    subroot: {
        position: 'absolute',
        background: grey[100],
        width: '50%',
        height: '100%',
        borderLeft: `1px solid ${grey[300]}`,
        transform: 'translateX( calc(100% - 2px))',
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            display:'none'
        },
    },
    leftContainer: {
        boxSizing: 'border-box',
        paddingTop: 56,
        paddingRight: 44,
        [theme.breakpoints.down('sm')]: {
            paddingRight:0
        },
    },
    rightContainer: {
        boxSizing: 'border-box',
        paddingTop: 56,
        paddingLeft: 44
    }
}))

const OrderStatusView = props => {

    const classes = useStyles()
    const { match, history, location } = props
    const [order, setOrder] = useState(null)
    const [seller, setSeller] = useState(null)
    const [loading, setLoading] = useState(true)
    const [step, setStep] = useState(3)
    const paymentViewRef = useRef()
    const [payment_status, setPaymentStatus] = useState(null)


    useEffect(() => {
        const initModule = async () => {try {
            setLoading(true)
            const args = queryString.parse(location.search)
            const orderID = match.params.id
            if(args && args.payment_id){
                // Update payment info if it is redirected from Mercado Pago Site 
                const {payment_id} = args
                const _order = await request_payment_mercadopago_verification(orderID, payment_id)
                if(_order.payment) setPaymentStatus(_order.payment.status_id)
                const _seller = await request_order_seller(orderID)
                setOrder(_order)
                setSeller(_seller)
                localStorage.removeItem('orderID')
                setLoading(false)
                return
            }
            // Check order in normal status
            const _order = await request_order(orderID)
            const _seller = await request_order_seller(orderID)
            if(_order.payment) setPaymentStatus(_order.payment.status_id)
            setOrder(_order)
            setSeller(_seller)
            setLoading(false)
            console.log(_order)
        } catch (error) {
            console.log(error)   
            setLoading(false)
        }}
        initModule()
        //console.log(paymentViewRef)
    }, [])

    const onCompletePayment = () => {
        if(paymentViewRef){
            paymentViewRef.current.submit()
        }
    }

    return (
        <div>
            <LoadingContainer loading={loading}>
                <div className={classes.home}>
                    <div className={classes.subroot} />
                    <div className={classes.root}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div className={classes.leftContainer}>
                                    <CheckoutLeft payment_status={payment_status} step={step} onChangeStep={(val) => setStep(val)} history={history} order={order} seller={seller} 
                                    onUpdateOrder={(data) => setOrder(data)} passedInRef={paymentViewRef} onCompletePayment={onCompletePayment}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Hidden smDown>
                                <div className={classes.rightContainer}>
                                    <CheckoutRight step={step} order={order} onCompletePayment={onCompletePayment}/>
                                </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </LoadingContainer>
            
        </div>
        

    )
}

const mapStateToProps = state => {
    return {
        apolloClient: state.apolloClient
    }
}

export default connect(mapStateToProps)(OrderStatusView)