export const content = {
    categories:{
        title:'Categorías',
        menu:[
            {id:1, label:'Condición', url:''},
            {id:2, label:'Marcas', url:''},
        ]
    },
    information:{
        title:'Información',
        menu:[
            {id:5, label:'Contáctanos', url:'mailto:contacto@ubelens.com?'},
            {id:6, label:'Legal', url:'/legal'},
            {id:7, label:'Política de Tratamiento de Datos Personales', url:'/privacy'},
            {id:8, label:'Archivo de Protección de Datos', url:'https://ubelens-dev-public.s3.amazonaws.com/documents/POLITICA+DE+PROTECCI%C3%93N+DE+DATOS+UBELENS+COLOMBIA+S.pdf'}
        ]
    },
    contact:{
        title:'Contáctanos',
        contacts:[
            {id:1, icon:'location_on', text:'Ubelens, Calle 86 No 50 - 129, Barranquilla, Atlántico'},
            {id:2, icon:'call', text:'Llámanos ahora: +57 (300) 4213212 o al +57 (322) 2612513'},
            {id:3, icon:'mail_outline', text:'Email: contacto@ubelens.com'},
        ]
    }

    
}