import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_order = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/order/${id}`)
    return response.data.data
}

export const request_payment_mercadopago_verification = async(orderid, paymentid) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${orderid}/transaction/mercadopago/${paymentid}`)
    return response.data.data
}

export const request_order_seller = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/order/${id}/vendor`)
    return response.data.data
}

export const request_update_order = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.patch(`/order/${id}`, data)
    return response.data.data
}

export const request_update_shipping_address = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.patch(`/order/${id}/shippingAddress`, data)
    return response.data.data
}

export const request_update_billing_address = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.patch(`/order/${id}/billingAddress`, data)
    return response.data.data
}

export const request_payment_credit_card = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/creditcard`, data)
    return response.data.data.payment_id
}

export const request_payment_credit_card_verification = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/creditcard/verify`, data)
    return response.data.data
}

export const request_payment_cash= async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/cash`, data)
    return response.data.data
}

export const request_complete_order = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/complete`)
    return response.data.data
}