import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import ContainedButton from '../../../components/Actions/Buttons/ContainedButton';
import InputForm from '../../../components/Forms/InputForm';
import SelectForm from '../../../components/Forms/SelectForm';
import SimpleCard from '../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../components/Texts/DisplayText';
import { request_update_shipping_address } from '../requests';
import { onGetSelectedFormData, onSetErrorsToForm, isFormValidV2 } from '../../../shared/utility';

const ShippingAddressForm = ({user_id, address, form, onChange, onUpdateForm, onUpdateData, onDispatchSuccess}) => {

    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = async () => {
        const errors = isFormValidV2(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            onUpdateForm(new_form)
            return
        }
        //Extract data
        let data2send = onGetSelectedFormData(form, address)
        setSending(true)
        try {
            const _user = await request_update_shipping_address(user_id, data2send);
            onUpdateData(_user)
            setSending(false)
            onDispatchSuccess('Acción exitosa')
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }
    }

    return ( 
        <div>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='h6' style={{fontWeight:500}}>Dirección de envío</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InputForm data={form.address1} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.address2} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.province} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.city} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.country} onChange={onChange}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                </Grid>
            </Grid>
        </div>

     );
}
 
export default ShippingAddressForm;