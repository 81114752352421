import { makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../components/Texts/DisplayText'
import logo from '../../assets/ubelens-logo-color.png'
import logoWhite from '../../assets/ubelens-logo-white.png'

const useStyles = makeStyles(theme => ({
    root:{
        padding:'16px 32px',
        maxWidth:1100, 
        margin:'auto'
    },
    header:{
        margin:'16px 0px',
    },
    logo:{
        backgroundColor:theme.palette.primary.main,
        textAlign:'center',
        marginBottom:16
    }

}))

const Legal = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.logo}>
                <img src={logo} alt='' width={150} style={{marginTop:4}}/>
            </div>
            <DisplayText variant='h4' color='primary'>MARCO LEGAL WEBSITE UBELENS PÁGINA WEB</DisplayText>
            <DisplayText color='primary' className={classes.header} variant='h6'>1. POLÍTICAS DE COOKIES</DisplayText>
            <DisplayText>
            Una cookie es un pequeño fichero de letras y cifras que se almacena en el navegador 
            del usuario al acceder a determinadas páginas web. Esta web almacena tanto cookies propias 
            como de terceros con el objetivo de adaptar los contenidos a sus intereses y facilitar su navegación.
            <br />
            <br />
            Cookies propias
            <br />
            <br />
            •	Cookies Técnicas: tienen el objetivo de controlar el tráfico y la comunicación correcta de datos, 
            mantener la configuración, identificar sesiones de navegación (que los servidores reconozcan, a pesar de 
            pasar de una página a otra, que se trata de la misma sesión de navegación de un usuario), acceder a partes 
            de acceso restringido, utilizar elementos de seguridad durante la navegación. Sin ellas, 
            la navegación no sería coherente. Estas cookies son de sesión. 
            <br />
            •	Cookies Funcionales: son aquellas que registran información sobre opciones del usuario, permitiendo 
            adaptar el Sitio Web (preferencias de idioma, navegador a través del que se accede al servicio, 
            configuración regional, etc.). Estas cookies son de sesión y persistentes.
            <br />
            <br />
            Cookies de terceros
            <br />
            <br />
            Cookies de Análisis: permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico 
            de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página 
            web, con el fin de mejorar la oferta de productos o servicios que le ofrecemos. Con el fin de someter dicha información 
            a un tratamiento estadístico, se utilizan los servicios de Google Analytics; ello implica que la recogida y almacenamiento 
            de la indicada información (en adelante, los procedimientos) se realiza mediante “cookies” directamente entre el 
            navegador del usuario y el servidor de Google Analytics. Cuando el usuario se conecte nuevamente con el presente website, 
            el citado servidor reconocerá el número almacenado en “cookie”, según se ha indicado, suministrando la información anónima 
            referida.
            <br />
            Los procedimientos están gestionados y controlados exclusivamente por Google Analytics. 
            <br />
            Si desea conocer la declaración de privacidad y políticas de privacidad y cookies de Google Analytics puede hacerlo en 
            esta dirección <a href='https://support.google.com/analytics/answer/6004245?hl=es' target='_blanck'>https://support.google.com/analytics/answer/6004245?hl=es</a> 
            <br />
            Se llevan a cabo acciones de remarketing mediante Facebook Ads, que utiliza las cookies para 
            ayudarnos a ofrecer anuncios en línea específicos basados en visitas anteriores a nuestro Sitio Web. Si desea conocer más, 
            ingrese a <a href='https://www.facebook.com/policies/cookies/' target='_blanck'>https://www.facebook.com/policies/cookies/</a>.
            <br/>
            Las implicaciones de privacidad estarán en función de cada red social y dependerán de la configuración de privacidad que 
            ha elegido en estas redes. En ningún caso, ni el responsable de esta web ni los anunciantes pueden obtener información 
            personal identificable de estas cookies. 
            </DisplayText>
            <DisplayText color='primary' className={classes.header} variant='h6'>
                ¿CÓMO PUEDO REVOCAR EL CONSENTIMIENTO PARA EL USO DE COOKIES? ¿CÓMO PUEDO DESACTIVAR LAS COOKIES?
            </DisplayText>
            <DisplayText>
                El usuario puede en cualquier momento revocar el consentimiento para el uso de cookies mediante su desactivación o 
                borrado, a través de las opciones de privacidad/seguridad de su navegador.
            <br />
                Para más información, le recomendamos que consulte la ayuda que su navegador le ofrece para gestionar las cookies:
            <br />
            •	 Internet Explorer <a href='http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies' target='_blanck'>(http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies)</a>
            <br />
            •	 Mozilla Firefox <a href='http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we' target='_blanck'>(http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we)</a>
            <br />
            •	 Google Chrome <a href='https://support.google.com/chrome/answer/95647?hl=es' target='_blanck'>(https://support.google.com/chrome/answer/95647?hl=es)</a>
            <br />
            •	Safari <a href='http://support.apple.com/kb/HT1677?viewlocale=es_ES' target='_blanck'>(http://support.apple.com/kb/HT1677?viewlocale=es_ES)</a>
            <br />
            <br/>
            Si usted borra o desactiva las cookies, es posible que algunas funciones del presente Sitio Web no estén disponibles para 
            su utilización o que su funcionamiento no sea óptimo.
            </DisplayText>
            <DisplayText color='primary' className={classes.header} variant='h6'>2. AVISO DE PRIVACIDAD</DisplayText>
            <DisplayText>
            El presente Aviso de Privacidad establece los términos y condiciones en virtud de los cuales UBELENS, identificado con 
            NIT 901.027.531-5 y con domicilio en la Calle 86 No. 50-129 de Barranquilla, Colombia, realizará el presente tratamiento de sus 
            datos personales en cumplimiento con la Ley 1581 de 2012 “Ley de Protección de Datos Personales” y el Decreto 1377 de 2013. 
            <br/>
            <br/>
            1. TRATAMIENTO Y FINALIDAD 
            <br/>
            <br/>
            La “Información y Datos Personales que serán tratados” serán utilizados para las siguientes finalidades, las cuales son necesarias 
            para la existencia, mantenimiento y cumplimiento de la relación jurídica entre el cliente o usuario y el Sitio Web.
            <br/>
            <br/>
            Finalidades principales
            <br/>
	        •	 Para que usted pueda crear, utilizar, personalizar y aprovechar las funcionalidades disponibles a través de su Cuenta de Usuario. 
	        <br/>
            •	 Para que usted pueda realizar la compra de los productos que se comercializan a través del Sitio Web y le sean enviados los productos 
            adquiridos. 
	        <br/>
            •	 Para proveerle los productos y/o servicios disponibles a través del Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> 
	        <br/>
            •	 Para identificarlo para efectos de los servicios de atención al cliente, así como para el procesamiento de solicitudes a las que se refiere el presente Aviso de Privacidad.
	        <br/>
            •	 Para informarle sobre cambios y/o actualizaciones en el Sitio Web, en los Términos y Condiciones, y en el presente Aviso de Privacidad.
	        <br/>
            •	 Para evaluar la calidad y funcionalidad de los bienes y servicios provistos por el Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> 
	        <br/>
            •	 Para realizar una lista de usuarios y mantener un expediente de cada usuario.
	        <br/>
            •	 Para validar que cuenta con la capacidad para adquirir los bienes que se ofrecen a través del Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> en cuanto se trate de dispositivos de venta bajo prescripción.
	        <br/>
            •	 Para informarle sobre promociones, descuentos y demás publicidad relacionada con el Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> 
	        <br/>
            •	 Para fines de mercadotecnia, publicidad y prospección comercial, si acepta su respectivo envío.  
	        <br/>
            •	 Para realizar el Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> u otros terceros, estudios de mercado, promocionales, estadísticos, publicitarios, comerciales.
            <br/>
            <br/>
            UBELENS es la responsable del tratamiento de sus datos en este Sitio Web y demás aplicaciones móviles, email, textos u otros mensajes electrónicos. 
            Siendo los mecanismos que usamos seguros y confidenciales, que garantizan el acceso indeseado por parte de terceras personas. 
            En cualquier caso, los datos solicitados a través de la Web son necesarios para la prestación de un servicio óptimo al usuario. 
            En caso de que el usuario no facilite los datos solicitados, UBELENS no garantiza que la información y servicios prestados se ajusten por completo a sus necesidades.
            </DisplayText>
            <br/>
            <DisplayText >1.1. Tipos de Datos que recopila Ubelens</DisplayText>
            <br/>
            <DisplayText>
            UBELENS garantiza la protección de la privacidad de los datos que nos suministra a través de la página web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> y demás 
            aplicaciones móviles como emails, textos, u otros mensajes electrónicos entre usted y nuestra web. Para registrarse en nuestra página web, 
            deberá suministrar información personal la cual puede incluir datos como su nombre completo, fecha de nacimiento, ocupación, email, 
            teléfono, dirección para envío de correspondencia, así como también información relacionada con su tarjeta de crédito, ciudad y país de 
            residencia, detalles de su pedido, acerca de su conexión a internet, del equipo que usa para acceder a la web y registros de uso. También 
            solicitamos la fecha de nacimiento con el fin de impedir que se registren en nuestra página web y demás aplicaciones móviles menores de   
            trece (13) años de edad. Esta Política de Privacidad no aplica para la información recogida por cualquier otra web o aplicación móvil 
            operada por terceros, incluyendo cualquier aplicación o contenido (publicidad) que esté enlazado o accesible desde nuestra web.
            </DisplayText>
            <br/>
            <DisplayText >1.2. ¿Cómo utiliza Ubelens los datos personales recogidos?</DisplayText>
            <br/>
            <DisplayText>
            La información que recogemos y que nos suministra, o la que recogemos de manera automática mediante el sistema de información mientras está navegando en nuestra página web, 
            la usamos para mejorar la experiencia de compra, y con los siguientes fines: 
            <br />
            •	Procesar sus pedidos en línea, incluido el envío de correos electrónicos y/o mensajes de texto para informarle sobre el estado de un envío.
            <br />
            •	Mejorar la experiencia del usuario. 
            <br />
            •	Gestionar llamadas y contactos en general. 
            </DisplayText>
            <br/>
            <DisplayText>1.3. ¿Con quién comparte Ubelens los datos personales recogidos?</DisplayText>
            <br/>
            <DisplayText>
            UBELENS podrá intercambiar información con terceros a efectos de protección contra el fraude y la reducción de riesgo de crédito. 
            A su vez, estos terceros se comprometen a cumplir con lo dispuesto en el presente documento para el manejo de sus datos. En nuestro 
            Sitio Web, contamos con proveedores de servicios y prestadores de datos encargados de los servicios de alojamiento de sistemas y 
            mantenimiento de las redes, análisis, aplicativos para toma de pedidos, pagos y transacciones, logística de envío, entre otros, 
            quienes podrán acceder a su información de datos personales para garantizar la debida prestación del servicio. Todos los prestadores 
            de servicios deberán cumplir con nuestra Política de Tratamiento de Datos Personales. 
            </DisplayText>
            <DisplayText className={classes.header}>2. DERECHOS DEL TITULAR </DisplayText>
            <DisplayText>
            En cumplimiento de lo dispuesto en la normatividad vigente aplicable al tratamiento de datos personales, como Titular de sus datos 
            personales Usted tiene derecho a ser informado de la(s) finalidade(s) de la recolección de datos personales y a los derechos de 
            acceso, rectificación, cancelación y oposición frente a datos parciales, inexactos, incompletos, fraccionados que induzcan a error, 
            o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado, así como el derecho a revocar el consentimiento otorgado para 
            el tratamiento de datos personales. 
            <br/>
            Así mismo el Titular de los datos personales podrá acceder en forma gratuita a sus datos personales que hayan sido objeto de 
            tratamiento. Abstenerse de responder las preguntas sobre datos sensibles. Tendrán carácter facultativo las respuestas que versen sobre 
            datos sensibles o sobre datos de las niñas, niños y adolescentes
            <br/>
            A continuación, se hace una breve descripción de cada uno de los derechos y lo que significa:
            <br/>
            - Cancelación: facultad que tiene el Titular de los datos personales de poder solicitar ante el responsable del tratamiento de sus 
            datos personales, la cancelación o supresión de un dato que resulte innecesario o no para la finalidad con la que inicialmente fue 
            suministrado. El material publicitario, de oferta, descuentos, y en general todo aquel contenido que es enviado por e-mail a todos 
            nuestros usuarios, consta de la opción para cancelar la recepción de tal contenido, en el evento en que así el usuario lo requiera. 
            De igual manera, si desea retirar su consentimiento para el tratamiento de sus datos personales, puede escribir al correo 
            electrónico : contacto@ubelens.com. 
            <br/>
            - Oposición: facultad que tiene el Titular de la información de oponerse al tratamiento de sus datos personales, salvo los casos en 
            que tal derecho no proceda por disposición legal o por vulnerar intereses generales superiores al interés particular. 
            <br/>
            - Acceso: facultad que tiene el Titular de los datos de obtener toda la información respecto de sus propios datos personales, bien 
            sea de manera parcial o completa, de conocer el tratamiento aplicado a los mismos, su finalidad, y las diferentes bases de datos en 
            las que estos son almacenados, así como también de conocer acerca de la transferencia de datos o revelación de información, sea 
            autorizada o no. 
            <br/>
            - Rectificación:  facultad que tiene el Titular de la información de modificar aquellos datos que resulten ser inexactos, incompletos 
            o inexistentes. Podrá pedir la corrección, la adición, la eliminación o el bloqueo de sus datos personales.
            </DisplayText>
            <DisplayText className={classes.header} >3. MECANISMOS PARA CONOCER LA POLÍTICA DE TRATAMIENTO DE DATOS </DisplayText>
            <DisplayText>
            El Titular puede acceder a la Política de Tratamiento de Datos Personales en el Sitio Web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> en el menú inferior en el 
            link Políticas. 
            Si desea presentar cualquier solicitud o petición relacionada con la protección de datos personales puede enviar correo a 
            contacto@ubelens.com o comuníquese al teléfono en Barranquilla 57-5-3226161 o en la dirección Calle 86 No. 50-129.
            </DisplayText>
            <DisplayText className={classes.header} color='primary' variant='h6'>3. PROPIEDAD INTELECTUAL E INDUSTRIAL</DisplayText>
            <DisplayText>
            UBELENS es una marca registrada y todos los derechos de propiedad intelectual e industrial de su página 
            web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a> tanto sus contenidos, como videos, diseños, textos, gráficos, 
            logos, iconos, combinaciones de colores, estructura, botones, así 
            como el software, los nombres comerciales, obras, ilustraciones, fotografías o dibujos industriales y cualesquiera otros signos 
            susceptibles de utilización industrial y comercial son propiedad y titularidad de UBELENS o de terceros titulares de los mismos 
            que han autorizado debidamente su inclusión en la página web <a href='www.ubelens.com.' target='_blanck'>www.ubelens.com.</a>.
            Queda expresamente prohibida su reproducción para uso comercial. 
            </DisplayText>
        </div>
    )

}

export default Legal