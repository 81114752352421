import React, {useState} from 'react'
import { Grid, makeStyles, Icon, fade } from '@material-ui/core'
import Heading from '../../../Texts/Heading'

import { content } from '../content'
import PopperContainer from '../../../Overlays/PopperContainer'
import MenuItem from './MenuItem'
import clsx from 'clsx';
import SectionItem from './SectionItem'
import { blueGrey } from '@material-ui/core/colors'
import DisplayText from '../../../Texts/DisplayText'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    popper:{
        minWidth:300,
        padding:theme.spacing(3),
    },
    divider:{
        width:2,
        height:200,
        background:blueGrey[50]
    },
}))

const MenuTopBar = props => {

    const {onSelectedItem} = props

    const classes = useStyles()

    const [selected, setSelected] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSubmenuActive, setIsSubmenuActive] = useState(false)
    const [submenu, setSubmenu] = useState([])

    let timer = null

    const onSelectedMenu = (e, id) => {
        setAnchorEl(e.currentTarget)
        setSelected(id)
        const findElement = content.menu.find(el => el.id === id)
        setSubmenu(findElement.sections)

    }

    const onInnerSelectedItem = (url) => {
        setSelected(null)
        setAnchorEl(null)
        setSubmenu([])
        onSelectedItem(url)
    }

    const onClose = () => {
        setAnchorEl(null)
        setSelected(null)
        setSubmenu([])
    }

    let submenuContent = null

    if(submenu && submenu.length){
        submenuContent = (
            <PopperContainer anchorEl={anchorEl} topOffset={4} onClose={onClose}>
                <div className={classes.popper}>
                    <Grid container spacing={4} wrap="nowrap">
                        {submenu.map((item,key)=>{
                            return(
                                <Grid item key={key.toString()}>
                                    <div style={{minWidth: 150}}>
                                        <Grid container spacing={4} wrap="nowrap">
                                            <Grid item>
                                                <SectionItem {...item} onItemSelected={onInnerSelectedItem}/>
                                            </Grid>
                                            {submenu.length-1 !== key ? (
                                                <Grid item>
                                                    <div className={classes.divider}/>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </div>
                                    
                                    
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </PopperContainer>
        )
    }

    return(
        <div>
            {submenuContent}
            <Grid container spacing={4} wrap="nowrap">
                {content.menu.map((item,key)=>{
                    return(
                        <Grid item key={key.toString()}>
                            <MenuButton {...item} selected={selected === item.id} anchorEl={anchorEl}
                            onSelectedMenu={onSelectedMenu}  onSelectedItem={onInnerSelectedItem}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )

}

export default MenuTopBar

const useButtonStyles = makeStyles(theme => ({
    root:{
        //paddingTop:theme.spacing(1),
        //paddingBottom:theme.spacing(1),
        borderTopLeftRadius:12,
        borderTopRightRadius:12,
        //borderBottom:`2px solid ${theme.palette.primary.main}`,
        //transition:theme.transitions.create(['background'],
        //    { duration: 1000 }
        //),
        '&:hover':{
            cursor:'pointer',
           // background:theme.palette.primary.main,
           // borderBottom:`2px solid #FFF`,
        },
        
    },
    activeRoot:{
        //borderBottom:`2px solid #FFF`,
        //background: fade('#FFF',0.25)
    },
    icon:{
        color:'white'
    }
    
}))

const MenuButton = props => {

    const {id, label, url, selected, onSelectedMenu} = props
    const classes = useButtonStyles()
    return(
        <div className={clsx({
            [classes.root]:true,
            [classes.activeRoot]:selected
        })} onClick={(e) => onSelectedMenu(e, id)}>
            <Grid container alignItems='center' wrap="nowrap">
                <Grid item>
                    <DisplayText size='small' color='white' noWrap>
                        {label}
                    </DisplayText>
                </Grid>
                <Grid item>
                    <Icon className={classes.icon}>expand_more</Icon>
                </Grid>
            </Grid>
            
        </div>
    )
}