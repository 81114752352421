import React, {useState, useEffect} from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import Page from '../../components/Structure/Page/Page'
import Heading from '../../components/Texts/Heading'
import { isTokenValid } from '../../shared/utility'
import { request_orders } from './requests'
import DateFilter from './components/DateFilter'
import moment from 'moment'
import LoadingContainer from '../../components/Structure/LoadingContainer'
import OrderItem from './components/OrderItem'
import OrderItemMobile from './components/OrderItemMobile'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import DisplayText from '../../components/Texts/DisplayText'
import noorders from '../../assets/orders/shopping.png'

const breadcrumbs = [
    {id:1, label:'Mis datos', url:'/profile', selected:true}
]

const useStyles = makeStyles(theme => ({
    root:{
        
        //background:'red',
        paddingBottom:200
    },
    image:{
        width:300
    },
    form:{
        marginTop:32
    },
    image:{
        width:180
    },
    noorders:{
        marginTop:40
    }
}))


const OrdersView = (props) => {
    
    const classes = useStyles()
    const {history, onUpdateCartItems} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [filterDate, setFilterDate] = useState(1)
    const [orders, setOrders] = useState([])
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState({limit:10,offset:0})


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const selectedFilter = dateFilterOptions.find(el=>el.id === filterDate)
            const _orders = await request_orders({start_date:selectedFilter.start_date, end_date:selectedFilter.end_date, order:-1, order_by:'created_at'})
            setOrders(_orders.data)
            setCount(_orders.count)

        } catch (error) {
            setError('Error de conexión con el servidor')
        } setLoading(false)}
        window.scrollTo(0,0)
        if(isTokenValid()){initModule()}else{history.push('/')}
    }, [filterDate])

    const onChangeFilterDate = (id) => {
        setFilterDate(id)
    }

    const onViewDetails = (id) => {
        history.push(`/orders/${id}/status`)
    }

    const onRecover = async(id, items) => {
        localStorage.setItem('orderID', id)
        onUpdateCartItems(items)
        history.push('/cart')

    }


    return(
        <Page breadcrumbs={breadcrumbs} error={error} onCloseErrorModal={() => setError(null)}>
            <div className={classes.root}>
                <Heading variant='h4'>Mis pedidos</Heading>
                <DateFilter selected={filterDate} onChange={onChangeFilterDate} options={dateFilterOptions}/>
                <LoadingContainer loading={loading}>
                    <Grid container spacing={2}>
                        {orders.map(item => {
                            return(
                                <Grid item xs={12} key={item._id}>
                                    <Hidden smDown>
                                        <OrderItem {...item} onViewDetails={onViewDetails}  onRecover={onRecover}/>
                                    </Hidden>
                                    <Hidden mdUp>
                                        <OrderItemMobile {...item} onViewDetails={onViewDetails}  onRecover={onRecover}/>
                                    </Hidden>
                                </Grid>
                            )
                        })}
                        {orders.length === 0 ? (
                            <Grid item xs={12}>
                                <div className={classes.noorders}>
                                    <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
                                        <Grid item>
                                            <img src={noorders} alt='' className={classes.image}/>
                                        </Grid>
                                        <Grid item>
                                            <DisplayText align='center' size='large' color='bluegrey'>No se encontró ningún pedido</DisplayText>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        ):null}
                    </Grid>
                </LoadingContainer>
            </div>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
    }
}


export default connect(null, mapDispatchToProps)(OrdersView)


const dateFilterOptions = [
    {id:1, label:'3 meses', start_date:moment().subtract(3,'months').format('YYYY-MM-DD'), end_date:moment().add(2,'days').format('YYYY-MM-DD')},
    {id:2, label:'6 meses', start_date:moment().subtract(6,'months').format('YYYY-MM-DD'), end_date:moment().add(2,'days').format('YYYY-MM-DD')},
    {id:3, label:'2021', start_date:'2021-01-01', end_date:'2021-12-31'}
]
