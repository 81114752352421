import moment from 'moment'
import { ERROR_CODES } from '../variables/errorCodes';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    if(!error) return ''
    if(error.response){
        if(error.response.data){
            message = ERROR_CODES[error.response.data.error.code]
            console.log(error.response.data.error.code)
        }
    }
    return message
}

export const onGetFullname = (data) => {
    if(!data) return 'N/D'
    return `${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''}`
}

export const onGetCompleteAddress = (data) => {
    if(!data) return 'N/D'
    return `
    ${data.address1 ? data.address1 : ''}, 
    ${data.address2 ? data.address2 : ''}, 
    ${data.city ? data.city : ''}, 
    ${data.province ? data.province : ''}, 
    ${data.zip ? data.zip : ''}`
}

export const onGetFormatNumber = (number) => {
    let result = ''
    if(number){
        let temp = parseFloat(number).toFixed(0)
        result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return result
}

export const isFormValid = (form) => {
    let response = true
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) response = false
        })
    }
    return response
} 

export const isFormValidV2 = (form) => {
    let errors = []
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const isTokenValid = () => {
    let isValid = false

    const userInfo = JSON.parse(localStorage.getItem('token_data'))
    if(userInfo){
        const currentTime = moment().unix()
        const serverTime = userInfo.exp
        isValid = currentTime < serverTime
    }
    return isValid
}

export const isCartAvailable = async() => {
    let nItems = 0

    const cartID = localStorage.getItem('cartID')
    if(cartID){
        //console.log(cartID)
        //const currentTime = moment().utc()
        //const serverTime = moment(userInfo.expiresAt).utc()
        //isValid = currentTime.diff(serverTime)/(1000*60*60) < 0
    }
    return nItems
}

export const onGetCreditCardFormat = (data) => {
    let value = data.split(' ').join('')
    if(value.length <=16) return value.match(/.{1,4}/g).join(' ')
    return value.slice(0,-1).match(/.{1,4}/g).join(' ')
}

export const onGetExpirationFormat = (data) => {
    let value = data.split('/').join('')
    if(value.length <=4) return value.match(/.{1,2}/g).join('/')
    return value.slice(0,-1).match(/.{1,2}/g).join('/')
}

export const isExpDateIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{4}$/g)) return true
    return false
}

export const isCCVIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{3,4}$/g)) return true
    return false
}

export const onGetJSONData = (data) => {
    if(!data) return {}
    let temp = {}
    Object.keys(data).forEach(item => {
        if(data[item].isValid && data[item].isVisited) temp = {...temp, [item]: data[item].value}
    })
    return temp
}

export const isFilterFormValid = (form, filter) => {
    let errors = []
    if(form && filter && filter.length){
        filter.forEach(item => {
            if(!form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}

export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]:form[item].value}
            }else{
                if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
            }
        })
    }
    return temp
}

export const onInitForm = (form, origin) => {
    let response = {...form}
    if(!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
    })
    return response
}