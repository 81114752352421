import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import InputForm from '../../../components/Forms/InputForm'
import mplogo from '../../../assets/mercadopago-logo.png'
import DisplayText from '../../../components/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    form:{
        borderRadius:4,
        padding:16,
        paddingRight:0,
    }
}))

const CheckoutCreditCard = props => {

    const classes = useStyles()
    const {form, onChange, paymentMethod} = props

    let imgSrc = null
    if(paymentMethod) imgSrc = <img src={paymentMethod.secure_thumbnail} alt='' />
    let cardNumberForm = {...form.cardNumber}
    if(cardNumberForm.isVisited) cardNumberForm.isValid = paymentMethod ? true : false

    return(
        <div className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputForm data={form.cardholderName} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <InputForm data={cardNumberForm} imgIcon={imgSrc} onChange={onChange}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputForm data={form.expiration} onChange={onChange}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputForm data={form.securityCode} onChange={onChange}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center' justify='flex-end' spacing={1}>
                        <Grid item>
                            <DisplayText color='bluegrey' >Powered by</DisplayText>
                        </Grid>
                        <Grid item>
                            <img src={mplogo} alt='' width={80}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default CheckoutCreditCard

