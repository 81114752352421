import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import { contact_lenses_categories } from '../../../texts/esp/categories'
import { request_products } from './requests'
import CollectionView from '../componentes/CollectionView'



const ContactLensesView = props => {

    const {id} = props.match.params
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [products, setProducts] = useState([])

     

    useEffect(()=>{
        const initModule = async() => {try {
            const category = contact_lenses_categories[id]
            setLoading(true)
            const _products = await request_products(category.tags)
            setProducts(_products)
            setTitle(category.title)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }}
        window.scrollTo(0,0)
        initModule()
    },[props.match])

    const onSelectedProduct = (handle) => {
        console.log(handle)
        window.scrollTo(0,0)
        props.history.push(`/contactlense-product/${handle}`)
    } 

    const onSelectedCategory = (url) => {
        window.scrollTo(0,0)
        props.history.push(url)
    }

    //console.log(products)

    let category = 'Marca'

    if(title == 'Miopía o Hipermetropía' || title == 'Astigmatismo' || title == 'Presbicia' || title == 'Lentes cosméticos') category ='Condición visual'
    if(title == 'Diarios' || title == 'Quincenales' || title == 'Mensuales' || title == 'Anuales') category = 'Remplazo'
    if(title == 'Alcon' || title == 'Bausch & Lomb' || title == 'CooperVision' || title == 'Johnson & Johnson' || title == 'ITAL-LENT') category = 'Fabricante'
   
    
    let breadcrumbs = [
        {id:1, label:'Lentes de contacto', url:'/contactlenses'},
        /* {id:2, label:'Miopía o Hipermetropía', url:`/contactlenses/${id}`, selected:true}, */
        {id:2, label:`${category}`, url:`/contactlenses/${id}`, selected:true},
        {id:3, label:`${title}`, url:`/contactlenses/${id}`, selected:true},
    ]

    if(title === 'Todos los lentes'){
        breadcrumbs = [
            {id:1, label:'Lentes de contacto', url:'/contactlenses'},
        ]
    }
    

    return(
        <Page breadcrumbs={breadcrumbs}>
            <CollectionView 
                title={title} loading={loading} products={products} sections={sections}
                onSelectedProduct={onSelectedProduct}
                onSelectedCategory={onSelectedCategory}
            />
        </Page>
    )
}

export default ContactLensesView

const sections = [
    {
        id:1, 
        label:'Por condición visual', 
        options:[
            {id:1, label:'Miopía o hipermetropía', url:'/contactlenses/miopia-o-hipermetropia'},
            {id:3, label:'Astigmatismo', url:'/contactlenses/astigmatismo'},
            {id:4, label:'Presbicia', url:'/contactlenses/presbicia'},
            {id:5, label:'Lentes cosméticos y cosmoprotésicos', url:'/contactlenses/lentes-cosmeticos'},
        ]
    },
    {
        id:2, 
        label:'Por reemplazo', 
        options:[
            {id:1, label:'Diarios', url:'/contactlenses/uso-diario'},
            {id:3, label:'Quincenales', url:'/contactlenses/uso-quincenal'},
            {id:4, label:'Mensuales', url:'/contactlenses/uso-mensual'},
            {id:5, label:'Anuales', url:'/contactlenses/uso-anual'},
        ]
    },
    {
        id:3, 
        label:'Por marca', 
        multipleColumns:true,
        options:[
            {id:1, label:'Air Optix', url:'/contactlenses/air-optix'},
            {id:2, label:'Dailies AquaComfort', url:'/contactlenses/dailies-aqua-comfort-plus'},
            {id:3, label:'Freshlook', url:'/contactlenses/freshlook'},
            {id:7, label:'Soflens', url:'/contactlenses/soflens'},
            {id:8, label:'Pure Vision 2', url:'/contactlenses/pure-vision-2'},
            {id:9, label:'Ultra', url:'/contactlenses/ultra'},
            {id:12, label:'Biomedics', url:'/contactlenses/biomedics'},
            {id:13, label:'Avaira Vitality', url:'/contactlenses/avaira-vitality'},
            {id:14, label:'Biofinity', url:'/contactlenses/biofinity'},
            {id:15, label:'Clariti', url:'/contactlenses/clariti'},
            {id:16, label:'Acuvue', url:'/contactlenses/acuvue'},
            //{id:17, label:'1-Day', url:'/contactlenses/1-day'},
            {id:18, label:'Hydrosoft', url:'/contactlenses/hydrosoft'},
        ]
    },
    {
        id:4, 
        label:'Por fabricante', 
        options:[
            {id:1, label:'Alcon', url:'/contactlenses/alcon'},
            {id:2, label:'Bausch & Lomb', url:'/contactlenses/bausch-lomb'},
            {id:3, label:'CooperVision', url:'/contactlenses/coopervision'},
            {id:4, label:'Johnson & Johnson', url:'/contactlenses/johnson-johnson'},
            {id:5, label:'ITAL-LENT', url:'/contactlenses/ital-lent'},
        ]
    },
    {
        id:5,
        label:'Ver todos',
        options:[{id:1, label:'Ver todos', url:'/contactlenses/all'},]
    }
]
