export const ADD_SESSION = 'ADD_SESSION'

export const CHANGE_AUTH_STATUS = 'CHANGE_AUTH_STATUS' 
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS'
export const UPDATE_APOLLO_CLIENT = 'UPDATE_APOLLO_CLIENT'


/// AUTHENTIFICATION
export const AUTH_LOGIN_MODAL = 'AUTH_LOGIN_MODAL'
export const AUTH_SINGUP_MODAL = 'AUTH_SINGUP_MODAL'
export const AUTH_RECOVER_PASSWORD_MODAL = 'AUTH_RECOVER_PASSWORD_MODAL'
export const AUTH_CONFIRM_LINK = 'AUTH_CONFIRM_LINK'