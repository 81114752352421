import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../components/Texts/DisplayText'
import logo from '../../assets/ubelens-logo-color.png'

const useStyles = makeStyles(theme => ({
    root:{
        padding:'16px 32px',
        maxWidth:1100, 
        margin:'auto'
    },
    header:{
        margin:'16px 0px',
    },
    def:{
        display:'inline',
        fontWeight:700
    },
    logo:{
        backgroundColor:theme.palette.primary.main,
        textAlign:'center',
        marginBottom:16
    }

}))

const Privacy = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.logo}>
                <img src={logo} alt='' width={150} style={{marginTop:4}}/>
            </div>
            <DisplayText variant='h4' color='primary'>Política de Tratamiento de Datos Personales</DisplayText>
            <DisplayText color='primary' className={classes.header} variant='h6'>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES UBELENS COLOMBIA S.A.S</DisplayText>
            <DisplayText>
            UBELENS DE COLOMBIA S.A.S en adelante “UBELENS” dando cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y el Decreto 
            Reglamento 1377 de 2013, que regulan la recolección y tratamiento de los datos de carácter personal, expide la Política de Tratamiento 
            de Datos Personales mediante aprobación de su Comité Directivo teniendo en cuenta las siguientes consideraciones: 
            <br/>
            -El artículo 15 de la Constitución Política colombiana determina que "(...) Todas/as personas tienen derecho a su intimidad personal y 
            familiar y a su buen nombre, y el Estado debe respetarlos y hacerlos respetar. De igual modo, tienen derecho a conocer, actualizar y 
            rectificar las informaciones que se hayan recogido sobre ellas en bancos de datos y en archivos de entidades públicas y privadas (...)". 
            Por lo cual constitucionalmente se consagran tres derechos fundamentales autónomos, a saber, intimidad, buen nombre y Habeas Data. En 
            la Política de Tratamiento de Datos Personales de UBELENS se desarrolla el precepto de "Habeas Data", el cual es el derecho que garantiza 
            y protege todo lo relacionado con el conocimiento, actualización y rectificación de la información personal contenida en Bases de Datos 
            y archivos, el cual ha sido desarrollado y protegido mediante la Ley Estatutaria 1581 de 2012 y su Decreto Reglamentario 1377 de 2013. 
            <br/>
            -Que UBELENS se compromete mediante sus directivas, colaboradores, contratistas, proveedores a observar, acatar y cumplir las órdenes e 
            instrucciones que de modo particular se imparten respecto al uso de los datos personales en cumplimiento de lo contenido en el artículo 
            15 de la Constitución Política de Colombia, Ley 1581 de 2012 y el Decreto 1377 de 2013.
            </DisplayText>

            <DisplayText color='primary' className={classes.header} variant='h6'>I.	DEFINICIONES </DisplayText>
            <DisplayText>
            Los términos que se usan en el presente documento tendrán el significado asignado por la Ley 1581 de 2012 y el Decreto 1377 de 2013. 
            <br/>
            <div className={classes.def}>Base de Datos.</div> Es todo conjunto organizado de datos personales que sea objeto de Tratamiento.
            <br/>
            <div className={classes.def}>Custodio de la Base de Datos.</div> Es la persona física que tiene bajo su custodia la Base de Datos Personales al interior de la 
            empresa UBELENS COLOMBIA S.A.S.  
            <br/>
            <div className={classes.def}>Dato Personal.</div> Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
            <br/>
            <div className={classes.def}>Datos Públicos.</div> Son considerados Datos Públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión
            u oficio, y a su calidad de comerciante o de servidor público. Por su naturaleza, los datos públicos pueden estos contenidos 
            entre otros, en registros públicos, documentos públicos, gacetas, boletines oficiales y sentencias judiciales debidamente 
            ejecutoriadas que no estén sometidas a reserva. 
            <br/>
            <div className={classes.def}>Datos Sensibles.</div> Se entiende por Datos Sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar 
            su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas 
            o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier 
            partido político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la 
            salud, a la vida sexual, y a los datos biométricos. 
            <br/>
            <div className={classes.def}>Encargado del Tratamiento.</div> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el 
            Tratamiento de Datos Personales por cuenta del Responsables del Tratamiento.
            <br/>
            <div className={classes.def}>Principios para el Tratamiento de Datos.</div> Son las reglas fundamentales, de orden legal y/o jurisprudencia!, que inspiran y orientan 
            el Tratamiento de Datos Personales, a partir de los cuales se determinan acciones y criterios para dar solución a la posible 
            colisión eritreo el derecho a la intimidad, Habeas Data y protección de los Datos Personales, y el derecho a la información. 
            <br/>
            <div className={classes.def}>Responsable del Tratamiento.</div> Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre 
            la Base de Datos y/o el Tratamiento de los datos. 
            <br />
            <div className={classes.def}>Titular.</div> Es la persona física cuyos datos sean objeto de Tratamiento. 
            <br/>
            <div className={classes.def}>Tratamiento.</div> Cualquier operación o conjunto de operaciones sobre datos personales, tales como recolección, almacenamiento, uso, 
            circulación o supresión. 
            <br/>
            <div className={classes.def}>Transferencia.</div> La transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de Datos Personales, 
            ubicado en Colombia, envía la información o los datos personales a un receptor que a su vez es Responsable del Tratamiento y 
            se encuentra dentro o fuera del país. 
            </DisplayText>

            <DisplayText  className={classes.header} color='primary' variant='h6'>II.	ALCANCE Y APLICACIÓN </DisplayText>
            <DisplayText>
            •	UBELENS, está debidamente constituida bajo el ordenamiento jurídico colombiano, identificada con el Número de Identificación Tributaria (NIT) 901.027.531-5 cuyo domicilio se encuentra ubicado en la Calle 86 No 50 - 129 en la ciudad de Barranquilla, y con teléfono de contacto (+57)5 3226161.
            <br/>
            •	Que UBELENS, para cumplir con su objeto social recolecta, utiliza, custodia, almacena, usa, circula, hace tratamiento, modifica, actualiza, corrige y suprime datos personales con quienes tiene o han tenido relación o vínculo, tales como trabajadores y familiares de éstos, accionistas, consumidores, clientes, distribuidores, proveedores, acreedores, entre otros. Por consiguiente, UBELENS es el encargado o el responsable de organizarlos en bases de datos personales, con el fin de ejercer un tratamiento sobre los mismos, al almacenarlos, consultarlos, actualizarlos, compartirlos o eliminarlos.
            <br/>
            •	Teniendo en cuenta lo anterior, UBELENS manifiesta que garantiza los derechos de la privacidad, la intimidad, el buen nombre y la autonomía, en el tratamiento de los datos personales, y en consecuencia todas sus actuaciones se regirán por los principios de legalidad, finalidad, libertad, veracidad o calidad, transparencia, acceso y circulación restringida, seguridad y confidencialidad y en cumplimiento de las disposiciones previstas en los artículos 15 y 20 de la Constitución Política, la Ley 1581 de 2012, el Decreto 1377 de 2013, el Decreto 886 de 2014 y el Decreto Único 1074 de 2015.
            <br/>
            •	Así mismo, de acuerdo a lo dispuesto en el artículo 8 de la Ley 1581 de 2012, UBELENS, mediante las presentes políticas otorga los canales necesarios para que terceras personas como titulares de información personal y en reconocimiento de su derecho de Habeas Data, ejerzan sus derechos de conocer, actualizar, rectificar sus datos personales, realicen reclamos y consultas, etcétera.
            <br/>
            •	La aceptación de estas políticas de tratamiento y protección en materia de datos personales constituye condición obligatoria e indispensable para establecer un vínculo con UBELENS, ya sea a través de la página web (www.ubelens.com) o de forma física en su domicilio. 
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>III.	OBLIGACIONES DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</DisplayText>
            <DisplayText>
            De acuerdo a lo dispuesto en el artículo 17 de la Ley 1581 de 2012, UBELENS como responsable del tratamiento de datos personales, tendrá los siguientes deberes: 
            <br/>
            •	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de Hábeas Data.
            <br/>
            •	Solicitar y conservar copia de la respectiva autorización otorgada por el Titular para el tratamiento de datos personales. 
            <br/>
            •	Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten en virtud de la autorización otorgada. 
            <br/>
            •	Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. 
            <br/>
            •	Garantizar que la información sea veraz, completa, exacta, actualizada, comprobable y comprensible. 
            <br/>
            •	Actualizar oportunamente la información y velar porque se mantenga actualizada. 
            <br/>
            •	Rectificar la información cuando sea incorrecta y comunicar lo pertinente. 
            <br/>
            •	Respetar las condiciones de seguridad y privacidad de la información del Titular. 
            <br/>
            •	Tramitar las consultas y reclamos formulados en los términos señalados por la ley. 
            <br/>
            •	Informar a solicitud del Titular sobre el uso dado a sus datos. 
            <br/>
            •	Cumplir los requerimientos e instrucciones que imparta la Superintendencia de Industria y Comercio sobre el tema en particular.
            <br/>
            •	En el evento en que el tratamiento sea ejercido mediante un Encargado, UBELENS en su calidad de Responsable deberá garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible y previamente haya sido autorizada por el Titular para su tratamiento y contará con los medios necesarios para actualizar la información suministrada al Encargado.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>IV.    AUTORIZACIÓN POR PARTE DEL TITULAR</DisplayText>
            <DisplayText>
            UBELENS, en cumplimiento de sus obligaciones legales, deberá exigir al Titular de los datos personales autorización previa, expresa e informada para ejercer tratamiento sobre los datos personales a suministrar. La autorización por parte del Titular podrá ser obtenida por UBELENS mediante documento físico, electrónico u otro formato que permita garantizar su consulta y verificación, posterior del otorgamiento de dicha autorización por parte del Titular y de las entidades competentes.
            <br/>
            La autorización otorgada a UBELENS deberá contener, en virtud de lo consagrado en la Ley 1581 de 2012, lo siguiente: 
            <br/>
            •	La información de contacto de UBELENS como Responsable del Tratamiento de los datos personales. Calidad adquirida en virtud del ejercicio de la recopilación de los datos personales del Titular;
            <br/>
            •	La descripción de la naturaleza y carácter de los datos personales recopilados por UBELENS en su calidad de Responsable del Tratamiento;
            <br/>
            •	La(s) finalidade(s) del tratamiento de los datos personales solicitados por parte de UBELENS;
            <br/>
            •	Los canales y medios con los que cuenta el Titular de los datos personales para el ejercicio de su derecho de Habeas Data en relación con el acceso, corrección, actualización o supresión de los datos personales suministrados a UBELENS.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>V.	  AVISO DE PRIVACIDAD  </DisplayText>
            <DisplayText>
            El Aviso de Privacidad informa al Titular los medios con los que cuenta, ya sea de forma física o electrónica, para acceder a la Política de Tratamiento de Datos Personales de UBELENS, comunicando lo siguiente:  
            <br/>
            •	La información de contacto de UBELENS, en su calidad de Responsable del Tratamiento de los Datos Personales, en virtud del ejercicio de la recopilación de los datos personales del Titular;
            <br/>
            •	El tipo de tratamiento al cual serán sometidos los datos y la finalidad del mismo;
            <br/>
            •	Los canales y medios con los que cuenta el Titular de los datos personales para consultar las políticas de tratamiento y protección en materia de datos personales.
            <br/>
            •	Los derechos que le asisten al Titular en ejercicio de su derecho de Habeas Data en relación con el acceso, corrección, actualización o supresión de los datos personales suministrados a UBELENS. 
            <br/>
            UBELENS deberá conservar el modelo del Aviso de Privacidad que haya trasmitido a los Titulares de los datos personales recolectados, siempre que se siga efectuando el tratamiento sobre dichos datos personales, por consiguiente, perduren las obligaciones que de éste se deriven. 
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>VI.	DEBERES DE LOS ENCARGADOS DEL TRATAMIENTO  </DisplayText>
            <DisplayText>
            En caso de contar con Encargados del Tratamiento, UBELENS asegurará que éstos cumplan con los siguientes deberes:
            <br/>
            •	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de Hábeas Data;
            Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;
            <br/>
            •	Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de la Ley 1581 de 2012;
            <br/>
            •	Actualizar la información reportada por los Responsables del Tratamiento dentro de los cinco (5) días hábiles contados a partir de su recibo;
            <br/>
            •	Tramitar las consultas y los reclamos formulados por los Titulares en los términos señalados en la Ley 1581 de 2012;
            <br/>
            •	Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la Ley 1581 de 2012 y, en especial, para la atención de consultas y reclamos por parte de los Titulares;
            <br/>
            •	Registrar en la Base de Datos la leyenda “reclamo en trámite” en la forma en que se regula en la Ley 1581 de 2012;
            <br/>
            •	Insertar en la Base de Datos la leyenda “información en discusión judicial” una vez notificado por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del dato personal;
            <br/>
            •	Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;
            <br/>
            •	Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella;
            <br/>
            •	Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares;
            <br/>
            •	Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>VII.	MEDIOS Y CANALES PARA EL EJERCICIO DE HABEAS DATA</DisplayText>
            <DisplayText>
            En cumplimiento de lo dispuesto en la Ley 1581 de 2012, UBELENS deberá suministrar canales y medios al Titular de la información o datos personales para el ejercicio del Habeas Data, y los cuales deben ser informados en el Aviso de Privacidad, tales como:
            <br/>
            •	Correo electrónico: contacto@ubelens.com
            <br/>
            •	Toda la información de estas políticas estarán ubicadas en la página web www.ubelens.com. 
            <br/>
            •	Servicios de atención al cliente o de reclamación en las instalaciones físicas: Calle 86 No 50 – 129. 
            </DisplayText>

            <DisplayText color='primary' className={classes.header} variant='h6'>VIII.	DERECHOS DE LOS TITULARES DE LOS DATOS </DisplayText>
            <DisplayText>
            Conocer, actualizar y rectificar sus datos personales frente a UBELENS en su condición de Responsable del Tratamiento, en relación a 
            datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido 
            o no haya sido autorizado. De conformidad con lo dispuesto en el artículo 8 de la Ley 1581 de 2012 y el artículo 21 y 22 del Decreto 
            1377 de 2013, los Titulares de los datos personales suministrados a UBELENS, tienen los siguientes derechos:            
            <br/>
            <br/>
            <div className={classes.def}>•	Derecho de acceso: </div> comprende la facultad del Titular del Dato Personal de obtener toda la 
            información respecto de sus propios Datos Personales, sean parciales o completos, del Tratamiento aplicado a los mismos, de la 
            finalidad del Tratamiento, la ubicación de las Bases de Datos que contienen sus Datos Personales, y sobre las comunicaciones y/o 
            cesiones realizadas respecto de ellos, sean éstas autorizadas o no.
            <br/>
            <div className={classes.def}>•	Derecho de actualización:</div> se refiere a la facultad del Titular del dato de actualizar sus Datos 
            Personales cuando éstos hayan tenido alguna variación. 
            <br/>
            <div className={classes.def}>•	Derecho de rectificación:</div> es la facultad del Titular del Dato Personal de modificar los Datos 
            Personales que resulten ser inexactos, incompletos o inexistentes.
            <br/>
            <div className={classes.def}>•	Derecho de cancelación: </div> es la facultad del Titular de Datos Personales de cancelar los mismos 
            o suprimirlos cuando sean excesivos, no pertinentes, o el Tratamiento sea contrario a las normas, salvo en aquellos casos contemplados 
            como excepciones o exigidos por la Ley y/o que sea necesario en un marco contractual específico. 
            <br/>
            <div className={classes.def}>•	Derecho de la revocatoria del consentimiento: </div> derecho de revocar el consentimiento o la autorización 
            que habilitaba a UBELENS para un Tratamiento con determinada finalidad, salvo en aquellos casos contemplados como excepciones por la Ley 
            y/o que sea necesario en un marco contractual específico. 
            <br/>
            <div className={classes.def}>•	Derecho de oposición: </div> se refiere a la facultad del Titular del Dato Personal de oponerse al Tratamiento 
            de sus Datos Personales, salvo los casos en que tal derecho no proceda por disposición legal o por vulnerar intereses generales superiores 
            al interés particular. UBELENS, con base en los legítimos derechos que argumente el Titular del Dato Personal, hará un juicio de proporcionalidad 
            o ponderación con el fin de determinar la preeminencia o no del derecho particular del Titular del Dato Personal sobre otros derechos, 
            verbigracia, el derecho de información.
            <br/>
            <div className={classes.def}>•	Derecho de presentar quejas o reclamos:</div> el Titular del Dato Personal tiene derecho a presentar ante la 
            Superintendencia de Industria y Comercio, o la entidad que fuera competente, quejas y reclamos, así como las acciones que resultaren 
            pertinentes, para la protección de sus datos una vez haya agotado el trámite de consulta o reclamo ante el Responsable del Tratamiento o 
            Encargado del Tratamiento. 
            <br/>
            <div className={classes.def}>•	Derecho a otorgar autorización para el tratamiento: </div> el Titular del Dato Personal tiene derecho a otorgar 
            autorización para tratar sus datos personales, esta autorización no será requerida cuando sea un dato público o cuando sea requerida por entidad 
            pública o administrativa, en caso de una emergencia sanitaria o médica, cuando sea para fines históricos o estadísticos, cuando sean datos cuya 
            finalidad sea de seguridad nacional.  
            <br/>
            <div className={classes.def}>
                Así mismo el Titular del Dato Personal podrá ejercer los siguientes derechos que a continuación se detallan: 
            </div> 
            <br/>
            •	Solicitar prueba de la autorización otorgada a UBELENS, en su condición de Responsable del Tratamiento;
            <br/>
            •	Ser informado por UBELENS, previa solicitud, respecto del uso que le ha dado a sus datos personales;
            <br/>
            •	Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen, una vez haya agotado el trámite de consulta o reclamo ante el Responsable del Tratamiento;
            <br/>
            •	Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales;
            <br/>
            •	Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento;
            <br/>
            •	Tener acceso a medios de contacto otorgados por UBELENS para ejercer sus derechos y dar aplicación a los procedimientos previstos en la legislación colombiana respecto al Derecho de Habeas Data;
            <br/>
            •	Solicitar la supresión de datos personales cuando en el Tratamiento no se respeten los derechos y garantías constitucionales y legales, cuando exista declaratoria administrativa por parte de la Superintendencia de Industria y Comercio de la infracción cometida por UBELENS respecto al régimen de protección de datos personales;
            <br/>
            <br/>
            DERECHOS DE HABEAS DATA DE NIÑOS, NIÑAS Y ADOLESCENTES: queda proscrito el tratamiento de datos personales de menores, salvo aquellos datos que sean de naturaleza pública, y en este caso el tratamiento deberá cumplir con los siguientes parámetros: 
            <br/>
            <br/>
            a. Responder y respetar el interés superior de los menores. 
            <br/>
            b. Asegurar el respeto de los derechos fundamentales de los menores. 
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>IX.	PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS, PETICIONES DE RECTIFICACIÓN, ACTUALIZACIÓN Y SUPRESIÓN DE DATOS</DisplayText>
            <DisplayText>
            En desarrollo del ejercicio de los derechos de información, acceso, actualización, rectificación, cancelación y oposición por parte del Titular de Datos Personales o interesado habilitado legalmente, esto es, en sus causahabientes y representantes legales, UBELENS adopta el siguiente procedimiento: 
            <br/>
            <br/>
            El Titular del dato y/o interesado en ejercer uno de estos derechos, acreditará esta condición mediante copia del documento pertinente y de su documento de identidad, que podrá suministrar por medio físico o digital. En caso de que el Titular esté representado por un tercero, deberá allegarse el respectivo poder, el cual deberá tener reconocimiento de firma y contenido ante notario público. El apoderado deberá igualmente acreditar su identidad en los términos indicados.
            <br/>
            <br/>
            La solicitud se podrá realizar por medio escrito físico o digital y debe contener la siguiente información: 
            <br/>
            •	Nombre del Titular del Dato Personal y de sus representantes, de ser el caso. 
            <br/>
            •	Petición concreta y precisa de información, acceso, actualización, rectificación, cancelación, oposición o revocatoria del consentimiento. En cada caso la petición deberá estar razonablemente fundamentada para que UBELENS proceda como Responsable de la Base de Datos Personales a dar respuesta.
            <br/>
            •	Dirección física y/o electrónica para notificaciones. 
            <br/>
            •	Documentos que soportan la solicitud. Firma de la solicitud por parte del Titular del Dato Personal.
            <br/>
            <br/>
            Si faltare alguno de los requisitos aquí indicados, UBELENS así lo comunicará al interesado dentro de los cinco (5) días siguientes a la recepción de la solicitud, para que los mismos sean subsanados. Si Transcurridos dos (2) meses sin que presente la información requerida, se entenderá que se ha desistido de la solicitud. UBELENS podrá disponer de formatos físicos y/o digitales para el ejercicio de este derecho y en ellos indicará si se trata de una consulta o de un reclamo del interesado. 
            <br/>
            <br/>
            Se dará respuesta a la solicitud en el término de diez (10) días hábiles si se trata de una consulta y si es un reclamo en el término de quince (15) días hábiles. Si no logra dar respuesta en los términos citados, UBELENS informará al interesado las causas de la demora y la nueva fecha en que entregará la respuesta, que no puede ser mayor a cinco (5) días hábiles siguientes al vencimiento del primer término. 
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>X. FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES</DisplayText>
            <DisplayText>
            De acuerdo a lo dispuesto en los artículos 4 y 12 de la Ley 1581 de 2012, el Tratamiento de Bases de Datos Personales debe estar regido por el principio de finalidad legítima. Por consiguiente, en cumplimiento de su deber de informar al Titular de los Datos Personales, UBELENS le deberá informar previamente, y cuantas veces así lo requiera el Titular, acerca del objetivo o propósito de la misma, con el fin que conozca para qué tiene la sociedad esa Base de Datos.
            <br/>
            <br/>
            Los datos de carácter personal, sin importar su naturaleza, ya sean datos de identificación, de ubicación, datos socioeconómicos o sensibles y que sean suministrados a UBELENS por personas naturales en su calidad de Titulares, y en marco del ejercicio de su actividad económica principal, serán objeto de tratamiento conforme las siguientes finalidades de carácter general:
            <br/>
            <br/>
            •	Para lograr una eficiente comunicación, por cualquier medio conocido, relacionada con productos, servicios, ofertas, promociones, alianzas, contenidos, o para el cumplimiento de las obligaciones y/o compromisos derivados de las relaciones, contractuales o no, existentes. 
            <br/>
            •	Para el cumplimiento de las obligaciones legales que involucren datos personales de sus grupos de interés.
            <br/>
            •	Para la gestión comercial y relacionamiento con sus grupos de interés. 
            <br/>
            •	Evaluar la calidad de sus productos y/o servicios. 
            <br/>
            •	Para el análisis prospectivo sobre tendencias y preferencias de sus grupos de interés en relación con sus bienes y/o servicios.
            <br/>
            •	Para conocer de manera prospectiva las necesidades de sus grupos de interés con el fin de innovar y satisfacer estas.
            <br/>
            •	Registrar la Base de Datos en la cual se le da tratamiento a la información ante la Superintendencia de Industria y Comercio, en los términos establecidos en la Ley 1255 de 2012 y el Decreto 886 de 2014
            <br/>
            •	Para comunicar a sus grupos de interés información sobre sus bienes, servicios, publicaciones, eventos de capacitación, actividades empresariales y publicidad asociada a su actividad empresarial, trátese de bienes y/o servicios.
            <br/>
            •	Para desplegar hacia sus grupos de interés actividades de responsabilidad social empresarial.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>XII.      MECANISMOS DE SEGURIDAD DE LA INFORMACIÓN Y DATOS PERSONALES</DisplayText>
            <DisplayText>
            En ocasión de lo consagrado en la Ley 1581 de 2012 respecto al principio de seguridad, UBELENS adoptará las medidas necesarias de carácter técnico, humanas y administrativas, mediante las cuales garanticen la seguridad y el carácter privado de los datos personales recolectados en sus bases de datos, evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
            <br/>
            <br/>
            UBELENS ejecuta capacitaciones al personal que ingresa a la compañía respecto a:  i) La Política de Tratamiento y Protección en Materia de Datos Personales; ii) Ámbito de aplicación del procedimiento con especificación detallada de los recursos protegidos; iii) Las medidas, normas, procedimientos, reglas y estándares encaminados a garantizar el nivel de seguridad exigido en la Ley 1581 de 2012 y el Decreto 1377 de 2013, y realizar controles periódicos para verificar el cumplimiento de lo dispuesto en el procedimiento de seguridad que se implemente.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>   XIII.     TRANSFERENCIA NACIONAL O INTERNACIONAL DE DATOS PERSONALES</DisplayText>
            <DisplayText>
            UBELENS DE COLOMBIA S.A.S podrá realizar la transferencia de datos a otros Responsables del Tratamiento cuando así esté autorizado por el Titular de la información o por la ley o por un mandato administrativo o judicial. 
            <br/>
            <br/>
            La transmisión nacional o internacional de datos personales a encargados ubicados fuera o dentro del territorio de la República de Colombia se realizará en los siguientes casos:
            <br/>
            <br/>
            a.	Cuando cuente con autorización de Titular.
            <br/>
            b.	Cuando sin contar con la autorización exista entre el Responsable y el Encargado un contrato de transmisión de datos.
            </DisplayText>

            <DisplayText className={classes.header} color='primary' variant='h6'>XIV.    CONSIDERACIONES FINALES </DisplayText>
            <DisplayText>
            UBELENS podrá, cuando lo considere conveniente, cambiar los términos de su Política de Privacidad y Tratamiento en Materia de Datos Personales, sin perjuicio de las garantías dispuestas en el régimen colombiano de protección de datos personales. Los cambios sustanciales efectuados entrarán en vigor treinta (30) días después de que éstos hayan sido publicados en la plataforma o en la página web principal de la sociedad.
            <br/>
            <br/>
            UBELENS ha dispuesto al área comercial como la encargada del manejo de los Datos Personales y de la atención de los clientes, relacionada con la Protección de los Datos. Esta persona es responsable de la recepción y atención de peticiones, quejas, reclamos y consultas de conformidad con la Ley y esta Política. Los datos del contacto son:
            <br/>
            <br/>
            Responsable: UBELENS DE COLOMBIA S.A.S 
            <br/>
            NIT: 901.027.531-5
            <br/>
            Dirección física: Calle 86 No. 50-129. Barranquilla-Atlántico.
            <br/>
            Correo electrónico: contacto@ubelens.com
            <br/>
            Teléfono: (+57) 5 3226161
            <br/>
            <br/>
            El Titular de los datos personales podrá hacer valer sus derechos a través del correo electrónico: <a href='contacto@ubelens.com' target='_blanck'>contacto@ubelens.com</a>
            <br/>
            <br/>
            El Titular de los datos sujetos a tratamiento por UBELENS podrá comunicarse con la sociedad en el evento en que considere que 
            otra persona le está vulnerando un derecho conforme a esta Política de Privacidad. En cuyo caso podrá remitir correo electrónico 
            con la descripción de los detalles del caso, a la siguiente dirección: <a href='contacto@ubelens.com' target='_blanck'>contacto@ubelens.com</a>   
            <br/>
            <br/>
            De igual manera, en el evento en que el Titular desee realizar preguntas y consultas respecto a lo expresado en esta Política de Privacidad, 
            las puede formular a la siguiente dirección electrónica: <a href='contacto@ubelens.com' target='_blanck'>contacto@ubelens.com</a> 
            </DisplayText>
        </div>
    )

}

export default Privacy