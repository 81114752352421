import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Icon, makeStyles } from '@material-ui/core'
import { blueGrey, cyan, teal } from '@material-ui/core/colors'
import React from 'react'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading'
import { nodata } from '../../../variables/texts'
import moment from 'moment'
import { onGetFormatNumber, onGetFullname } from '../../../shared/utility'
import ProductImage from '../../../components/Structure/Images/ProductImage'
import { catalogs } from '../../../texts/esp/catalogs'

const useStyles = makeStyles(theme => ({
    root:{
        border:`1px solid ${blueGrey[50]}`,
        borderRadius:16
    },
    header:{
        borderTopLeftRadius:16,
        borderTopRightRadius:16,
        padding:16,
        background:blueGrey[50]
    },
    content:{
        padding:16
    },
    restore:{
        background:cyan[700],
        '&:hover':{background:cyan[900]}
    },
    acordion:{
        borderRadius:'0px 0px 16px 16px',
        //border:'none',
        border:`1px solid ${blueGrey[50]}`,
        boxShadow: 'none',
    },
    acordionSummary:{
        //borderRadius:16,
        //border: 'none',
        boxShadow: 'none',
    }
}))

const OrderItem = (props) => {

    const classes = useStyles()
    const {created_at, subtotal, shipping_address, order_id, line_items, status_id, _id,
    onRecover, onViewDetails} = props

    let orderDate = created_at ? moment(created_at).format('DD MMM YYYY') : nodata
    let price = subtotal ? `$ ${onGetFormatNumber(subtotal)}` : nodata
    let fullname = shipping_address ? onGetFullname(shipping_address) : nodata 

    let image, product_name, productDetailsContent
    const totalProducts = line_items ? line_items.length : 0
    if(line_items && line_items.length){
        image = line_items[0].image
        product_name = line_items[0].title
        

        if(line_items[0].details){
            const configAttributes = JSON.parse(line_items[0].details)
            const {type} = configAttributes
            if(type === 'DIFF'){
                const {axis_left, axis_right, cylinder_left, cylinder_right, sphere_left, sphere_right, 
                addition_left, addition_right, dominance_left, dominance_right, color_left, color_right,
                quantity_left, quantity_right} = configAttributes
                productDetailsContent = (
                    <Grid container spacing={3}>
                        <Grid item >
                            <LenseDetailContainer
                                title='OJO IZQUIERDO' quantity={quantity_left} 
                                axis={axis_left} cylinder={cylinder_left} sphere={sphere_left}
                                addition={addition_left} dominance={dominance_left} color={color_left}
                            />
                        </Grid>
                        <Grid item >
                            <LenseDetailContainer 
                                title='OJO DERECHO' quantity={quantity_right}
                                axis={axis_right} cylinder={cylinder_right} sphere={sphere_right}
                                addition={addition_right} dominance={dominance_right} color={color_right}
                            />
                        </Grid>
                    </Grid>
                )
                
            }else{
                const {axis, cylinder, sphere, addition, dominance, color, quantity} = configAttributes
                    productDetailsContent = (
                        <LenseDetailContainer 
                            title='MISMA FORMULA' quantity={quantity} 
                            axis={axis} cylinder={cylinder} sphere={sphere}
                            addition={addition} dominance={dominance} color={color}
                        />
                    )
            }
        }

    }





    return(
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <ProductImage type='CART' image={image} />
                            </Grid>
                            <Grid item xs>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <DisplayText>{product_name}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {productDetailsContent}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <Grid container direction='column' alignItems='flex-end'  spacing={2}> */}
                            <Grid item>
                                <DataWrapper title='No. de productos' data={totalProducts}/>
                            </Grid>
                            {status_id > 1 ? <Grid item xs={12}>
                                <Button fullWidth variant='contained' color='primary' onClick={() => onViewDetails(_id)} >Ver detalle</Button>
                            </Grid>: null}
                            {status_id ===1 ? <Grid item xs={12}>
                                <Button fullWidth variant='contained' color='secondary' className={classes.restore}
                                onClick={() => onRecover(_id, line_items.length)}>Recuperar</Button>
                            </Grid>: null}
                        {/* </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        
                    </Grid> */}
                </Grid>
            </div>
            <Accordion 
                classes={{root: classes.acordion}}
            >
                <AccordionSummary
                expandIcon={<Icon style={{color:'grey'}}>expand_more</Icon>}s
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    root: classes.acordionSummary, 
                    expanded : classes.expandedChild  ,
                    content: classes.contentChild            
                    }}
                >
                <DisplayText className={classes.heading}>Ver detalles del pedido</DisplayText>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <DataWrapper title='Fecha de pedido' data={orderDate}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataWrapper title='Total' data={price}/>
                        </Grid>
                        <Grid item xs={12}>
                            <StatusWrapper title='Estatus' data={status_id}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataWrapper title='Enviar a' data={fullname}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataWrapper title='No. de order' data={order_id}/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default OrderItem

const DataWrapper = ({title,data}) => {
    return(
        <Grid container spacing={2}>
            <Grid item >
                <Heading>{title}</Heading>
            </Grid>
            <Grid item >
                <DisplayText>{data}</DisplayText>
            </Grid>
        </Grid>
    )
}

const StatusWrapper = ({title,data}) => {

    let status = catalogs.order_statuses.find(el => el.value === data)

    return(
        <Grid container spacing={2}>
            <Grid item >
                <Heading>{title}</Heading>
            </Grid>
            <Grid item >
                <Grid container wrap='nowrap' alignItems='center'>
                    <Grid item>
                        <Icon style={{color:status.color, fontSize:12, marginRight:4}}>brightness_1</Icon>
                    </Grid>
                    <Grid item xs>
                        <DisplayText>{status.label}</DisplayText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


const LenseDetailContainer = props => {
    const {title, sphere, cylinder, axis, addition, dominance, color, quantity} = props

    return(
        <Grid container direction='column'>
            <Grid item>
                <Heading color='bluegrey'>{title}</Heading>
            </Grid>
            <Grid item>
                <Grid container direction='column'>
                    {sphere ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Poder(SPH):</Heading></Grid>
                                <Grid item xs><DisplayText>{sphere}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {cylinder ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Cilindro(CYL):</Heading></Grid>
                                <Grid item xs><DisplayText>{cylinder}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {axis ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Eje(AXIS):</Heading></Grid>
                                <Grid item xs><DisplayText>{axis}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {addition ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Adición(ADD):</Heading></Grid>
                                <Grid item xs><DisplayText>{addition}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {dominance ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Dominance(DOM):</Heading></Grid>
                                <Grid item xs><DisplayText>{dominance}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {color ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Color:</Heading></Grid>
                                <Grid item xs><DisplayText>{color}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {quantity ? (
                        <Grid item>
                            <Grid container wrap='nowrap' spacing={1}>
                                <Grid item><Heading>Cantidad:</Heading></Grid>
                                <Grid item xs><DisplayText>{quantity}</DisplayText></Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    )
}