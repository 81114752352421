import { CircularProgress, Dialog, Grid, Grow, makeStyles, useTheme } from '@material-ui/core'
import React from 'react'
import ContainedButton from '../../../components/Actions/Buttons/ContainedButton';
import DisplayText from '../../../components/Texts/DisplayText';
import QRCode from 'react-qr-code';
import SimpleModal from '../../../components/Structure/Modals/SimpleModal';

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:0,
        paddingBottom:0
    }
}))

const MercadoPagoModal = (props) => {

    const classes = useStyles()
    const {open, onClose, url} = props
    const theme = useTheme()

    //console.log(url)

    return(
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div className={classes.root}>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                    <Grid item>
                        <DisplayText variant='h6' color='primary' style={{color:theme.palette.primary.main ,fontWeight:500}}>Link de pago para Mercado Pago</DisplayText>
                        <DisplayText>Por favor escanea el código o da clic en continuar para realizar el pago en esta plataforma</DisplayText>
                    </Grid>
                    <Grid item>
                        <QRCode value={url} size={200}/>
                    </Grid>
                    <Grid item>
                        <ContainedButton color='primary' onClick={() => window.open(url, '_self')} > Proceder a pago </ContainedButton>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default MercadoPagoModal
