import React from 'react'
import { makeStyles, Card, Button } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel';

import banner1 from '../../../assets/banners/banner1.jpg'
import banner2 from '../../../assets/banners/banner2.jpg'
import banner3 from '../../../assets/banners/banner3.jpg'
import { grey } from '@material-ui/core/colors';



const useStyles = makeStyles(theme => ({
    root:{
        background:'#EFEFEF',
        width:'100%',
        boxSizing:'border-box',
        paddingTop:'40%',
        position:'relative',
        //height:'40vh',
        [theme.breakpoints.down('sm')]:{
            height:'40vh'
        }
    },
    subroot:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        [theme.breakpoints.down('sm')]:{
            height:'40vh'
        }
    },
    image:{
        borderRadius:0,
        height:'100%',
        //height:'40vh',
        width:'100%',
        [theme.breakpoints.down('sm')]:{
            height:'40vh',
            objectFit:'cover'
        }
    },
    clickableContianer:{
        '&:hover':{cursor:'pointer'}
    },
    button:{
        position:'absolute',
        bottom:'12.5%', left:'50%',
        transform:`translate(-50%,0%)`,
        zIndex:2,
        background:'white',
        fontSize:20,
        padding:'12px 32px',
        textTransform:'none',
        color:theme.palette.primary.main,
        '&:hover':{
            background:grey[200],
        }
        
    }
}))

const CarouselContainer = props => {
    const classes = useStyles()
    return(
        <div className={classes.root}>
            <div className={classes.subroot}>
                <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div>
                        <img src={banner1} alt='' className={classes.image}/>
                    </div>
                    <div className={classes.image}>
                        <img src={banner2} alt='' className={classes.image}/>
                        <Button className={classes.button} 
                            onClick={() => window.open("https://www.opticapanorama.com", "_blank")}
                        >Ir al sitio</Button>
                    </div>
                    <div>
                        <img src={banner3} alt='' className={classes.image}/>
                    </div>
                </Carousel>
            </div>
            
        </div>
    )
}

export default CarouselContainer