import { Button, Dialog, Grid, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import ProductImage from '../../../components/Structure/Images/ProductImage'
import SimpleModal from '../../../components/Structure/Modals/SimpleModal'
import DisplayText from '../../../components/Texts/DisplayText'
import Heading from '../../../components/Texts/Heading'
import { onGetFormatNumber } from '../../../shared/utility'


const CheckoutRightModalMobile = (props) =>{

    const {step, order, onCompletePayment, open, onClose} = props
    const classes = useStyles()

    //console.log(lineItems)
    //console.log(totalPrice)
    let lineItems = [] 
    let totalPrice = 0;
    if(order){
        lineItems = order.line_items
        totalPrice = onGetFormatNumber(order.subtotal)
    }

    return(
        <SimpleModal open={open} maxWidth='xs' onClose={onClose} >
            <div >
                <div className={classes.listContainer}>
                    <Grid container spacing={2}>
                        {lineItems.map((item,key)=>{

                            const {quantity, title, total_price, image} = item
                            const singlePrice = onGetFormatNumber(total_price)

                            return(
                                <Grid item xs={12} key={key.toString()} >
                                    <Grid container spacing={3} wrap='nowrap' alignItems='center'>
                                        <Grid item>
                                            <ProductImage type='CHECKOUT' image={image} quantity={quantity}/>
                                        </Grid>
                                        <Grid item xs>
                                            <DisplayText>{title}</DisplayText>
                                        </Grid>
                                        <Grid item>
                                            <Heading>{`$ ${singlePrice}`}</Heading> 
                                        </Grid>
                                    
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                <div className={classes.details}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs><DisplayText>Subtotal</DisplayText></Grid>
                                <Grid item><Heading>{`$ ${totalPrice}`}</Heading></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs><DisplayText>Envío</DisplayText></Grid>
                                <Grid item><Heading>Gratis</Heading></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.total}>
                    <Grid container spacing={1}>
                        <Grid item xs><DisplayText variant='h6'>Total</DisplayText></Grid>
                        <Grid item><Heading variant='h5'>{`$ ${totalPrice}`}</Heading></Grid>
                    </Grid>
                </div>
                {step === 3 ? <div className={classes.buttonContainer}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <Button variant='contained' color='primary' size='large' onClick={onCompletePayment}>Pagar</Button>
                        </Grid>
                    </Grid>
                </div> : null}
            </div>
        </SimpleModal>
    )

}

const useStyles = makeStyles(theme => ({
    image:{
        width:64
    },
    listContainer:{
        paddingBottom:24,
        borderBottom:`1px solid ${blueGrey[100]}`
    },
    details:{
        paddingTop:24,
        paddingBottom:24,
        borderBottom:`1px solid ${blueGrey[100]}`
    },
    total:{
        paddingTop:24,
    },
    buttonContainer:{
        paddingTop:32
    },
    paper:{borderRadius:32, 
        padding:theme.spacing(5)},
}))

export default CheckoutRightModalMobile