import React from 'react'
import Slider from "react-slick";
import { makeStyles, Grid } from '@material-ui/core';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from '../../Texts/Heading';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:60,
        paddingBottom:40,
        //borderTop:`1px solid ${blueGrey[50]}`,
        //borderBottom:`1px solid ${blueGrey[50]}`
    },
    container:{
        padding:theme.spacing(1),
        paddingTop:theme.spacing(2)
    },
    imageContainer:{
        width:150,
        height:150,
        position:'relative',
        margin:'auto',
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },

}))

const CustomerSlider = props => {

    const {brands} = props
    const classes = useStyles()

    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "8px",
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3500,
        cssEase: "linear",
        arrows:true,
        responsive:[
            /* {
                breakpoint: 1280,
                settings: {
                    dots:true,
                    infinite:false,
                    speed:500,
                    centerPadding: "8px",
                    slidesToShow:4,
                    slidesToScroll:1,
                    initialSlide:0,
                    dots:false,
                }
            }, */
            {
                breakpoint: 600,
                settings: {
                    infinite:false,
                    speed:500,
                    centerPadding: "8px",
                    slidesToShow:1,
                    slidesToScroll:1,
                    dots:false,
                }
            },
        ]
        
    };
    

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Heading align='center' size='extraLarge' >Nuestras marcas</Heading>
                </Grid>
                <Grid item xs={12}>
                    <Slider {...settings}>
                        {brands.map((item,key)=>{
                            return(
                                <div key={key.toString()}>
                                    <div className={classes.imageContainer}>
                                    <img src={item.image} alt={''} className={classes.image}/>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default CustomerSlider

