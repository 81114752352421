import gql from 'graphql-tag';
import { mainServer, BASIC_AUTH_HEADER, mpServer } from '../../variables/config';

export const request_order = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/order/${id}`)
    return response.data.data
}

export const request_profile = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/user/me`)
    return response.data.data
}

export const request_order_seller = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/order/${id}/vendor`)
    return response.data.data
}

/*export const request_update_order = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}`, data)
    return response.data.data
}*/

export const request_update_shipping_address = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}/shippingAddress`, data)
    return response.data.data
}

export const request_update_billing_address = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/${id}/billingAddress`, data)
    return response.data.data
}

export const request_payment_credit_card = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/creditcard`, data)
    return response.data.data.payment_id
}

export const request_payment_credit_card_verification = async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/creditcard/verify`, data)
    return response.data.data
}

export const request_payment_cash= async(id, data) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/transaction/cash`, data)
    return response.data.data
}

export const request_complete_order = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.post(`/order/${id}/complete`)
    return response.data.data
}

export const request_update_payment = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/order/${id}/admin/payment`, data)
}

export const request_update_order = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/order/admin/${id}`, data)
    return response.data.data
}


/*export const request_mercadopago_payment = async(id, idpayment) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/${id}/payment/mercadopago/review/${idpayment}`)
    return response.data.data.completed
}*/

/*export const request_mercadopago_payment_link = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/payment/mercadopago/${id}`,data)
    console.log(response.data.data  )
    return response.data.data.url
}*/

/*export const request_mercadopago_payment_link = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/${id}/payment/mercadopago/link`)
    return response.data.data.url
}*/

///////////////////////////////////////////////////////////////////////////////////////////////////
////                            MERCARDO PAGO REQUESTS                                          ///
///////////////////////////////////////////////////////////////////////////////////////////////////

export const request_mercadopago_preference = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/payment/mercadopago/preference/${id}`)
    return response.data.data
}

export const request_mercadopago_payment = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/payment/mercadopago/${id}`)
    return response.data.data
}

export const request_mercadopago_payment_link = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/order/${id}/payment/mercadopago/link`)
    return response.data.data.url
}