import React, {useState} from 'react'
import { Card, ClickAwayListener, Divider, Grid, Grow, Icon, IconButton, makeStyles, Popper } from '@material-ui/core'
import DisplayText from '../../../../Texts/DisplayText';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme=>({
    root:{},
    card:{borderRadius:16},
    menu:{marginTop:10, marginBottom:10, width:180}
}))

const UserButton = props => {

    const classes = useStyles();
    const {onItemSelected, isAuth, onLogOut, history} = props
    const [anchorEl, setAnchorEl] = useState(null)



    const onInnerItemSelected = (identifier) => {
        setAnchorEl(null)
        if(identifier === 'logout'){
            onLogOut()
            history.push('/')
            return
        }
        if(onItemSelected) onItemSelected(identifier)
    }

    let buttonContent = (
        <Card className={classes.card}>
            <div className={classes.menu}>
                <Grid container>
                    <Grid item xs={12}>
                        <OptionComponent icon='login' label='Iniciar sesión' id='signin' onItemSelected={onInnerItemSelected}/>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12}>
                        <OptionComponent icon='how_to_reg' label='Registrarse' id='signup' onItemSelected={onInnerItemSelected}/>
                    </Grid>
                </Grid>
            </div>
        </Card>
    )

    if(isAuth){
        buttonContent = <Card className={classes.card}>
            <div className={classes.menu}>
                <Grid container>
                    <Grid item xs={12}>
                        <OptionComponent icon='account_circle' label='Mis datos' id='profile' onItemSelected={onInnerItemSelected}/>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12}>
                        <OptionComponent icon='fact_check' label='Mis pedidos' id='orders' onItemSelected={onInnerItemSelected}/>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={12}>
                        <OptionComponent icon='login' label='Cerrar Sesión' id='logout' onItemSelected={onInnerItemSelected}/>
                    </Grid>
                </Grid>
            </div>
        </Card>
    }

    return(
        <div className={classes.root}> 
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} ><Icon style={{color:'white'}}>perm_identity</Icon></IconButton>
        </div>
    )
}

export default UserButton

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {id, icon, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(id)}>
            <Grid container spacing={1}>
                <Grid item><Icon className={classes.icon}>{icon}</Icon></Grid>
                <Grid item><DisplayText color='bluegrey'>{label}</DisplayText></Grid>
            </Grid>
        </div>
    )
}